<vl-region>
    <div v-vl-align:center v-if="loading">
        <vl-loader message="De gegevens worden geladen. Even geduld, a.u.b." />
    </div>
    <vl-form-grid mod-stacked v-if="!loading">
        <vl-column>
            <vl-title tag-name="h2">
                {{title}}
            </vl-title>
        </vl-column>
        <vl-column v-if="roundSuccessfullyDone">
            <vl-alert icon="check" title="Facturatie ronde afgelopen!" mod-success>
                <div v-if="!!currentInvoiceRound.endTime">De facturatieronde die gestart is op {{formatDate(currentInvoiceRound.startTime)}} is succesvol afgelopen op {{formatDate(currentInvoiceRound.endTime)}}.</div>
                <div v-else>De facturatieronde die gestart is op {{formatDate(currentInvoiceRound.startTime)}} is succesvol afgelopen.</div>
            </vl-alert>
            <vl-u-spacer mod-medium />
        </vl-column>
        <vl-column v-if="nothingToInvoice">
            <vl-alert icon="check" title="Niks te factureren!" mod-success>
                <div>De facturatieronde heeft geen te factureren aanvragen.</div>
            </vl-alert>
            <vl-u-spacer mod-medium />
        </vl-column>
        <vl-column v-if="!!mapRequestsNotRegisteredInFinancialTransaction && mapRequestsNotRegisteredInFinancialTransaction.length > 0">
            <vl-alert icon="alert-circle" title="Opgelet!" mod-error>
                <div>Er zijn {{mapRequestsNotRegisteredInFinancialTransaction.length}} planaanvragen die nog
                    <kl-router-link :to="routeAdminMapRequestWithFinancialAnomalies" class="vl-link">
                        niet binnen Clearing House geregistreerd zijn.
                    </kl-router-link>
                </div>
            </vl-alert>
            <vl-u-spacer mod-medium />
        </vl-column>

        <vl-column v-if="error">
            <vl-alert mod-error icon="alert-circle" title="Fout tijdens het starten van de facturatie!" :closable="false">
                <p>
                    {{error}}
                </p>
            </vl-alert>
            <vl-u-spacer mod-medium />
        </vl-column>
        <vl-column>
            <vl-form-grid mod-stacked v-kl-u-background-alt v-kl-u-box-padding>
                <vl-column width-l="12" v-if="noActiveRoundRunning" >
<!--                    <vl-form-validation-observer v-slot="{ handleSubmit, invalid, pristine }">-->
                        <form class="kl-start-invoicing vl-form" @submit="onSubmit">
                            <vl-column width-s="12" width-m="8" width-l="12">
                                <strong>Opgelet: Volgende geplande facturatie ronde start automatisch op de 11e van de maand.</strong>
                                <vl-u-spacer mod-small/>
                            </vl-column>
                            <vl-column width-s="12" width-m="8" width-l="12">
                                <strong>Te factureren organisaties:</strong>
                                <vl-u-spacer mod-small/>
                            </vl-column>
                            <vl-column width-s="12" width-m="8" width-l="12">
                                <vl-radio id="organisation-all" name="organisation" :mod-disabled="isSending"
                                          v-model="selectedOrganisationTpe" value="all" checked>Alle organisaties
                                </vl-radio>
                                <vl-radio id="organisation-select" name="organisation" :mod-disabled="isSending"
                                          v-model="selectedOrganisationTpe" value="select">Selecteer de organisatie(s)
                                </vl-radio>
                                <vl-u-spacer/>
                            </vl-column>
                            <vl-column width-s="12" width-m="12" width-l="12" v-if="organisationSelection">
                                <vl-multiselect v-model="selectedOrganisations" :id="uniqueFieldId('organisation')"
                                                :mod-multiple="true" :show-no-results="false" :clear-on-select="true" :hide-selected="true"
                                                placeholder="Zoek een organisatie"
                                                track-by="organisationId"
                                                :custom-label="organisation => organisation.namePath"
                                                :options="organisationSuggestion"
                                                @search-change="onOrganisationInputChange">
                                    <template v-slot:noResult>
                                        <span>Geen resultaten gevonden...</span>
                                    </template>
                                    <template v-slot:noOptions>
                                        <span>Geen opties beschikbaar!</span>
                                    </template>
                                </vl-multiselect>
                                <vl-u-spacer mod-small/>
                            </vl-column>
                            <vl-column width="4">
                                <kl-field-validation-wrapper :field="dateFromField" #default="{hasErrors}" mod-required>
                                    <kl-datepicker :mod-error="hasErrors" visual-format="d/m/Y" v-model="dateFrom" :default-date="dateFromMaxDate" :max-date="dateFromMaxDate"/>
                                </kl-field-validation-wrapper>
                                <vl-u-spacer />
                            </vl-column>
                            <vl-column>
                                <vl-checkbox v-model="overruleDryRun" ref="overruleCheckbox">
                                    Toch doorgaan indien de dry run fouten bevat
                                </vl-checkbox>
                            </vl-column>
                            <vl-button type="submit" :mod-disabled="startingNewRound" mod-large>Start</vl-button>
                        </form>
<!--                    </vl-form-validation-observer>-->
                </vl-column>
                <template v-if="!startingNewRound">
                    <vl-column>
                        <div class="log">
                            <div v-for="log in currentInvoiceRound.log" :class="logClass(log)">
                                <span>{{formatDate(log.timestamp)}}</span> - <span v-html="log.message"></span>
                            </div>
                        </div>
                    </vl-column>
                </template>
            </vl-form-grid>
        </vl-column>
    </vl-form-grid>
</vl-region>
