<nav class="ippro-pagination" role="navigation" aria-label="Paginatie">
    <div class="ippro-pagination__list">
        <div
            class="ippro-pagination__list__item ippro-pagination__list__item--position"
            v-if="pagePosition"
            v-html="pagePosition"
            id="pagePosition"
        />
    </div>

    <div class="ippro-pagination__list">
        <template v-if="pageCount > 1">
            <div
                :class="{'ippro-pagination__list__item': true, 'ippro-pagination__list__item--previous': true, 'ippro-pagination__list__item--disabled' : !prevButtonActive}"
            >
                <component
                    :is="prevButtonActive ? 'a' : 'span'"
                    class="vl-link"
                    :href="prevButtonActive ? '#' : null"
                    @click.prevent="prevButtonActive ? changePage(currentPage - 1) : null"
                    :aria-label="prevButtonActive ? `${translation('goToPrevious')}` : null"
                >
                    <vl-icon class="vl-link__icon vl-link__icon--before" icon="nav-left"/>
                    {{ translation('previous') }}
                </component>
            </div>
            <div
                :class="{'ippro-pagination__list__item': true, 'ippro-pagination__list__item--current': page == currentPage}"
                v-for="page in pagination"
                :key="page !== '...' ? 'page-' + page : null"
            >
                <component
                    :is="page != currentPage && page !== '...' ? 'vl-link' : 'span'"
                    :href="page != currentPage && page !== '...' ? '#' : null"
                    @click.prevent="page != currentPage && page !== '...' ? changePage(parseInt(page)) : null"
                    :aria-label="page != currentPage && page !== '...' ? `${translation('goToPage')} ${page}` : null"
                    :aria-current="page == currentPage"
                    v-html="page"
                ></component>
            </div>
            <div
                :class="{'ippro-pagination__list__item': true, 'ippro-pagination__list__item--next': true, 'ippro-pagination__list__item--disabled' : !nextButtonActive}"
            >
                <component
                    :is="nextButtonActive ? 'a' : 'span'"
                    class="vl-link"
                    :href="nextButtonActive ? '#' : null"
                    @click.prevent="nextButtonActive ? changePage(currentPage + 1) : null"
                    :aria-label="nextButtonActive ? `${translation('goToNext')}` : null"
                >
                    {{ translation('next') }}
                    <vl-icon class="vl-link__icon vl-link__icon--after" icon="nav-right"/>
                </component>
            </div>
        </template>
    </div>
</nav>
