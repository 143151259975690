<vl-form-grid mod-stacked v-if="unaZone.geometries.length && !unaZone.isKlimZone">
    <vl-column>
        <vl-title tag-name="h2">Geometrie</vl-title>
    </vl-column>
    <vl-column>
        <template v-for="geometry in unaZone.geometries" :key="geometry.reference">
            <kl-geometry-details-on-map
                :geometry="geometry"
                :all-geometries="unaZone.geometries"
            />
            <vl-u-spacer />
        </template>
    </vl-column>
</vl-form-grid>
