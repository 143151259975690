﻿import { defineComponent, onMounted, ref } from 'vue';
import { useUserStore } from '../shared/state/UserDataModule';
import { useRoute, useRouter } from '@/router/router-vue3';
import { RegistrationModel } from "@/app/public/registrationModel";

export default defineComponent({
    props: {},
    setup() {
        const registrationModel = ref<RegistrationModel>(new RegistrationModel());
        const isStepValid = ref<boolean>(registrationModel.value.isStepValid());
        const router = useRouter();
        const route = useRoute();

        const modelChanged = (model: RegistrationModel) => {
            registrationModel.value = model;
        }

        const isActiveStep = (step: number) => {
            return step === registrationModel.value.activeStep;
        }

        const goToNextStep = () => {
            registrationModel.value.nextStep();
            router.push({ path: route.path, hash: registrationModel.value.activeStep > 1 ? `#stap${registrationModel.value.activeStep}` : null });
        }

        const gotoPreviousStep = () => {
            registrationModel.value.previousStep();
            router.push({ path: route.path, hash: registrationModel.value.activeStep > 1 ? `#stap${registrationModel.value.activeStep}` : null });

        }

        onMounted(() => {
            useUserStore().updateIsCheckingAuthentication(false);
        });

        return {
            registrationModel,
            isStepValid,
            gotoPreviousStep,
            goToNextStep,
            isActiveStep,
            modelChanged
        }
    }
});
