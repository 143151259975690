import { computed, ComputedRef, defineComponent, ref, watch } from "vue";
import KlSearchOrganisation, { ISelectedOrganisation } from "@/app/admin/components/kl-search-organisation/kl-search-organisation";
import debounce from "lodash-es/debounce";
import { isEmpty } from 'lodash-es';

export class UserSearchField {
    firstName: string | undefined;
    lastName: string | undefined;
    organisation: string | undefined;
    roles: string[] | undefined;
    inActiveUsers: boolean;
    offset: number;
}

export default defineComponent({
    props: {
        defaultFilter: {
            type: Object as () => UserSearchField,
            required: true
        },
        modHideOrganisation: {
            type: Boolean,
            default: false,
            required: false,
        },
        debounceWait: {
            type: Number,
            default: 500
        }
    },
    emits: ['search'],
    setup(props, { emit }) {
        const klipRoleOptions = ref<string[]>([
            'Kabel- en leidingbeheerders (KLB)',
            'Planaanvragers (PAV)',
            'Openbaar Domeinbeheerders (ODB)',
            'Helpdesk- & Admin (CP)',
            'Organisatiebeheerder (ORG)',
        ]);
        const searchOrganisations = ref<InstanceType<typeof KlSearchOrganisation>>(null);
        const offset = ref<number>(0);
        const selectedKlipRoles = ref<string[]>([]);
        const selectedOrganisationId = ref<string>('');
        const inActiveUsers = ref<boolean>(false);
        const firstName = ref<string>('');
        const lastName = ref<string>('');

        const watchFields: ComputedRef<[string[], boolean, string, string, string]> = computed(() => {
            return [
                selectedKlipRoles.value.map(v => v),
                inActiveUsers.value,
                firstName.value,
                lastName.value,
                selectedOrganisationId.value
            ];
        })

        const debouncedSearch = debounce(() => {
            emit('search', {
                firstName: firstName.value,
                lastName: lastName.value,
                organisation: selectedOrganisationId.value,
                offset: offset.value,
                roles: selectedKlipRoles.value.map(role => {
                    switch (role) {
                        case 'Planaanvragers (PAV)':
                            return 'PAV';
                        case 'Kabel- en leidingbeheerders (KLB)':
                            return 'KLB';
                        case 'Openbaar Domeinbeheerders (ODB)':
                            return 'ODB';
                        case 'Helpdesk- & Admin (CP)':
                            return 'CP';
                        case 'Organisatiebeheerder (ORG)':
                            return 'ORG';
                    }
                }),
                inActiveUsers: inActiveUsers.value
            } as UserSearchField);
        }, props.debounceWait);

        const search = () => {
            debouncedSearch();
        };

        const defaultFilterChanged = (filter: UserSearchField) => {
            if (filter == null) {
                return;
            }
            if (filter.firstName) {
                firstName.value = filter.firstName;
            }
            if (filter.lastName) {
                lastName.value = filter.lastName;
            }
            if (!isEmpty(filter.roles)) {
                selectedKlipRoles.value = filter.roles;
            }
            if (filter.organisation) {
                selectedOrganisationId.value = filter.organisation;
            }
            if (filter.offset > 0) {
                offset.value = filter.offset;
            }
            inActiveUsers.value = filter.inActiveUsers;
        }

        const reset = () => {
            selectedOrganisationId.value = null;
            inActiveUsers.value = false;
            firstName.value = '';
            lastName.value = '';
            selectedKlipRoles.value = [];
            searchOrganisations.value.reset();
        }

        const onOrganisationSelect = (selectedOrganisation: ISelectedOrganisation) => {
            selectedOrganisationId.value = selectedOrganisation.organisationId;
        };

        watch(watchFields, search, { immediate: false, deep: true });
        watch(props.defaultFilter, defaultFilterChanged, { immediate: true, deep: true });

        return {
            searchOrganisations,
            firstName,
            lastName,
            klipRoleOptions,
            selectedKlipRoles,
            inActiveUsers,
            search,
            reset,
            onOrganisationSelect
        }
    }
});
