<vl-multiselect
    v-model="selectedOrganisationNames"
    :options="organisationData"
    :mod-multiple="false"
    :custom-label="org => org.title"
    placeholder="Zoek een organisatie"
    :loading="organisationDataFetching"
    @select="onOrganisationSelect"
    @search-change="onOrganisationInputChange"
>
    <template v-slot:noResult>
        <span>Geen resultaten gevonden...</span>
    </template>
    <template v-slot:noOptions>
        <span>Geen opties beschikbaar!</span>
    </template>
    <template v-slot:option="{ option }">
        <div>
            {{ option.title }}
        </div>
        <vl-annotation v-if="option.subtitle" class="kl-search-organisation__subtitle">
            {{ option.subtitle }}
        </vl-annotation>
    </template>
</vl-multiselect>
