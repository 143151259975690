<div :class="classes">
    <div role="alert">
        <vl-icon class="kl-una-alert__icon" :icon="icon" mod-large v-if="icon" />
        <slot></slot>
    </div>
    <div class="kl-una-alert__link">
        <vl-link :href="helpLink" target="_blank">Wat wil dit zeggen?</vl-link>
    </div>
</div>

