import {NavigationGuard, NavigationGuardReturn, RouteLocation} from 'vue-router';

export function createHashGuard(): NavigationGuard {

    return async function (to: RouteLocation, from: RouteLocation): Promise<NavigationGuardReturn> {

        // Support for old HashTagUrls (ignore hashes later in path)
        if (to.hash.substr(0, 2) === '#/') {
            const path = to.hash.substr(2);
            return path;
        }
    }
}
