import {App} from 'vue';
import VueProgressBar from "@aacassandra/vue3-progressbar";

export default {
    install(app: App) {
        const options = {
            color: 'rgb(0,0,0)',
            failedColor: 'red',
            height: '2px',
            transition: {
                speed: '0.2s',
                opacity: '0.0s',
                termination: 300,
            },
        };

        app.use(VueProgressBar, options);
    }
}
