<vl-region v-if="zone">
        <vl-form-grid mod-stacked>
            <vl-column width-l="9">
                <vl-title tag-name="h1">{{ zone.name }}</vl-title>
            </vl-column>
            <vl-column width-l="3">
                <vl-action-group class="vl-action-group--align-right--l vl-action-group--primary">
                    <slot name="geometryButton"></slot>
                </vl-action-group>
            </vl-column>
            <vl-column>
                <vl-tabs :active-tab="tabToLoad" @tab-change="tabChange">
                    <vl-tab label="Aanwezige KLB's" :id="unaZonesTabId">
                        <kl-pda-una-zones :organisationId="organisationIdValue" :parentOrganisationId="parentOrganisationIdValue"
                            :zoneId="zoneId" v-if="unaZonesLoaded">
                        </kl-pda-una-zones >
                    </vl-tab>

                    <vl-tab label="Planaanvragen" :id="maprequestsTabId">
                        <kl-pda-maprequests :parentOrganisationId="parentOrganisationIdValue" :zoneId="zoneId"
                            :zoneDetailView="zoneDetailView" :maprequestDetailRoute="routePdaMapRequest" v-if="maprequestsLoaded">
                        </kl-pda-maprequests>
                    </vl-tab>
                </vl-tabs>
            </vl-column>
        </vl-form-grid>
</vl-region>
