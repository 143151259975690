  <div class="copy-to-clipboard-container">
    <vl-title tag-name="h5">{{title}}</vl-title>
    <div class="copy-to-clipboard">
      <vl-input-addon
        class="copy-to-clipboard-addon"
        text
        tooltip="Kopieer naar klembord"
        icon="file-copy"
        @click="copyToClipBoard(id)"
      ></vl-input-addon>
      <vl-input-field
        readonly
        class="copy-to-clipboard-input"
        :name="id"
        :id="id"
        v-model="value"
      />
    </div>
  </div>