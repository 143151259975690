import {createApp} from 'vue';
import App from './app/app.vue';
import { createPinia } from 'pinia';
// import error from '@/app/error/error';
import webComponents from '@/plugins/webComponents';
import ipproComponents from '@/plugins/ipproComponents';
import globalComponents from '@/plugins/globalComponents';
import directives from '@/plugins/directives-vue3';
// import VueProgressBar from 'vue-progressbar';
import signalrHub from '@/plugins/signalr';
// import routes from './plugins/routes';
// import permission from './plugins/permission';
// import alert from './plugins/alert';
import proxyClient from '@/plugins/proxy-client';
import validations from './plugins/validations-vue3';
import filters from './plugins/filters';
// import VueGtag from "vue-gtag"
// import promiseFinally from 'promise.prototype.finally';
import externalComponents from '@/plugins/external-components';
// import miniRouter from '@/router/mini-router';
import router from '@/router/router-vue3';
import progressbarPlugin from '@/plugins/progressbarPlugin';

// MOVE!!!
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"
import vlMigrationComponents from '@/plugins/vl-migration-components';
dayjs.extend(utc);

const app = createApp(App);

app.use(webComponents);
app.use(vlMigrationComponents);
app.use(globalComponents);
app.use(externalComponents);
app.use(ipproComponents);
app.use(directives);

app.use(signalrHub);
app.use(proxyClient);
app.use(router);
app.use(progressbarPlugin);
app.use(filters);
app.use(validations);

const pinia = createPinia();
app.use(pinia);




// Vue.use(permission);
// Vue.use(alert);

// Vue.use(VueGtag, {
//     config: {
//         id: import.meta.env.VITE_GA4,
//         params: {
//             send_page_view: false, // no pageview on initial load!
//             anonymize_ip: true,
//             allow_google_signals: false, // disable all advertising features
//             debug_mode: !!import.meta.env.VITE_GA4_DEBUG_MODE
//         }
//     },
// }, router);

// Vue.config.productionTip = false;

// export default new Vue({
//     router,
//     pinia,
//     render: (h) => h(App),
//     created() {
//         if (useUserStore().isCheckingAuthentication) {
//             return;
//         }
//         this.$signalrHub.start();
//     },
// }).$mount('#app');


// TO VERIFY: is dit nodig + waarom? (copy van MOPRO)
// remove this loop when all components are migrated to vue3 without compatMode
for (const component in app._context.components) {
    if (component.startsWith('vl-')) {
        (app._context.components[component] as any).compatConfig = {
            MODE: 3
        }
    }
}

app.mount('#app');

export default app;
