﻿<div id="registration">
    <vl-region>
        <vl-layout>
            <vl-form-grid mod-stacked>
                <vl-column>
                    <vl-title tag-name="h1">Nieuwe registratie</vl-title>
                    <vl-button @click="gotoPreviousStep" v-if="!isActiveStep(1)" class="vl-button--link">Vorige</vl-button>
                </vl-column>
                <vl-column>
                    <kl-registration-step1 v-if="isActiveStep(1)" :registration-model="registrationModel" @change="modelChanged" />
                    <kl-registration-step2 v-if="isActiveStep(2)" :registration-model="registrationModel" @change="modelChanged" />
                    <kl-registration-step3 v-if="isActiveStep(3)" :registration-model="registrationModel" @change="modelChanged" />
                    <kl-registration-step4 v-if="isActiveStep(4)" :registration-model="registrationModel" />
                </vl-column>
                <vl-column width="2">
                    <vl-button @click="goToNextStep" mod-block mod-large :mod-disabled="!registrationModel.isStepValid()" v-if="!registrationModel.isFinalStep">Volgende</vl-button>
                </vl-column>
            </vl-form-grid>
        </vl-layout>
    </vl-region>    
</div>
