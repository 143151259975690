<vl-form-grid mod-stacked mod-v-center>
    <vl-column width-s="6" width-m="4" width-l="4">
        <vl-button tag-name="a" @click.prevent="fetchOpenMapRequestInViewerLink" :mod-disabled="showMapDisabled" mod-block mod-large>
            Bekijk op kaart
        </vl-button>
    </vl-column>
    <vl-column width-s="12" width-m="8" width-l="8">
        <p v-if="showMapDisabled" ref="downloadMapExplanation">{{ showMapDisabledExplanation }}</p>
    </vl-column>
    <vl-column width-s="6" width-m="4" width-l="4">
        <vl-button @click.prevent="fetchDownloadPmklFileUrl" :mod-disabled="showDownloadKlipDisabled" mod-block mod-secondary mod-large>
            Download .klip-bestand
        </vl-button>
    </vl-column>
    <vl-column width-s="12" width-m="8" width-l="8">
        <vl-typography>
            <p ref="downloadKlipExplanation" v-if="showDownloadKlipDisabled">{{ showDownloadKlipDisabledExplanation }}</p>
            <p v-else>Bekijk dit bestand overal offline met de <vl-link :href="klipAppUrl" target="_blank">KLIP-app (beschikbaar op Android, iOS en Windows)</vl-link></p>
        </vl-typography>
    </vl-column>
    <template v-if="showDownloadImkl || showOrderImkl">
        <vl-column width-s="6" width-m="4" width-l="4">
            <vl-button @click.prevent="fetchDownloadImklFileUrl" mod-secondary mod-block mod-large id="downloadImklButton" ref="downloadImklButton" v-if="showDownloadImkl" :key="1">
                Download IMKL-pakket
            </vl-button>
            <vl-button v-vl-modal-toggle="'orderimkl-modal'" :mod-disabled="showOrderImklDisabled" mod-secondary mod-block mod-large id="orderImklButton" ref="orderImklButton" v-if="showOrderImkl" :key="2">
                Bestel IMKL-pakket {{$filters.toCurrency(getImklPrice)}} {{ applyVat ? ' excl. btw' : '' }}
            </vl-button>
        </vl-column>
        <vl-column width-s="12" width-m="8" width-l="8">
            <vl-typography>
                <p v-if="showOrderImkl && showOrderImklDisabled" ref="orderImklExplanation">U heeft niet voldoende rechten om het IMKL-pakket te bestellen.</p>
                <p v-if="showImklAlreadyOrdered" ref="imklAlreadyOrderedExplanation">Het IMKL-pakket voor deze planaanvraag is reeds besteld.</p>
                <p v-else><vl-link :href="imklInfoUrl" target="_blank">Meer info over dit IMKL-formaat</vl-link></p>
            </vl-typography>
        </vl-column>
        <vl-modal id="orderimkl-modal" closable>
            <vl-title tag-name="h3">Bent u zeker dat u het IMKL-pakket wil bestellen?</vl-title>
            <vl-typography>
                Wanneer u het IMKL-pakket bestelt, kost dit {{$filters.toCurrency(getImklPrice)}} {{ applyVat ? ' excl. btw' : ''}}. Bent u zeker dat u het IMKL-pakket wil bestellen?
            </vl-typography>
            <template v-slot:modal-footer>
                <vl-action-group>
                    <vl-button @click="orderImklData" v-vl-modal-toggle="'orderimkl-modal'">IMKL-pakket bestellen</vl-button>
                    <vl-button class="vl-button--link" v-vl-modal-toggle="'orderimkl-modal'" icon="cross" mod-icon-before>Annuleer</vl-button>
                </vl-action-group>
            </template>
        </vl-modal>
    </template>
    <template v-if="showPendingImkl">
        <vl-column width-s="6" width-m="4" width-l="4">
            <vl-button @click.prevent="" :mod-loading="true" mod-secondary mod-block mod-large>
                Bezig met bestellen...
            </vl-button>
        </vl-column>
    </template>
    <vl-modal v-if="hasImklOrderErrorMessage" id="orderimklerror-modal" closable open>
        <vl-title tag-name="h3">IMKL-pakket kan niet besteld worden</vl-title>
        <vl-typography>
            {{getImklOrderErrorMessage}}
        </vl-typography>
        <template v-slot:modal-footer>
            <vl-action-group>
                <vl-button v-vl-modal-toggle="'orderimklerror-modal'">Ok</vl-button>
            </vl-action-group>
        </template>
    </vl-modal>
</vl-form-grid>
