<div :class="queueCssClass(serviceInfo)" role="alert">
    <div class="vl-alert__content">
        <div class="vl-infotext-wrapper">
            <template v-if="showLink">
                <kl-router-link protect class="vl-infotext" :to="routeAdminServiceDetail" :params="{name:serviceInfo.name}">
                    <div class="vl-infotext__value">{{ serviceInfo.activeMessageCount }}</div>
                    <div class="vl-infotext__text">{{ serviceInfo.name }}</div>
                    <div class="dead-letters" v-if="serviceInfo.deadLetterMessageCount > 0">{{ serviceInfo.deadLetterMessageCount }} deadletters</div>
                    <div class="service-down" v-if="isServiceDown(serviceInfo)">Service Down!</div>
                    <div class="service-down" v-for="ss in unhealthySubServices(serviceInfo)">{{ss.name}} Down</div>
                </kl-router-link>
            </template>
            <template v-else>
                <div class="vl-infotext">
                    <div class="vl-infotext__value">{{ serviceInfo.activeMessageCount }}</div>
                    <div class="vl-infotext__text">{{ serviceInfo.name }}</div>
                </div>
            </template>
        </div>
    </div>
</div>
