<div>
    <vl-form-grid v-if="additionalProcesses" class="warning">
        <vl-column v-if="additionalProcesses.kmHmPuntenOutdated">
            <vl-icon icon="alert-triangle-filled" mod-large />&nbsp;
            <span>De KmHmPunten dataset is ouder dan 24u</span>
        </vl-column>
        <vl-column v-for="unregisteredKlimKlb in additionalProcesses.unregisteredKlimKlbs" v-if="additionalProcesses.unregisteredKlimKlbs">
            <vl-icon icon="alert-triangle-filled" mod-large />&nbsp;
            <span>KLIM KLB {{unregisteredKlimKlb}} is niet gekend</span>
        </vl-column>
        <vl-column v-if="additionalProcesses.holidaysNeedsNewData">
            <vl-icon icon="alert-triangle-filled" mod-large />&nbsp;
            <kl-router-link :to="{ name: routeAdminSettings.name, hash: '#holidays'}" class="warning">Feestdagen aanvullen</kl-router-link>
        </vl-column>
        <vl-column v-for="missingUnaResponseAfterDeadline in additionalProcesses.missingUnaResponsesAfterDeadline" v-if="additionalProcesses.missingUnaResponsesAfterDeadline">
            <vl-icon icon="alert-triangle-filled" mod-large />&nbsp;
            <kl-router-link :to="{ name: routeAdminOrganisationDetail.name , hash: '#receivedMapRequests' }" :params="{organisationId: missingUnaResponseAfterDeadline.klipOrganisationId}" class="warning">{{missingUnaResponseAfterDeadline.unaName}} antwoordt laattijdig ({{missingUnaResponseAfterDeadline.numberOfMapRequests}} PA)</kl-router-link>
        </vl-column>
    </vl-form-grid>
</div>