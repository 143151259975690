import FunctionalHeader from './shared/components/kl-functional-header/kl-functional-header.vue';
import {useAlertStore, IAlertNotification, sessionTimeoutLocalStorageKey} from './shared/state/AlertModule';
import { useUserStore } from './shared/state/UserDataModule';
import { useCodeListStore } from './shared/state/CodeListModule';
import { useErrorStore } from '@/stores/error-store';
import userLocalStorage from '@/storage/user-local-storage';
import { IApplicationInfo } from '@/api/klip-api.proxy';
import { settings } from './settings/settings-routes';
import * as widgetClient from '@govflanders/vl-widget-client'
import { Widget } from '@govflanders/vl-widget-core/types/plugin'
import "reflect-metadata"
import '@govflanders/vl-widget-polyfill'
import axios from 'axios';
import { klipApiProxy } from '@/plugins/proxy-client';
import {AlertHandler, useAlertHandler} from '@/plugins/alert';
import {signalrHub, useSignalrHub} from "@/plugins/signalr";
import {computed, defineComponent, onMounted, watch, onUnmounted} from 'vue';
import {useRoute, useRouter} from '@/router/router-vue3';
import {safeRouteNameToString} from '@/router/route-utils';


export default defineComponent({
    name: 'App',
    components: {
        FunctionalHeader,
    },
    provide: {
        axiosClient: axios,
        klipApiProxy: klipApiProxy,
        signalrHub: signalrHub,
        alerts: AlertHandler,
    },
    props: {},
    setup() {
        const acmHeaderUrl: string = import.meta.env.VITE_ACM_HEADER_URL;
        const acmFooterUrl: string = import.meta.env.VITE_ACM_FOOTER_URL;

        const route = useRoute();
        const router = useRouter();

        const applicationInfo = computed((): IApplicationInfo => {
            return useCodeListStore().applicationInfo;
        });

        const isCheckingAuthentication = computed((): boolean => {
            return useUserStore().isCheckingAuthentication;
        });

        const userLoggedOn = computed((): boolean => {
            return useUserStore().loggedOn;
        });

        const alerts = computed((): IAlertNotification[] => {
            const pageSpecificAlerts = useAlertStore().pageAlerts(safeRouteNameToString(route?.name));
            const globalAlerts = useAlertStore().globalAlerts;

            return [...pageSpecificAlerts, ...globalAlerts];
        });

        const hasErrors = computed((): boolean => {
            return useErrorStore().hasErrors || (!!useUserStore().user && (useUserStore().user.roleError || !!useUserStore().user.validationError));
        });

        const absoluteUrl = computed((): string => {
            return import.meta.env.VITE_BASE_URI;
        });

        const routeSettings = computed(() => {
            return settings;
        });

        const authenticated = computed((): boolean => useUserStore().authenticated);

        const routeClass = computed((): string => {
           return safeRouteNameToString(route?.name);
        });

        const close = (id: string) => {
            useAlertHandler().removeAlert(id);
        }

        const _generateHeader = async () => {
            const widget = await widgetClient.bootstrap(acmHeaderUrl);
            widget.setMountElement(document.getElementsByClassName('global-header')[0]);
            await widget.mount();
            widget.getExtension('citizen_profile.session').then((session: any) => {
                session.configure({
                    endpoints: {
                        loginUrl: `${absoluteUrl.value}/auth/login`,
                        switchCapacityUrl: `${absoluteUrl.value}/auth/switch`,
                        loginRedirectUrl: `${absoluteUrl.value}/`,
                        logoutUrl: `${absoluteUrl.value}/auth/logout`
                    }
                });
            });
        }

        const _generateFooter = async () => {
            const footer = await widgetClient.bootstrap(acmFooterUrl);
            footer.setMountElement(document.getElementsByClassName('global-footer')[0]);
            await footer.mount();
        }

        const _widgetCapture = () => {
            widgetClient.capture((widget: Widget) => {
                const userActive = useUserStore().isAuthenticated;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                widget.getExtension('citizen_profile.session').then((session: any) => {
                    session.configure({ active: userActive });
                });
            });
        }

        const _widgetActivity = () => {
            widgetClient.capture((widget: Widget) => {
                if (widget.getPluginTypeId() === 'global_header') {
                    // Get the Citizen Profile Session extension from the global header widget.
                    widget.getExtension('citizen_profile.session').then((session: any) => {
                        /**
                         * Event handler which extends a Citizen Profile session.
                         */
                        function activityEventHandler() {
                            // Inform the Citizen Profile Session extension about activity.
                            session.extend()
                        }

                        // Build a list of event names which should be used for activity tracking.
                        const eventNames = [
                            'mousedown',
                            'mousemove',
                            'mousewheel',
                            'DOMMouseScroll',
                            'scroll',
                            'wheel',
                            'keydown',
                            'keypress',
                            'touchmove',
                            'touchstart'
                        ]
                        // Iterate through the events names.
                        for (const eventName of eventNames) {
                            // Register our event handler given event name.
                            window.addEventListener(eventName, activityEventHandler)
                        }
                    })
                }
            });
        }

        const _onAlertsChanged = (alerts: IAlertNotification[], oldAlerts: IAlertNotification[]) => {
            const autoCloseAlerts = alerts.filter((alert) => alert.autoClose);
            const autoCloseAlertsOld = oldAlerts.filter((alert) => alert.autoClose);
            if (JSON.stringify(autoCloseAlerts) !== JSON.stringify(autoCloseAlertsOld)) {
                if (autoCloseAlerts) {
                    autoCloseAlerts.forEach((alert) => {
                        setTimeout(() => {
                            useAlertHandler().removeAlert(alert.id);
                        }, alert.autoClose);
                    });
                }
            }
        }

        const _onAuthenticatedChanged = () => {
            if (!authenticated.value) {
                return;
            }
            _widgetCapture();
            _widgetActivity()
        }

        const  _onApplicationInfoChanged = async (newApplicationInfo: IApplicationInfo) => {
            if (!newApplicationInfo) {
                return;
            }

            // check if the user's session timed out, redirect to last location
            const routePath = userLocalStorage.getItem(sessionTimeoutLocalStorageKey);
            if (routePath) {
                userLocalStorage.removeItem(sessionTimeoutLocalStorageKey);
                await router.push({ path: routePath });
            }

            const buildNumberElement = document.querySelector('head meta[name="klip_buildnumber"]');
            const environmentElement = document.querySelector('head meta[name="klip_environment"]');

            buildNumberElement.setAttribute('content', newApplicationInfo.buildNumber);
            environmentElement.setAttribute('content', newApplicationInfo.environment);

            if (newApplicationInfo.environment === 'Beta') {
                const favicon = document.querySelector('link[rel="icon"]');
                favicon.setAttribute('href', import.meta.env.BASE_URL + 'favicon.beta.ico');
                document.title = 'KLIP BETA - Kabel- en Leidinginformatieplatform | Digitaal Vlaanderen';
            }
        }

        watch(
            alerts,
            _onAlertsChanged,
            { immediate: false, deep: true });

        watch(
            authenticated,
            _onAuthenticatedChanged,
            { immediate: true, deep: true });

        watch(
            applicationInfo,
            _onApplicationInfoChanged,
            { immediate: false, deep: true });


        onMounted(async () => {
            await _generateHeader();
            await _generateFooter();
        });

        onUnmounted(() => {
            useSignalrHub().stop();
        });


        return {
            acmHeaderUrl,
            acmFooterUrl,

            applicationInfo,
            isCheckingAuthentication,
            userLoggedOn,
            alerts,
            hasErrors,
            absoluteUrl,
            routeSettings,

            routeClass,

            close,
        }
    }
})
