import { OrganisationUnaSettings, OrganisationUnaSettingsInput } from '@/api/klip-api.proxy';
import {useField, useForm} from 'vee-validate';
import {EEventType, useSignalrHub} from '@/plugins/signalr';
import {useUserStore} from '@/app/shared/state/UserDataModule';
import {EUserRole} from '@/app/shared/state/user-role';
import {computed, defineComponent, onBeforeUnmount, ref, watch} from 'vue';
import {useKlipApiProxy} from '@/plugins/proxy-client';
import {klValidateRules} from '@/app/shared/components/kl-form-fields/kl-field-validation-wrapper.vue';

export default defineComponent({
    name: 'KlOrganisationUnaSettings',
    emits: ['updated-settings'],
    props: {
        value: {
            type: Object as () => OrganisationUnaSettings,
            default: () => ({}),
            required: true,
        }
    },
    setup(props, { emit }) {

        const organisationNameValidationRegex = /^[a-zA-Z0-9\-_ .+!()&ö/:,è|'@\s]*$/;
        const sscAnnotationLink: string = import.meta.env.VITE_LINKS_IMKL_SIGNING_HELP;
        const sscAnnotation: string = `Enkel relevant om <a href="${sscAnnotationLink}" target="_blank" class="vl-link">IMKL antwoorden digitaal te ondertekenen</a>`;
        const imklInfoUrl: string = import.meta.env.VITE_LINKS_IMKLINFO;


        const form = useForm();
        const disableSubmit = computed((): boolean => {
            return !form.meta.value.valid || !form.meta.value.dirty;
        });

        const initialName = ref<string>('');
        const displayNameField = useField<string>(
            'Naam als leidingbeheerder in KLIP',
            (newValue, params) => klValidateRules({ 'required': true, 'max': 90, 'regex': organisationNameValidationRegex, 'displayNameIsUnique': [initialName.value] }, newValue, params))
        const subjectSigningCertificateField = useField<string>('Subject signing certificaat');

        watch(() => props.value.displayName, (newValue: string) => { displayNameField.value.value = newValue; });
        watch(() => props.value.subjectSigningCertificate, (newValue: string) => { subjectSigningCertificateField.value.value = newValue; });


        const isSending = ref<boolean>(false);
        const shouldConfirmUnaCreation = ref<boolean>(false);
        const isUna = ref<boolean>(false);

        const editable = computed(() => {
            return useUserStore().hasRole([EUserRole.una_manager, EUserRole.klim_admin]);
        });


        const confirmUnaCreation = () => {
            _saveUnaSettings();
        }


        const _saveUnaSettings = () => {
            if (shouldConfirmUnaCreation.value) {
                useSignalrHub().subToEvent(EEventType.UtilityNetworkAuthorityRegisteredEvent, _unaCreated);
                useSignalrHub().subscribeToOrganisationNotifications(props.value.organisationId);
            }

            isSending.value = true;
            const organisationUnaSettingsInput = new OrganisationUnaSettingsInput();
            organisationUnaSettingsInput.organisationId = props.value.organisationId;
            organisationUnaSettingsInput.displayName = props.value.displayName;
            organisationUnaSettingsInput.subjectSigningCertificate = props.value.subjectSigningCertificate;

            useKlipApiProxy().organisation_EditUnaSettingsForOrganisation(organisationUnaSettingsInput).finally(() => {
                // wait emitting event when new una
                if (shouldConfirmUnaCreation.value) {
                    return;
                }
                isSending.value = false;
                shouldConfirmUnaCreation.value = false;
                emit('updated-settings', props.value.displayName);

                form.resetForm({ values: form.values });
            });
        }

        const _unaCreated = (message: IMessage = null) => {
            if (message && message.info === props.value.organisationId) {
                isSending.value = false;
                shouldConfirmUnaCreation.value = false;

                emit('updated-settings', props.value.displayName);
            }
        }

        onBeforeUnmount(() => {
            useSignalrHub().unsubFromEvent(EEventType.UtilityNetworkAuthorityRegisteredEvent);
            useSignalrHub().unSubscribeFromOrganisationNotifications(props.value.organisationId);
        });

        watch(
            () => props.value,
            (val: OrganisationUnaSettings) => {
                if (val) {
                    shouldConfirmUnaCreation.value = !props.value.isUna && editable.value;
                    isUna.value = props.value.isUna;
                    initialName.value = props.value.displayName;
                }
            },
            { immediate: true, deep: true });


        const _onInvalidSubmit = (submittedValues) => {
            console.log('_onInvalidSubmit', submittedValues);
        }

        const onSubmit = form.handleSubmit(_saveUnaSettings, _onInvalidSubmit);

        return {
            displayNameField,
            subjectSigningCertificateField,

            sscAnnotation,
            imklInfoUrl,

            isSending,
            shouldConfirmUnaCreation,
            isUna,
            initialName,

            editable,

            confirmUnaCreation,

            onSubmit,
            disableSubmit,
        }
    }
})
