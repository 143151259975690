<component :is="tagName" :class="classes">
    <header role="none" class="kl-accordion__header">
        <button class="kl-accordion__header__toggle" :aria-expanded="ariaExpanded" @click="toggle">
            <div class="kl-accordion__header__content">
                <p class="kl-accordion__header__title" v-html="title"></p>
                <p class="kl-accordion__header__subtitle" v-if="subtitle" v-html="subtitle"></p>
            </div>
            <vl-icon v-if="toggleIcon" :icon="toggleIcon" class="kl-accordion__header__icon" />
        </button>
    </header>
    <transition name="accordion" @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
        <div class="kl-accordion__content" :aria-hidden="ariaHidden" v-show="open">
            <div class="kl-accordion__content__wrapper">
                <slot></slot>
                <slot name="footer"></slot>
            </div>
        </div>
    </transition>
</component>
