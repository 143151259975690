<div>
    <!-- loading -->
    <vl-region key="loading" v-if="viewModelLoading">
        <vl-layout>
            <div v-vl-align:center>
                <vl-loader message="De gegevens worden geladen. Even geduld, a.u.b." />
            </div>
        </vl-layout>
    </vl-region>
    <!-- ./loading -->

    <template v-else-if="zone">
        <vl-region>
            <vl-layout>
                <kl-una-zone-details :zone="zone" :mailSettings="mailSettings"></kl-una-zone-details>
            </vl-layout>
        </vl-region>

        <!-- geometry -->
        <vl-region>
            <vl-layout>
                <kl-una-zone-geometry :zone="zone"></kl-una-zone-geometry>
            </vl-layout>
        </vl-region>
        <!-- ./geometry -->

        <!-- contact -->
        <vl-region>
            <vl-layout>
               <kl-una-zone-contact :zone="zone"></kl-una-zone-contact>
            </vl-layout>
        </vl-region>
        <!-- ./contact -->
    </template>
</div>
