import { computed, defineComponent, PropType, ref, watch } from 'vue';
import { useVueFuse } from 'vue-fuse';

export interface VlAutocompleteSearchResult {
  value: any
  title: string
  // key: string
  subtitle?: string
  selected?: boolean
}

export default defineComponent({
  name: 'VlAutocomplete',
  compatConfig: {
    MODE: 3
  },
  props: {
    options: {
      type: Array as PropType<VlAutocompleteSearchResult[]>,
      default: () => [],
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    feedback: {
      type: String,
      default: ''
    },
    searchValue: {
      type: String,
      default: ''
    },
    charLength: {
      type: Number,
      default: 3
    },
    // modelValue: {
    //   type: Object as PropType<VlAutocompleteSearchResult>,
    //   default: null
    // },
    modelValue: {
      type: String,
      default: null
    },
  },
  emits: ['update:modelValue', 'select', 'change'],
  setup(props, { emit }) {
    const resultsVisible = ref(false)
    const internalSelect = ref(false)
    const { search, results, noResults, loadItems } = useVueFuse(props.options, {
      shouldSort: false,
      minMatchCharLength: props.charLength,
      threshold: 0.6,
      ignoreLocation: true,
      findAllMatches: true,
      keys: ['title', 'subtitle']
    })

    const result = computed(() => {
      if (results.value && results.value.length > 0) {
        return results.value.find((object: any) => object.selected)
      }
      return null
    })
    const resetSelection = () => {
      results.value.forEach((obj: any) => (obj.selected = false))
    }
    const selectResultByKey = (value: string, key: string) => {
      internalSelect.value = true
      resetSelection()
      const foundResult = results.value.find((r: VlAutocompleteSearchResult) => r[key].toLowerCase() === value.toLowerCase())

      if (foundResult) {
        foundResult.selected = true
        resultsVisible.value = false
        search.value = foundResult.title
      }
      // emit('update:modelValue', foundResult)
      // emit('select', foundResult)
        //console.log('selectResultByKey', foundResult)
        _emitSelect(foundResult);
    }

    const select = () => {
      internalSelect.value = true
      if (result.value) {
        search.value = result.value.title
      }
      resultsVisible.value = false
      // emit('update:modelValue', result.value)
      // emit('select', result.value)
        //console.log('select', result.value)
        _emitSelect(result.value);
    }

    // const selectFromList = (key: string) => {
    //   if (key && results.value.length) {
    //     selectResultByKey(key, 'key')
    //   }
    // }

    const selectFromList = (option: VlAutocompleteSearchResult) => {
      // if (option && results.value.length) {
      //   selectResultByKey(option, 'key')
      // }
        internalSelect.value = true
        resetSelection()
        if (option) {
            option.selected = true
            resultsVisible.value = false
            search.value = option.title
        }
        // emit('update:modelValue', option)
        // emit('select', option)
        //console.log('selectFromList', option)
        _emitSelect(option);
    }

    const _emitSelect = (option: VlAutocompleteSearchResult) => {
        if (!option) {
            return;
        }
        emit('update:modelValue', option?.title);
        emit('select', option);
    }

    const inputValue = ($event: any) => {
      if ($event.target.value.length >= props.charLength && results.value && results.value.length > 0) {
        selectResultByKey($event.target.value, 'title')
      }
        emit('update:modelValue', $event.target.value);
        emit('change', $event.target.value)
    }

    watch(
      () => props.searchValue,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          search.value = newValue
        }
      }
    )

    watch(
      () => props.modelValue,
      (newValue, oldValue) => {
          search.value = newValue || '';

        // if (internalSelect.value) {
        //   internalSelect.value = false
        //   return
        // }
        // if (newValue) {
        //   if (results.value && results.value.length > 0) {
        //     selectResultByKey(newValue, 'key')
        //   }
        //   // else if (search.value !== newValue.title) {
        //   //   search.value = newValue.title
        //   //   inputValue({ target: { value: newValue.title } })
        //   // }
        // }
        // else {
        //   search.value = ''
        // }
      },
      { immediate: true, deep: true }
    )

    watch(
      () => props.options,
      async (newValue) => {
        loadItems(newValue)
        if (newValue && newValue.length > 0 && search.value) {
          selectResultByKey(search.value, 'title')
        }
      }
    )

    const showNoResults = computed(() => {
      // return noResults.value && search.value.length >= props.charLength && !props.loading
        return false;
    })

    const arrowDown = () => {
      if (results.value && !resultsVisible.value) {
        resultsVisible.value = true
      } else {
        navigateResults('down')
      }
    }
    const arrowUp = () => {
      navigateResults('up')
    }

    const navigateResults = (direction: string) => {
      if (results.value.length) {
        const currentResultIndex = results.value.findIndex((result: any) => result.selected)
        const newResultIndex =
          direction === 'down'
            ? currentResultIndex === results.value.length - 1
              ? 0
              : currentResultIndex + 1
            : !currentResultIndex || currentResultIndex === 0
              ? results.value.length - 1
              : currentResultIndex - 1

        resetSelection()
        results.value[newResultIndex].selected = true
      }
    }

    return {
      search,
      results,
      select,
      selectFromList,
      resultsVisible,
      arrowDown,
      arrowUp,
      showNoResults,
      inputValue
    }
  }
})
