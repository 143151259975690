<template>
    <vl-form-message-label :for="name" :class="{'vl-u-visually-hidden': modHiddenLabel}" v-html="parsedLabel" />
    <slot :has-errors="hasError"></slot>
    <vl-form-message-annotation v-if="annotation" :class="{'vl-form__annotation--no-wrap': modNoWrapAnnotation}" v-html="annotation"></vl-form-message-annotation>
    <vl-form-message-error v-if="hasError" :class="{'vl-form__error--no-wrap': modNoWrapError}">
        {{ errors[0] }}
    </vl-form-message-error>
</template>

<script lang="ts">

import {computed, defineComponent, onMounted, Ref} from 'vue';
import {isEmpty} from 'lodash-es';
import {validate} from 'vee-validate';

export async function klValidateRules(rules, newValue, params) {
    // console.log('_validateRules', rules, newValue, params)
    const result = await validate(newValue, rules, params);
    if (!isEmpty(result.errors)) {
        return result.errors[0];
    }
    return true;
}

export default defineComponent({
    name: 'KlFieldValidationWrapper',
    components: {},
    props: {
        field: { type: Object, required: true },
        name: { type: String, required: false },

        // label: { type: String, default: '' },
        rulesLabelOverride: { type: String, default: '' },
        modHiddenLabel: { type: Boolean, default: false, required: false },

        // TO REVIEW
        annotation: { type: String, required: false, default: undefined },
        modRequired: { type: Boolean, default: false, required: false },
        modNoWrapError: { type: Boolean, default: false, required: false },
        modNoWrapAnnotation: { type: Boolean, default: false, required: false },
    },
    setup(props, { slots }) {

        const hasError = computed((): boolean => !isEmpty(props.field.errors.value));
        const errors = computed((): string[] => props.field.errors.value);
        const label = computed((): string => (props.field.name as Ref<string>).value);

        const rulesLabel = computed((): string => {
            // label = label bij het veld in de ui
            // rulesLabel = naam van het veld in de error text + deze moet uniek zijn binnen de form
            if (props.rulesLabelOverride) {
                return props.rulesLabelOverride;
            }
            return label.value;
        });

        const parsedLabel = computed((): string => {
            return `${label.value}${props.modRequired ? '&nbsp;*' : ''}`;
        });

        onMounted(() => {
        });


        return {
            rulesLabel,
            parsedLabel,

            hasError,
            errors,
        }
    }
})

</script>

