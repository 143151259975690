<template>
  <div
    class="ippro-autocomplete js-vl-autocomplete"
    v-click-outside="hideResults"
  >
    <span v-vl-visually-hidden v-html="helpText"></span>
    <vl-input-field
      v-bind="$attrs"
      v-model="searchText"
      ref="inputField"
      autocomplete="off"
      v-on="$listeners"
      @focus="showResults"
      @keydown.tab="hideResults"
      @keyup.esc="hideResults"
      @keydown.enter="selectFocussedResult"
      @keyup.down="arrowDown"
      @keyup.up="arrowUp"
    />
    <div class="vl-autocomplete" v-if="feedback">
      <div class="vl-autocomplete__feedback" v-html="feedback" />
    </div>
    <div
      class="vl-autocomplete__loader"
      :data-vl-show="fetching.toString()"
      data-vl-loader=""
      :aria-hidden="!fetching.toString()"
    ></div>
    <div
      class="vl-autocomplete"
      data-vl-content=""
      :aria-hidden="!hasResults.toString()"
      :data-vl-show="hasResults.toString()"
      :aria-labelledby="$attrs.id"
      v-if="hasResults"
      v-show="resultsVisible"
    >
      <div class="vl-autocomplete__list-wrapper">
        <ul class="vl-autocomplete__list" data-vl-records="" role="listbox">
          <li
            class="vl-autocomplete__item"
            role="option"
            v-for="(result, index) in results"
            :key="result.value.name + '_' + index"
          >
            <a
              :class="{
                'vl-autocomplete__cta': true,
                'vl-autocomplete__cta--focus': result.selected
              }"
              href="#"
              tabindex="-1"
              :data-vl-index="index + 1"
              data-vl-record=""
              data-vl-focus=""
              :data-vl-value="result.title"
              @click.prevent="select(result.value)"
            >
              <span
                class="vl-autocomplete__cta__title"
                v-html="result.title"
              ></span>
              <span class="vl-autocomplete__cta__sub" v-if="result.subtitle">{{
                result.subtitle
              }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts" src="./ippro-autocomplete.ts"></script>

<style lang="scss">
@import "./ippro-autocomplete.scss";
</style>
