import {
    Organisation,
    OrganisationInvoiceData,
    OrganisationSettingsInput,
    OrganisationUnaSettings,
    PaymentTypes
} from '@/api/klip-api.proxy';
import { organisationSettings, settings } from '@/app/settings/settings-routes';
import {useUserStore} from '@/app/shared/state/UserDataModule';
import {EUserRole} from '@/app/shared/state/user-role';
import {computed, defineComponent, onMounted, ref, watch} from 'vue';
import KlOrganisationUnaZones from './components/kl-organisation-una-zones/kl-organisation-una-zones';
import {useRouter} from '@/router/router-vue3';
import {useKlipApiProxy} from '@/plugins/proxy-client';
import {IKlInvoiceSettingsData} from '@/app/shared/components/kl-invoice-settings/kl-invoice-settings';
import KlInvoiceSettingsForm from '@/app/shared/components/kl-invoice-settings-form/kl-invoice-settings-form.vue';
import {useField, useForm} from 'vee-validate';
import {useMetaStore} from '@/stores/meta-store';
import {RouteRecordRaw} from 'vue-router';

export default defineComponent({
    name: 'KlEditSubOrganisation',
    components: {KlInvoiceSettingsForm},
    props: {
        organisationId: { type: String, default: '' },
    },
    setup(props) {

        const router = useRouter();

        const form = useForm();
        const disableSubmit = computed((): boolean => {
            return !form.meta.value.valid || !form.meta.value.dirty;
        });

        const organisation = ref<Organisation>();
        const organisationNameField = useField<string>('Naam organisatie', 'required|max:450');
        watch(() => organisation.value?.name, (newValue: string) => { organisationNameField.value.value = newValue; });


        const organisationUnaZones = ref<InstanceType<typeof KlOrganisationUnaZones>>(null);

        const isSending = ref<boolean>(false);
        const isLoading = ref<boolean>(true);
        const editOrganisationInvoiceData = ref<boolean>(false);

        const parentOrganisationName = ref<string>('');
        const isUnaConfirmed = ref<boolean>(false);
        const organisationInvoiceData = ref<OrganisationInvoiceData>();
        const organisationUnaSettings = ref<OrganisationUnaSettings>();

        const onUpdateInvoiceSettings = (data: IKlInvoiceSettingsData) => {
            organisationInvoiceData.value.email = data.email;
            organisationInvoiceData.value.attention = data.attention;
            organisationInvoiceData.value.reference = data.reference;
        }

        const zonesDisplayName = ref<string>('');

        const routeSettings = computed((): RouteRecordRaw => {
            return organisationSettings;
        });

        const isEditable = computed((): boolean => {
            return organisation.value.parentId !== null && hasOrganisationManagerRole.value;
        });

        const canEditInvoiceData = computed(() => {
            return organisationInvoiceData.value && !organisationInvoiceData.value.isInGroup;
        });

        const showInvoiceSettings = computed((): boolean => {
            return useUserStore().paymentType === PaymentTypes.Invoice;
        });

        const showUnaSettings = computed((): boolean => {
            if (hasUnaEditRole.value) {
                return true;
            }

            return hasUnaReadRole.value;
        });

        const hasUnaEditRole = computed((): boolean => {
            return useUserStore().hasRole([EUserRole.una_manager, EUserRole.una_reader, EUserRole.klim_admin]);
        });

        const hasUnaReadRole = computed((): boolean => {
            return useUserStore().hasRole([EUserRole.una_operator]) && organisationUnaSettings.value && organisationUnaSettings.value.isUna;
        });

        const hasOrganisationManagerRole = computed((): boolean => {
            return useUserStore().hasRole([EUserRole.org_manager]);
        });


        const toggleEditMode = () => {
            editOrganisationInvoiceData.value = !editOrganisationInvoiceData.value;
        }

        const unaSettingsUpdated = (displayName: string) => {
            if (props.organisationId === useUserStore().user.klipOrganisationApiId) {
                useUserStore().updateUserProfile(true);
            }
            isUnaConfirmed.value = true;

            organisationUnaSettings.value.displayName = displayName;
        }

        const _submit = () => {
            isSending.value = true;
            const organisationInput = new OrganisationSettingsInput();
            organisationInput.organisation = new Organisation();
            organisationInput.organisation.name = organisation.value.name;
            organisationInput.organisation.organisationId = organisation.value.organisationId;
            organisationInput.organisation.parentId = organisation.value.parentId;
            useKlipApiProxy().organisation_EditOrganisation(organisationInput).then(() => {
                router.push({ name: routeSettings.value.name, hash: "#organisation" });
            }).finally(() => {
                isSending.value = false;
            })
        }

        const saveOrganisationInvoiceData = (newInvoiceData: IKlInvoiceSettingsData) => {
            isSending.value = true;

            // copy to local
            organisationInvoiceData.value.attention = newInvoiceData.attention;
            organisationInvoiceData.value.email = newInvoiceData.email;
            organisationInvoiceData.value.reference = newInvoiceData.reference;

            // save to bff
            const organisationInput = new OrganisationSettingsInput();
            organisationInput.organisation = new Organisation();
            organisationInput.organisation.name = organisation.value.name;
            organisationInput.organisation.organisationId = organisation.value.organisationId;
            organisationInput.organisation.parentId = organisation.value.parentId;
            organisationInput.organisationInvoiceData = new OrganisationInvoiceData();
            organisationInput.organisationInvoiceData.attention = organisationInvoiceData.value.attention;
            organisationInput.organisationInvoiceData.email = organisationInvoiceData.value.email;
            organisationInput.organisationInvoiceData.reference = organisationInvoiceData.value.reference;
            organisationInput.organisationInvoiceData.vatNumber = organisationInvoiceData.value.vatNumber;
            useKlipApiProxy().organisation_EditOrganisationInvoiceData(organisationInput).then(() => {
                toggleEditMode();
            }).finally(() => {
                isSending.value = false;
            })
        }

        onMounted(() => {
            useKlipApiProxy().organisation_LoadEditOrganisationView(props.organisationId).then(response => {
                organisation.value = response.result.organisation;
                organisationInvoiceData.value = response.result.invoiceData;
                organisationUnaSettings.value = response.result.unaSettings;
                useMetaStore().setPageTitle(organisation.value.name);

                if (organisation.value.parentId) {
                    const parentOrganisation = useUserStore().user.subOrganisations?.find(s => s.id === organisation.value.parentId);
                    if (parentOrganisation) {
                        parentOrganisationName.value = parentOrganisation.name;
                    }
                }
                if (organisationUnaSettings.value) {
                    isUnaConfirmed.value = organisationUnaSettings.value.isUna;
                }
            }).finally(() => {
                isLoading.value = false;

                form.resetForm({ values: form.values });
            });

            if (useUserStore().user.subOrganisations.length === 1)
            {
                useMetaStore().setParent(settings);
            }
        });

        const _onInvalidSubmit = (submittedValues) => {
            console.log('_onInvalidSubmit', submittedValues);
        }

        const onSubmit = form.handleSubmit(_submit, _onInvalidSubmit);

        return {
            organisationNameField,

            organisationUnaZones,

            isSending,
            isLoading,
            editOrganisationInvoiceData,

            parentOrganisationName,
            isUnaConfirmed,
            organisation,
            organisationInvoiceData,
            organisationUnaSettings,
            zonesDisplayName,

            onUpdateInvoiceSettings,

            routeSettings,
            isEditable,
            canEditInvoiceData,
            showInvoiceSettings,
            showUnaSettings,
            hasUnaEditRole,
            hasUnaReadRole,
            hasOrganisationManagerRole,

            toggleEditMode,
            unaSettingsUpdated,
            //submit,
            saveOrganisationInvoiceData,

            onSubmit,
            disableSubmit,
        }
    }
})
