<div>
    <div v-if="hasPaymentError">
        <vl-alert class="alert-payment-error" icon="alert-circle" title="Betaling niet geslaagd" mod-error
            :closable="true" @close="closePaymentErrorMessage">
            <p>Er liep iets mis met uw betaling. Er werden geen planaanvragen doorgestuurd. Probeer opnieuw, aub.</p>
        </vl-alert>
        <vl-u-spacer mod-small />
    </div>
    <div v-if="hasPrepaidPaymentError">
        <vl-alert class="alert-prepaid-payment-error" icon="alert-circle" title="Betaling niet geslaagd" mod-error
            :closable="true" @close="closePrepaidPaymentErrorMessage">
            <p>Er liep iets mis met uw betaling. Uw prepaid saldo werd niet verhoogd.</p>
        </vl-alert>
        <vl-u-spacer mod-small />
    </div>
    <div v-if="hasUncertainPrepaidPaymentError">
        <vl-alert class="alert-uncertain-prepaid-payment-error" icon="alert-circle" title="Betaling niet geslaagd" mod-error
            :closable="true" @close="closeUncertainPrepaidPaymentErrorMessage">
            <p>Uw betaling is in behandeling. Wanneer uw betaling geslaagd is, wordt uw prepaidsaldo verhoogd.</p>
        </vl-alert>
        <vl-u-spacer mod-small />
    </div>
    <div v-if="isShoppingCartPaymentVisible" class="vl-alert" role="alert">
        <div class="vl-alert__icon">
            <kl-mini-cart :items="numberOfItemsInCart" />
        </div>
        <div class="vl-alert__content">
            <p class="vl-alert__title">Uw bestelling staat klaar om te betalen.</p>
            <div class="vl-alert__message">
                <vl-action-group class="vl-action-group--primary">
                    <kl-router-link protect :to="routeCheckout" class="vl-button vl-button--narrow">Nu betalen
                    </kl-router-link>
                </vl-action-group>
            </div>
        </div>
        <vl-u-spacer mod-small />
    </div>
    <div v-if="onlinePaymentMessage">
        <vl-alert icon="check" :title="onlinePaymentMessage.title" mod-success :closable="true"
            @close="closeOnlinePaymentMessage">
            <template v-if="onlinePaymentMessage.hasMapRequests">
                <div class="alert-payment-error">
                    <div>{{onlinePaymentMessage.mapRequestSubject}} verstuurd naar de leidingbeheerders en naar KLIM.</div>
                    <div v-if="onlinePaymentMessage.totalPrice">Hiervoor werd {{$filters.toCurrency(onlinePaymentMessage.totalPrice)}} in rekening gebracht.</div>
                    <div>De plannen zijn ten laatste beschikbaar op {{onlinePaymentMessage.availableDate}} (binnen 15 werkdagen<span v-if="onlinePaymentMessage.showHolidays">*</span>).</div>
                    <div v-if="onlinePaymentMessage.hasMaprequestsWithImkl">Vanaf dan kan u ook het IMKL-pakket downloaden.</div>
                    <div v-if="onlinePaymentMessage.hasMapRequestExtras">U kan het apart bestelde IMKL-pakket nu downloaden in de planaanvraag.</div>
                    <p v-if="onlinePaymentMessage.showHolidays && onlinePaymentMessage.holidays" class="holidays-message">* Volgende dagen worden niet beschouwd als werkdag: {{onlinePaymentMessage.holidays}}</p>
                </div>
            </template>
            <template v-if="!onlinePaymentMessage.hasMapRequests && onlinePaymentMessage.hasMapRequestExtras">
                <div>U kan het gevraagde IMKL-pakket nu downloaden in de planaanvraag.</div>
            </template>
        </vl-alert>
        <vl-u-spacer mod-small />
    </div>
    <div v-if="hasPrepaidPaymentMessage">
        <vl-alert icon="check" title="Betaling goed ontvangen!" mod-success :closable="true"
            @close="closePrepaidPaymentMessage">
            <div>Uw saldo is opgeladen.</div>
        </vl-alert>
    </div>
</div>
