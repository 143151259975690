import {RouteRecordRaw} from 'vue-router';

const oldRoutes: RouteRecordRaw[] = [
    {
        path: '/UnaMapRequest', redirect: (to) => {
            const { hash, params, query } = to;
            return {
                name: 'unamaprequest',
                params: { id: query.maprequestid as string, zoneId: query.zoneid as string, archived: 'false' },
                query: {},
            };
        },
    },
    {
        path: '/archiveunamaprequest', redirect: (to) => {
            const { hash, params, query } = to;
            return {
                name: 'unamaprequest',
                params: { id: query.maprequestid as string, zoneId: query.zoneid as string, archived: 'true' },
                query: {},
            };
        },
    },
];

export default oldRoutes;
