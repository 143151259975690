import { DirectiveBinding, VNode } from 'vue'

const HANDLER = '_vue_click_outside_handler'

// prevent global header/footer from cancelling this click event
function watchElements(): HTMLElement[] {
    const elements: any[] = [document.documentElement]
    for (const el of document.getElementsByClassName('iw2-wrapper')) {
        elements.push(el)
    }
    return elements
}

function beforeMount(el: any, binding: DirectiveBinding, vnode: VNode & { context: any }) {
    unmounted(el)
    const vm = vnode.context
    const callback = binding.value
    let initialMacrotaskEnded = false
    setTimeout(() => {
        initialMacrotaskEnded = true
    }, 0)

    el[HANDLER] = (ev: any) => {
        const path = ev.path || (ev.composedPath ? ev.composedPath() : undefined)
        if (initialMacrotaskEnded && (path ? path.indexOf(el) < 0 : !el.contains(ev.target))) {
            return callback.call(vm, ev)
        }
    }
    watchElements().forEach((element) => {
        element.addEventListener('click', el[HANDLER], false)
    })
}

function unmounted(el: any) {
    watchElements().forEach((element) => {
        element.removeEventListener('click', el[HANDLER], false)
    })
    delete el[HANDLER]
}

const KlClickOutside = {
    beforeMount,
    update: (el: HTMLElement, binding: DirectiveBinding, vnode: VNode & { context: any }) => {
        if (binding.value === binding.oldValue) {
            return
        }
        beforeMount(el, binding, vnode)
    },
    unmounted
}

export default KlClickOutside
