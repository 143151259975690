<vl-layout>
    <template v-if="isError404">
        <vl-region>
            <vl-layout>
                <vl-form-grid mod-stacked class="vl-grid--align-center vl-grid--v-center">
                    <vl-column width="6" class="vl-u-hidden vl-u-visible--s">
                        <div class="vl-u-display-flex vl-u-flex-align-center vl-u-flex-v-center">
                            <img src="/img/page-not-found.svg"></img>
                        </div>
                    </vl-column>
                    <vl-column width="6" width-s="8" width-xs="12">
                        <vl-title tag-name="h2">Oeps, die pagina vonden we niet terug</vl-title>
                        <vl-typography>De pagina die u zoekt, vonden we niet terug.</vl-typography>
                        <vl-u-spacer mod-small />
                        <vl-typography v-if="!!validationError">
                            <span class="text-small">{{validationError}}</span>
                        </vl-typography>
                        <vl-u-spacer mod-small />
                        <vl-typography v-if="!!correlationId">
                            <span class="text-small">
                                Fout: {{correlationId}}&nbsp;
                                <vl-link mod-icon-only mod-medium icon='copy-paste' @click="copyToClipBoard"
                                    v-vl-tooltip="'Kopieer foutcode'" hidden-text='kopieer foutcode'></vl-link>
                            </span>
                        </vl-typography>
                        <vl-u-spacer mod-medium />
                        <vl-action-group>
                            <vl-column width="6">
                                <vl-link href="/" mod-button mod-block class="vl-button vl-button--large"
                                    icon="text-redo" mod-icon-before>
                                    Terug naar de startpagina
                                </vl-link>
                            </vl-column>
                            <vl-column width="6">
                                <vl-link mod-button mod-block mod-secondary mod-icon-before
                                    class="vl-button vl-button--large vl-u-spacer-left" icon="mail"
                                    hidden-text="mail naar helpdesk"
                                    :href="mailTo('KLIP Oeps-pagina', 'Oeps, die pagina vonden we niet terug.')">
                                    Contacteer de helpdesk
                                </vl-link>
                            </vl-column>
                        </vl-action-group>
                    </vl-column>
                    <vl-column width="6" class="vl-u-hidden--s">
                        <div class="vl-u-display-flex vl-u-flex-align-center vl-u-flex-v-center">
                            <img src="/img/page-not-found.svg"></img>
                        </div>
                    </vl-column>
                </vl-form-grid>
            </vl-layout>
        </vl-region>
    </template>
    <template v-else-if="isError401">
        <vl-modal id="401error-modal" open>
            <vl-title tag-name="h3">Uw beveiligde sessie is verlopen</vl-title>
            <vl-typography>Geen probleem, meld opnieuw aan om verder werken.</vl-typography>
            <vl-u-spacer mod-medium />
            <vl-action-group>
                <vl-button tag-name="a" @click.prevent="logout" class="vl-button vl-button--large" icon="text-redo"
                    mod-icon-before>
                    Opnieuw aanmelden
                </vl-button>
            </vl-action-group>
        </vl-modal>
    </template>
    <template v-else-if="isError403">
        <vl-form-grid mod-stacked class="vl-grid--align-center vl-grid--v-center">
            <vl-column width="8">
                <vl-title tag-name="h3">U heeft onvoldoende rechten om deze pagina te bekijken</vl-title>
                <vl-typography>U bent aangemeld als <strong>'{{ username }}'.</strong></vl-typography>
                <vl-typography>Mogelijks kan u deze pagina wel bekijken als u <vl-link @click="logout">aanmeldt met
                        een andere account</vl-link>.
                </vl-typography>
                <vl-u-spacer mod-medium />
                <vl-action-group>
                    <vl-button tag-name="a" @click.prevent="returnToStart" class="vl-button vl-button--large"
                        icon="text-redo" mod-icon-before>
                        Terug naar de startpagina
                    </vl-button>
                </vl-action-group>
            </vl-column>
        </vl-form-grid>
    </template>
    <template v-else-if="isRoleError">
        <vl-form-grid mod-stacked class="vl-grid--v-center">
            <vl-column width="8">
                <vl-title tag-name="h2">Account probleem</vl-title>
                <vl-typography>Je account werd niet goed geconfigureerd. Contacteer <vl-link
                        :href="'mailto:' + helpdeskMail">{{ helpdeskMail }}</vl-link> om je account correct te
                    configureren.</vl-typography>
                <vl-u-spacer mod-medium />
            </vl-column>
            <vl-column width="4">
                <img src="/img/page-not-found.svg"></img>
            </vl-column>
        </vl-form-grid>
    </template>
    <template v-else-if="isError400Or405Or500">
        <vl-region>
            <vl-layout>
                <vl-form-grid mod-stacked class="vl-grid--align-center vl-grid--v-center">
                    <vl-column width="6" class="vl-u-hidden vl-u-visible--s">
                        <div class="vl-u-display-flex vl-u-flex-align-center vl-u-flex-v-center">
                            <img src="/img/page-not-found.svg"></img>
                        </div>
                    </vl-column>
                    <vl-column width="6" width-s="8" width-xs="12">
                        <vl-title tag-name="h2">Oeps, er liep iets mis</vl-title>
                        <vl-typography>De pagina die u zoekt, kon niet geladen worden.</vl-typography>
                        <vl-u-spacer mod-small />
                        <vl-typography v-if="!!correlationId">
                            <span class="text-small">
                                Fout: {{correlationId}}&nbsp;
                                <vl-link mod-icon-only mod-medium icon='copy-paste' @click="copyToClipBoard"
                                    v-vl-tooltip="'Kopieer foutcode'" hidden-text='kopieer foutcode'></vl-link>
                            </span>
                        </vl-typography>
                        <vl-u-spacer mod-medium />
                        <vl-action-group>
                            <vl-column width="6">
                                <vl-link @click.prevent="tryAgain" mod-button mod-block
                                    class="vl-button vl-button--large" icon="text-redo" mod-icon-before>
                                    Opnieuw proberen
                                </vl-link>
                            </vl-column>
                            <vl-column width="6">
                                <vl-link mod-button mod-block mod-secondary mod-icon-before
                                    class="vl-button vl-button--large vl-u-spacer-left" icon="mail"
                                    hidden-text="mail naar helpdesk"
                                    :href="mailTo('KLIP Oeps-pagina', 'Oeps, er liep iets mis.')">
                                    Contacteer de helpdesk
                                </vl-link>
                            </vl-column>
                        </vl-action-group>
                    </vl-column>
                    <vl-column width="6" class="vl-u-hidden--s">
                        <div class="vl-u-display-flex vl-u-flex-align-center vl-u-flex-v-center">
                            <img src="/img/page-not-found.svg"></img>
                        </div>
                    </vl-column>
                </vl-form-grid>
            </vl-layout>
        </vl-region>
    </template>
</vl-layout>