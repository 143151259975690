<form @submit.prevent="search" class="kl-search-users vl-form" v-kl-u-background-alt v-kl-u-box-padding>
    <div v-kl-u-background-white v-kl-u-box-padding>
        <vl-form-grid mod-stacked>
            <vl-column width="3">
                <label for="firstName">Voornaam</label>
                <vl-input-field name="firstName" autocomplete="off" v-model="firstName" mod-block />
            </vl-column>
            <vl-column width="3">
                <label for="lastName">Naam</label>
                <vl-input-field name="lastName" autocomplete="off" v-model="lastName" mod-block />
            </vl-column>
            <vl-column width="6" v-if="!modHideOrganisation">
                <label for="organisation">Organisatie</label>
                <kl-search-organisation @selected="onOrganisationSelect" ref="searchOrganisations" />
            </vl-column>
            <vl-column width="6">
                <label for="name">Type(s)</label>
                <vl-multiselect
                    v-model="selectedKlipRoles"
                    :options="klipRoleOptions"
                    :multiple="true"
                    placeholder="Kies één of meerdere types">
                </vl-multiselect>
            </vl-column>
            <vl-column width="6">
                <!-- WebComponent hack fix: vl-checkbox doesn't apply 'class' attrib  -->
                <div class="not-active-users">
                    <vl-checkbox v-model="inActiveUsers" mod-block>Toon &gt; 6 maanden niet actieve gebruikers</vl-checkbox>
                </div>
            </vl-column>
        </vl-form-grid>
    </div>
</form>
