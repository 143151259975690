<div>
    <div class="impersonation-header" v-if="isImpersonating">
        LET OP! U bekijkt KLIP momenteel als {{ impersonatingAsUserName }}<span v-if="!!impersonatingAsOrganisationName">&nbsp;van {{ impersonatingAsOrganisationName }}</span>.&nbsp;<vl-link @click="logoutLink">Afmelden</vl-link>
    </div>
    <div :class="functionalHeaderClassName" v-vl-print:no>
        <kl-breadcrumb class="kl-functional-header__breadcrumbs" v-if="showBreadcrumbNavigation" />
        <kl-admin-navigation class="kl-functional-header__admin-navigation" v-if="showAdminNavigation" />
        <kl-mini-cart v-if="shoppingCartActive" class="kl-functional-header__cart" :items="numberItemsInCart" />
        <div class="kl-functional-header__profile">
            <vl-loader v-if="isUpdating" />
            <kl-profile-dropdown v-else-if="isLoggedOn" />
            <a :href="loginLink" class="kl-functional-header__profile__button" v-else><strong>Aanmelden</strong></a>
        </div>
    </div>

</div>
