<vl-form-grid mod-stacked>
    <vl-column>
        <kl-search-users @search="search"  mod-hide-organisation :default-filter="defaultFilter" ref="searchUsers" />
    </vl-column>

    <vl-column>
        <ippro-datatable id="users-operations-table"
                         :meta="usersMeta"
                         :dataset="users"
                         :columns="usersColumns"
                         :fetching="usersFetching"
                         @pager-clicked="pagerClicked"
                         @column-clicked="columnClicked"
                         mod-clickable-rows mod-zebra>
            <template v-slot:noresults>
                <div v-vl-align:center>
                    <p>Uw zoekopdracht heeft geen resultaten opgeleverd.</p>
                    <vl-button @click="clearSearch" class="vl-button--link">Zoekvelden wissen</vl-button>
                </div>
            </template>
        </ippro-datatable>
    </vl-column>
</vl-form-grid>
