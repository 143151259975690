<vl-form-grid>
    <vl-column>
      <vl-title tag-name="h2">Kies een betaalmethode</vl-title>
    </vl-column>
    <kl-test-payment-data/>
    <vl-column>
        <vl-radio-tile name="payment-method" value="card" id="payment-method-card" key="payment-method-card" v-model="paymentMethod" title="Online betaling" />
        <template>
            <vl-radio-tile class="prepaid" name="payment-method" value="prepaid" id="payment-method-prepaid" key="payment-method-prepaid" v-model="paymentMethod" title="Met prepaid saldo betalen" :mod-disabled="prepaidDisabled">
                <template v-if="userHadPrepaidBefore">
                    <div class="prepaid-saldo">
                        Huidig saldo:
                        <span :class="{'prepaid-saldo__value': true, 'prepaid-saldo__value--sufficient': isPrepaidSufficient}">
                            {{ $filters.toCurrency(prepaidSaldo) }}
                        </span>
                        <span class="prepaid-saldo__deficiency" v-if="!isPrepaidSufficient">
                            {{ $filters.toCurrency(this.prepaidDeficiency) }} te weinig - <vl-link @click="addPrepaid">aanvullen</vl-link>
                        </span>
                    </div>
                </template>
                <template v-else>
                    <kl-router-link protect :to="routePrepaid" class="vl-link">Laad prepaid saldo op</kl-router-link>
                </template>
            </vl-radio-tile>
        </template>
    </vl-column>
  </vl-form-grid>
