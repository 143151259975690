import { useUserStore } from '@/app/shared/state/UserDataModule';
import {
    NavigationGuard,
    NavigationGuardReturn,
    RouteLocation,
} from 'vue-router';

enum EAuthorizationCode {
    ok,
    loginFailed,
    unauthorized,
    badRequest,
    error,
}

export function createAuthGuard(): NavigationGuard {

    return async function (to: RouteLocation, from: RouteLocation): Promise<NavigationGuardReturn> {

        // requires login?
        if (to?.meta?.allowAnonymous) {
            return;
        }

        // check authentication
        const authenticationOk = await checkAuthentication(to);
        if (!authenticationOk) {
            const firstVisit = !useUserStore().klipVisited();
            if (firstVisit) {
                // register
                return { path: '/public', replace: true };
            }
            else {
                if (window) {
                    // TO VERIFY: use router.x ??
                    window.location.assign(`${window.location.origin}/auth/login?redirectUri=${to.path}`);
                }
            }
        }

        // check authorization
        const authorizationResult = await checkAuthorization(to);
        if (authorizationResult === EAuthorizationCode.ok) {
            return true;
        }
        if (authorizationResult === EAuthorizationCode.loginFailed) {
            if (window) {
                // TO VERIFY: use router.x ??
                window.location.assign(`${window.location.origin}/auth/login`);
            }
        }
        if (authorizationResult === EAuthorizationCode.unauthorized) {
            return { path: '/403', replace: true };
        }
        if (authorizationResult === EAuthorizationCode.badRequest) {
            return { path: '/400' };
        }
        if (authorizationResult === EAuthorizationCode.error) {
            return { name: 'error', replace: true };
        }

        throw new Error(`authGuard: Unhandled exception (${authorizationResult})`);
    }
}

async function checkAuthentication(to: RouteLocation) {

    if (useUserStore().isAuthenticated) {
        return true;
    }

    return await useUserStore().isUserAuthenticated();
}

async function checkAuthorization(to: RouteLocation): Promise<EAuthorizationCode> {
    try {
        if (!useUserStore().loggedOn) {
            await useUserStore().updateUserProfile();
        }

        if (!useUserStore().loggedOn) {
            return EAuthorizationCode.loginFailed;
        }

        if (useUserStore().hasRole(to.meta?.authorize?.roles)) {
            return EAuthorizationCode.ok;
        }
        return EAuthorizationCode.unauthorized;
    }
    catch (error: any) {
        if (error?.response?.status === 400) {
            return EAuthorizationCode.badRequest
        }
        return EAuthorizationCode.error;
    }
}
