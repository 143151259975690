<vl-region>
    <vl-layout>
        <vl-form-grid mod-stacked>
            <vl-column>
                <vl-title tag-name="h1">Kabel- en Leidinginformatieplatform (KLIP)</vl-title>
            </vl-column>
            <vl-column width-l="9">
                <vl-form-grid mod-stacked>
                    <vl-column>
                        <vl-typography>
                            <p>
                                Om graafschade te voorkomen moet u weten welke kabels en leidingen u in de grond kan
                                tegenkomen wanneer u grondwerken wilt uitvoeren,
                                Daarom bent u verplicht om een KLIP-aanvraag te doen. KLIP stuurt uw aanvraag door naar
                                alle mogelijk betrokken kabel- en leidingbeheerders (KLB's) en stelt met hun antwoorden
                                één overzichtelijk digitaal plan op.
                                Het plan met alle aanwezige ondergrondse infrastructuur kan u online bekijken of via de
                                <vl-link :href="linkKlipApp" target="_blank">KLIP-app voor Android, iOS en Windows.
                                </vl-link>
                            </p>
                        </vl-typography>
                        <vl-u-spacer mod-small />
                    </vl-column>
                    <vl-column>
                        <vl-title tag-name="h2">Moet ik een KLIP-aanvraag indienen?</vl-title>
                    </vl-column>
                    <vl-column>
                        <vl-typography>
                            <p>
                                Wilt u op het grondgebied van het Vlaamse Gewest grondwerken uitvoeren, dan moet u ten
                                vroegste 40 werkdagen op voorhand via KLIP <vl-link :href="linkMapRequest"
                                    target="_blank">een planaanvraag indienen</vl-link>. Lees <vl-link
                                    :href="linkMapRequestRules" target="_blank">hier</vl-link> in detail wanneer u wel
                                of niet verplicht bent om plannen op te vragen. <br />
                                Waarom een aanvraag doen? Om graafschade te voorkomen en omdat u meestal niet verzekerd
                                bent bij schade bij graafwerken als u geen plannen hebt aangevraagd.
                            </p>
                            <p>
                                KLIP-aanvragen zijn betalend. Met de opbrengst wordt KLIP continue verbeterd:
                                <ul>
                                    <li>Voor kleine werken (met een planaanvraagzone kleiner dan 200m²) betaalt u &euro;6,05 (&euro;5 excl. btw).</li>
                                    <li>Grotere werken (met een planaanvraagzone groter dan 200m²) kosten &euro;12,10 (&euro;10 excl. btw).</li>
                                </ul>
                            </p>
                            <p>
                                De grondwerken moeten binnen de 40 werkdagen na de planaanvraag worden gestart. Na 6
                                maanden zal KLIP uw planaanvraag <vl-link :href="linkArchiveMapRequest" target="_blank">archiveren</vl-link>.
                            </p>
                        </vl-typography>
                        <vl-u-spacer mod-small />
                    </vl-column>
                    <vl-column>
                        <vl-title tag-name="h2">Hoe een planaanvraag indienen?</vl-title>
                    </vl-column>
                    <vl-column>
                        <vl-typography>
                            <ol>
                                <li>
                                    <vl-link :href="routePublicRegistrationRoute" target="_blank">Registreer u als nieuwe gebruiker</vl-link>
                                     en vraag meteen plannen aan. Al geregistreerd? <vl-link :href="linkLogin">Meld u hier aan.</vl-link>
                                </li>
                                <li>Duidt de zone van de geplande grondwerken aan. KLIP stuurt de planaanvraag door naar
                                    de betrokken leidingbeheerders.</li>
                                <li>De leidingbeheerders beantwoorden de aanvraag 1 voor 1 binnen de 5 werkdagen. KLIP
                                    bundelt nadien de aangeleverde data tot één uniform digitaal plan.</li>
                                <li>U kan uw plan raadplegen via het KLIP-platform of als bestand downloaden voor offline
                                    gebruik in de <vl-link :href="linkKlipApp" target="_blank">KLIP-app voor Android,
                                        iOS en Windows.</vl-link>
                                </li>
                            </ol>
                        </vl-typography>
                        <vl-u-spacer mod-small />
                    </vl-column>
                    <vl-column>
                        <vl-title tag-name="h2">Beheert u kabels of leidingen?</vl-title>
                    </vl-column>
                    <vl-column>
                        <vl-typography>
                            <p>
                                Beheert uw organisatie ondergrondse kabels en/of leidingen op het grondgebied van het
                                Vlaamse Gewest, ook op privéterrein? <br />
                                Dan bent u wettelijk verplicht om u <vl-link :href="linkKlipLegal" target="_blank">als
                                    KLB te registreren in KLIP.</vl-link>
                            </p>
                        </vl-typography>
                        <vl-u-spacer mod-small />
                    </vl-column>
                    <vl-column>
                        <vl-title tag-name="h2">Meer weten over KLIP?</vl-title>
                    </vl-column>
                    <vl-column>
                        <vl-typography>
                            <p>
                                Surf dan naar: <vl-link :href="linkKlip" target="_blank">{{linkKlip}}</vl-link>
                            </p>
                        </vl-typography>
                        <vl-u-spacer mod-small />
                    </vl-column>
                </vl-form-grid>
            </vl-column>
            <vl-column width-l="3">
                <vl-form-grid mod-stacked>
                    <vl-column>
                        <vl-link mod-button mod-block :href="linkLogin" class="vl-button--large">Aanmelden</vl-link>
                    </vl-column>
                    <vl-column>
                        <vl-link mod-button mod-block :href="routePublicRegistrationRoute"
                            class="vl-button--secondary vl-button--large">Nieuwe registratie</vl-link>
                    </vl-column>
                </vl-form-grid>
            </vl-column>
        </vl-form-grid>
    </vl-layout>
</vl-region>
