import {klipIndex} from '@/router/klip-index';
import {EUserRole} from '@/app/shared/state/user-role';
import {RouteRecordRaw} from 'vue-router';

export const mriIndex: RouteRecordRaw = {
    path: '/mri',
    name: 'mri',
    component: () => import(/* webpackChunkName: "mri" */ './index.vue'),
    meta: {
        authorize: { roles: [EUserRole.mri, EUserRole.mrr] },
        // authorize: { roles: [EUserRole.mri, EUserRole.mrr] },
        breadcrumb: { title: '', parent: klipIndex },
    },
};

export const mriOverlayCreate: RouteRecordRaw = {
    path: '/mri/overlay/create',
    name: 'mrioverlaycreate',
    component: () => import(/* webpackChunkName: "mri" */ './overlay/create.vue'),
    meta: {
        authorize: { roles: [EUserRole.mri] },
        breadcrumb: { title: 'Nieuwe opvraging aanwezige leidingbeheerders', parent: mriIndex },
    },
};


export const mriMapRequestCreate: RouteRecordRaw = {
    path: '/mri/maprequest/create',
    name: 'mrimaprequestcreate',
    component: () => import(/* webpackChunkName: "mri" */ './create.vue'),
    meta: {
        authorize: { roles: [EUserRole.mri] },
        breadcrumb: { title: 'Nieuwe planaanvraag', parent: mriIndex },
    },
};

export const mriMapRequestResubmit: RouteRecordRaw = {
    path: '/mri/maprequest/resubmit/:id/:archived',
    name: 'mrimaprequestresubmit',
    component: () => import(/* webpackChunkName: "mri" */ './create.vue'),
    meta: {
        authorize: { roles: [EUserRole.mri] },
        breadcrumb: { title: 'Nieuwe planaanvraag', parent: mriIndex },
    },
};

export const mriMapRequestSubmitOverlay: RouteRecordRaw = {
    path: '/mri/maprequest/submitoverlay/:id',
    name: 'mrimaprequestsubmitoverlay',
    component: () => import(/* webpackChunkName: "mri" */ './create.vue'),
    meta: {
        authorize: { roles: [EUserRole.mri] },
        breadcrumb: { title: 'Nieuwe planaanvraag', parent: mriIndex },
    },
};

export const mriMapRequestShoppingCart: RouteRecordRaw = {
    path: '/mri/shoppingcart/maprequest/:id/',
    name: 'mrimaprequestshoppingcart',
    component: () => import(/* webpackChunkName: "mri" */ './create.vue'),
    meta: {
        authorize: { roles: [EUserRole.mri] },
        breadcrumb: { title: 'Planaanvraag aanpassen', parent: mriIndex },
    },
};

export const mriMapRequest: RouteRecordRaw = {
    path: '/mri/maprequest/:id/map',
    name: 'mrimaprequest',
    component: () => import(/* webpackChunkName: "mri" */ './map-request.vue'),
    meta: {
        authorize: { roles: [EUserRole.mri, EUserRole.mrr, EUserRole.helpdesk] },
        breadcrumb: { title: 'Planaanvraag', parent: mriIndex },
    },
};

export const mriOverlayDetail: RouteRecordRaw = {
    path: '/mri/overlay/:id',
    name: 'mrioverlaydetail',
    component: () => import(/* webpackChunkName: "mri" */ './overlay/id.vue'),
    meta: {
        authorize: { roles: [EUserRole.mri] },
        breadcrumb: { title: 'Opvraging', parent: mriIndex },
    },
};

export const mriShoppingCart: RouteRecordRaw = {
    path: '/mri/shoppingcart',
    name: 'mrishoppingcart',
    component: () => import('./shopping-cart/index.vue'),
    meta: {
        authorize: { roles: [EUserRole.mri] },
        breadcrumb: { title: 'Winkelmandje', parent: klipIndex },
    },
};

export const mriInvoices: RouteRecordRaw = {
    path: '/mri/invoices',
    name: 'mriinvoices',
    component: () => import('./invoices/index.vue'),
    meta: {
        authorize: { roles: [EUserRole.mri, EUserRole.mrr] },
        breadcrumb: { title: 'Facturen', parent: klipIndex },
    },
};

export const mriViewer: RouteRecordRaw = {
    path: '/mri/viewer/:id',
    name: 'mriviewer',
    component: () => import('./../viewer/index.vue'),
    meta: {
        authorize: { roles: [EUserRole.mri, EUserRole.mrr, EUserRole.helpdesk, EUserRole.admin] },
        breadcrumb: {
            title: 'Leidingviewer',
            parent: klipIndex // mriViewer is opened on a separate tab = we do not need a mriMapRequest as parent
        },
    },
};

export const mriInvoicesForContact: RouteRecordRaw = {
    path: '/mri/invoices/:userId',
    props: true,
    name: 'invoicesforcontact',
    component: () => import('./invoices/index.vue'),
    meta: {
        authorize: { roles: [EUserRole.helpdesk, EUserRole.admin] },
        breadcrumb: { title: 'Facturen en betalingsbewijzen', parent: klipIndex },
    },
};

export default [
    mriIndex,
    mriMapRequestCreate,
    mriMapRequest,
    mriOverlayCreate,
    mriMapRequestResubmit,
    mriMapRequestShoppingCart,
    mriMapRequestSubmitOverlay,
    mriOverlayDetail,
    mriInvoices,
    mriInvoicesForContact,
    mriViewer,
    mriShoppingCart,
];
