﻿<div>
    <template v-if="isCitizen">
        <vl-typography v-if="isCitizen">
            Heeft u een geldig rijksregister- of BIS-nummer? U hoeft zich niet te registreren:
            <vl-link href="auth/login">Meld u onmiddellijk aan op KLIP.</vl-link>
            <br/>
            Geen geldig rijksregister- of BIS-nummer? <vl-link href="https://www.vlaanderen.be/informatie-en-communicatie-van-de-vlaamse-overheid/over-1700">Contacteer 1700</vl-link>
        </vl-typography>
    </template>
    <template v-else>
        <vl-form-grid mod-stacked>
            <vl-column width-s="5" width-m="5" width-l="5">
                <kl-field-validation-wrapper :field="organisationCodeField" :annotation="organisationCodeAnnotation" #default="{hasErrors}" mod-required>
                    <vl-input-field name="organisationCode" id="organisation-code" v-model="organisationCode"
                                    :mod-error="hasErrors" mod-block/>
                </kl-field-validation-wrapper>
                <vl-u-spacer mod-small/>
            </vl-column>
            <vl-column>
                <vl-typography>
                    Geen KBO- of OVO-code? Contacteer <a href="mailto:veiligheidsbouwstenen@vlaanderen.be">veiligheidsbouwstenen@vlaanderen.be</a> of 1700 (vanuit buitenland: +32 2 553 1700)
                </vl-typography>
                <vl-u-spacer mod-small/>
            </vl-column>
            <vl-column>
                <vl-checkbox v-model="isLegalRepresentative" mod-block>U bent
                    wettelijk vertegenwoordiger van deze organisatie. &nbsp;
                    <vl-icon icon="info-filled" v-vl-tooltip.right="'Een organisatie met ondernemingsnummer heeft in principe één of meerdere wettelijke vertegenwoordigers. Dit zijn personen die de onderneming juridisch vertegenwoordigen (bv. zaakvoerders, burgemeesters, bestuurders, voorzitters, ...).'" />
                </vl-checkbox>
            </vl-column>
        </vl-form-grid>
    </template>
</div>
