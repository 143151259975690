<div>
        <!-- loading -->
    <vl-region v-if="isLoading">
        <vl-layout>
            <div v-vl-align:center>
                <vl-loader message="De gegevens worden geladen. Even geduld, a.u.b." />
            </div>
        </vl-layout>
    </vl-region>
    <!-- ./loading -->
    <vl-region v-else-if="suborganisations">
        <vl-layout>
            <vl-title tag-name="h2">Nieuwe suborganisatie</vl-title>
            <vl-u-spacer mod-medium />
<!--            <vl-form-validation-observer v-slot="{ handleSubmit, invalid, pristine }">-->
                <form class="vl-form" @submit="onSubmit">
                    <vl-form-grid mod-stacked>
                        <vl-column width-s="12" width-m="8" width-l="6">
                            <kl-field-validation-wrapper :field="organisationNameField" #default="{hasErrors}" mod-required>
                                <vl-input-field name="name" id="organisation-name" v-model="organisationName" :mod-error="hasErrors" mod-block />
                            </kl-field-validation-wrapper>
                        </vl-column>
                        <vl-column width-s="0" width-m="4" width-l="6" v-vl-visibility:hide.s></vl-column>
                        <vl-column width-s="12" width-m="8" width-l="6">
                            <kl-field-validation-wrapper :field="organisationParentIdField" #default="{hasErrors}" mod-required>
                                <vl-select name="parent-organisation" id="parent-organisation" v-model="organisationParentId" :mod-error="hasErrors" mod-no-placeholder mod-block>
                                    <option :value="item.id" v-for="(item, index)  in suborganisations" :key="item.id">
                                         <span v-html="setDepth(item.depth, item.name)"></span>
                                    </option>
                                </vl-select>
                            </kl-field-validation-wrapper>
                        </vl-column>
                    </vl-form-grid>
                    <vl-u-spacer mod-medium />
                    <template v-if="invoiceData">
                        <kl-invoice-settings @update:model-value="onUpdateInvoiceSettings" :invoice-data="invoiceData" :edit-mode="true" mod-hide-group />
                    </template>
                    <vl-u-spacer mod-medium />
                    <vl-typography>
                        <p><strong>Let op!</strong> U kan een aangemaakte suborganisatie zelf niet verwijderen.</p>
                    </vl-typography>
                    <vl-u-spacer mod-medium />
                    <vl-button type="submit" :mod-disabled="disableSubmit" :mod-loading="isSending" mod-large>Suborganisatie toevoegen</vl-button>
                </form>
<!--            </vl-form-validation-observer>-->
        </vl-layout>
    </vl-region>
</div>
