import KlPublic from './public.vue';
import KlRegistration from './registration.vue';
import {klipIndex} from '@/router/klip-index';
import {RouteRecordRaw} from 'vue-router';

export const publicRoute: RouteRecordRaw = {
    path: '/public',
    name: 'public',
    component: KlPublic,
    meta: {
        breadcrumb: { title: '', parent: klipIndex },
        allowAnonymous: true,
    },
};

export const publicRegistrationRoute: RouteRecordRaw = {
    path: '/registration',
    name: 'registration',
    component: KlRegistration,
    meta: {
        breadcrumb: { title: '', parent: klipIndex },
        allowAnonymous: true,
    },
};


export default [publicRoute, publicRegistrationRoute];

