import Vue from 'vue';
import {useUserStore} from '@/app/shared/state/UserDataModule';
import {EUserRole} from '@/app/shared/state/user-role';

export default {
    install(vue: typeof Vue): void {
        vue.prototype.$hasPermission = (roles: EUserRole[]): boolean => useUserStore().hasRole(roles);
    },
};

export function usePermission() {
    return {
        hasPermission: (roles: EUserRole[]): boolean => useUserStore().hasRole(roles),
    }
}
