<template>
  <vl-map-search>
    <vl-input-group>
      <vl-input-addon
        tag-name="button"
        type="button"
        @click.prevent="focusSearch"
        tooltip=""
        icon="search"
        text="zoeken"
      />
      <ippro-autocomplete
        :data="data"
        :fetching="fetching"
        v-model="searchField"
        ref="autocomplete"
        @input="onInput"
        @select="onSelect"
        placeholder="Adres, perceelnummer, coördinaat.."
        @keydown.enter="onEnter"
        mod-block
        mod-select-first-result
      />
    </vl-input-group>
  </vl-map-search>
</template>

<script lang="ts" src="./kl-map-search.ts"></script>

<style lang="scss">
@import "./kl-map-search.scss";
</style>
