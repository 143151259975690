<div>
	<vl-region>
		<vl-layout>
			<vl-steps mod-line>
				<template v-for="(step, i) in steps" :key="'step-' + i">
					<vl-step
						:title="step.type"
						:subtitle="amountOfMessages(step)"
						:badge-icon="step.status === 'Success' || step.status === 'SuccessWithWarnings' ? 'check' : step.status === 'Failed' ? 'alert-triangle' : 'question-mark-small'"
						:mod-accordion="accordion(step)"
						:mod-success="step.status === 'Success' || step.status === 'SuccessWithWarnings'"
						:mod-error="step.status === 'Failed'"
						:mod-disabled="step.status === 'NotExecuted'">
						<template v-for="(message, j) in step.validationMessages" :key="'message-' + j">
							<ippro-icon-text
								v-if="message.level === 'Warning'"
								tag-name="span"
								icon="alert-triangle-filled"
								v-vl-text.bold.warning>
									<span class="level-label">{{ message.level }}</span>
									<vl-typography tag-name="span">
										in {{ message.fileName }}: {{ message.code }}
									</vl-typography>
							</ippro-icon-text>
							<ippro-icon-text
								v-if="message.level === 'Error'"
								tag-name="span"
								icon="alert-circle-filled"
								v-vl-text.bold.error>
									<span class="level-label">{{ message.level }}</span>
									<vl-typography tag-name="span">
										in {{ message.fileName }}: {{ message.code }}
									</vl-typography>
							</ippro-icon-text>
							<vl-typography>
								<kl-description-list-row v-if="message.element && step.order === 2" :label="message.element" mod-collapse>{{ message.description }}</kl-description-list-row>
								<template v-else>{{ message.description }}</template>
							</vl-typography>
						</template>
					</vl-step>
				</template>
			</vl-steps>
		</vl-layout>
	</vl-region>
</div>
