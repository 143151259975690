<form @submit.prevent="search" class="kl-search-organisations vl-form" v-kl-u-background-alt v-kl-u-box-padding>
    <div v-kl-u-background-white v-kl-u-box-padding>
        <vl-form-grid mod-stacked>
            <vl-column width="2" s-width="12" v-for="field in searchFields" :key="field.key">
                <vl-radio :id="uniqueFieldId(field.key)" :value="field.key" v-model="searchField"
                          @input="searchFieldChanged" name="filter">{{ field.label }}</vl-radio>
            </vl-column>
            <vl-column width="12" v-if="correlationIdSelected">
                <vl-input-field name="keyword" placeholder="Zoek op correlation-id" v-model="deadLetterCorrelationId" autocomplete="off" mod-block />
            </vl-column>
            <vl-column width="12" v-if="messageTypeSelected">
                <vl-select id="search-message-type" name="search-message-type" v-model="deadLetterMessageType" mod-block>
                    <option value="all">Alle types</option>
                    <option :value="messageType" v-for="messageType in deadLetterMessageTypes">
                        {{ messageType }}
                    </option>
                </vl-select>
            </vl-column>
        </vl-form-grid>
    </div>
</form>