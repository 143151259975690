<div>
    <vl-title tag-name="h2">Organisaties</vl-title>
    <!-- loading -->
    <vl-region v-if="isLoading">
        <vl-layout>
            <div v-vl-align:center>
                <vl-loader message="De gegevens worden geladen. Even geduld, a.u.b." />
            </div>
        </vl-layout>
    </vl-region>
    <!-- ./loading -->
    <vl-region v-else>
        <vl-form-grid mod-stacked mod-v-center v-if="loaded">
            <vl-column key="organisationSettings" >
                <kl-description-list>
                    <template v-for="(value, key) in organisationSettings.identificators">
                        <kl-description-list-row :label="key">{{ value }}</kl-description-list-row>
                    </template>
                </kl-description-list>
            </vl-column>
            <vl-column>
                <ippro-datatable id="organsation-settings-table" mod-zebra mod-hide-pagination mod-clickable-rows :fetching="false" :meta="meta" :dataset="organisationsDataTable" :columns="columns">
                    <template v-slot:noresults>
                        <div v-vl-align:center>
                            <vl-u-spacer mod-large />
                            <p>Er zijn nog geen suborganisaties binnen uw organisatie.</p>
                        </div>
                    </template>
                    <template v-slot:actions v-if="hasOrganisationAdminRole">
                        <kl-router-link protect :to="routeNewSubOrganisation" class="vl-link vl-link-add-zone">
                            <vl-icon icon="plus" class="vl-link__icon vl-link__icon--before" />
                            Suborganisatie toevoegen
                        </kl-router-link>
                    </template>
                </ippro-datatable>
            </vl-column>
        </vl-form-grid>
    </vl-region>
</div>
