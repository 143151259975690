import {defineComponent, onMounted, ref, computed} from "vue";
import * as KlipApi from "@/api/klip-api.proxy";
import { AdminInvoiceGroup, AdminNewInvoiceGroup } from "@/app/admin/admin-routes";
import {useKlipApiProxy} from '@/plugins/proxy-client';
import {RouteRecordRaw} from 'vue-router';

export default defineComponent({
    setup() {
        const proxy = useKlipApiProxy();
        const invoiceGroups = ref<KlipApi.InvoiceGroupOverviewDto[]>([])
        const loading = ref<Boolean>(true);

        const routeAdminNewInvoiceGroup = computed((): RouteRecordRaw => {
            return AdminNewInvoiceGroup;
        });

        const meta = computed((): any => {
            return {
                totalRows: invoiceGroups.value?.length ?? 0,
                resultsPerPage: invoiceGroups.value?.length ?? 0,
                currentPage: 0
            };
        });

        const cellParser = (row: IRow, column: IColumn) => {
            const routeParams = { parentId: row.parentId };
            switch (column.key) {
                default:
                    const cellValue = !!row[column.key] ? row[column.key] : '&nbsp;';
                    return {
                        template: `<kl-router-link :to='${JSON.stringify(AdminInvoiceGroup)}' :params='${JSON.stringify(routeParams)}'>${cellValue}</kl-router-link>`,
                    };
            }
        };

        const invoiceGroupsColumns = ref<IColumn[]>([
            {
                key: 'parentId',
                label: 'ParentId',
                sortable: false,
                isVisible: false,
                parser: cellParser
            },
            {
                key: 'rootOrganisationName',
                label: 'Hoofdorganisatie',
                sortable: true,
                isVisible: true,
                direction: 1,
                parser: cellParser
            },
            {
                key: 'parentOrganisationName',
                label: 'Hoofd facturatiegroep',
                sortable: true,
                isVisible: true,
                parser: cellParser
            },
            {
                key: 'nrOfOrganisations',
                label: '# organisaties',
                sortable: true,
                isVisible: true,
                parser: cellParser
            },
        ]);

        onMounted(async () => {
            const invoiceGroupsEnvelope = await proxy.invoiceGroups_InvoiceGroups();
            invoiceGroups.value = invoiceGroupsEnvelope.result;
            loading.value = false;
        });

        return {
            loading,
            invoiceGroups,
            routeAdminNewInvoiceGroup,
            invoiceGroupsColumns,
            meta
        };
    }
});
