export enum EUserRole {
    mri = 'mri',
    mrr = 'mrr',
    una_manager = 'una_manager',
    una_operator = 'una_operator',
    una_reader = 'una_reader',
    klim_admin = 'klim_admin',
    pda_manager = 'pda_manager',
    helpdesk = 'helpdesk',
    admin = 'admin',
    org_manager = 'org_manager',
}
