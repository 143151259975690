<div v-if="settings">
    <vl-title tag-name="h2">Contactvoorkeuren</vl-title>
    <vl-u-spacer mod-medium />

    <form class="vl-form" @submit.prevent="submit">
        <vl-form-grid mod-stacked>
            <vl-column>
                <vl-title tag-name="h3">Welke meldingen wil u ontvangen?</vl-title>
                <!-- plannenpakket -->
                <template v-if="showMriSettings">
                    <vl-form-grid mod-stacked>
                        <vl-form-column>
                            <vl-title tag-name="h4">Plannenpakket aanvragen</vl-title>
                        </vl-form-column>
                        <vl-form-column>
                            <vl-typography>
                                <p>Als uw aangevraagde plannenpakket volledig beschikbaar is, sturen we altijd een mail.</p>
                            </vl-typography>
                        </vl-form-column>
                        <vl-form-column>
                            <vl-checkbox v-model="mriSettings.mailConfirmation">
                                <span class="checkbox-label">Bevestiging planaanvraag</span>
                                <vl-link :href="mriSettings.mailConfirmationExampleUri" v-if="mriSettings.mailConfirmationExampleUri" icon="file" target="_blank" mod-icon-before>voorbeeld</vl-link>
                            </vl-checkbox>
                        </vl-form-column>
                        <vl-form-column>
                            <vl-checkbox v-model="mriSettings.mailIncompletePackageAvailable">
                                <span class="checkbox-label">Plannenpakket onvolledig beschikbaar</span>
                                <vl-link :href="mriSettings.mailIncompletePackageAvailableExampleUri" v-if="mriSettings.mailIncompletePackageAvailableExampleUri" icon="file" target="_blank" mod-icon-before>voorbeeld</vl-link>
                            </vl-checkbox>
                        </vl-form-column>
                        <vl-form-column>
                            <vl-typography>
                                <p>Planaanvragen worden na 6 maand automatisch gearchiveerd.</p>
                            </vl-typography>
                        </vl-form-column>
                        <vl-form-column>
                            <vl-checkbox v-model="mriSettings.mailSoonToBeArchived">
                                <span class="checkbox-label">Melding voor planaanvragen die binnenkort gearchiveerd zullen worden</span>
                                <vl-link :href="mriSettings.mailSoonToBeArchivedExampleUri" v-if="mriSettings.mailSoonToBeArchivedExampleUri" icon="file" target="_blank" mod-icon-before>voorbeeld</vl-link>
                            </vl-checkbox>
                        </vl-form-column>
                    </vl-form-grid>
                    <vl-u-spacer mod-small />
                </template>
                <!-- ./plannenpakket -->
            </vl-column>
        </vl-form-grid>
        <vl-u-spacer mod-large />
        <vl-button type="submit" :mod-disabled="!hasChanged || isSending" :mod-loading="isSending" mod-large>Bewaar veranderingen</vl-button>
    </form>
</div>
