import {defineComponent} from 'vue';

export default defineComponent({
    name: 'KlDescriptionListRow',
    props: {
        label: { type: String, default: '' },
        modCollapse: { type: Boolean, default: false },
    },
    setup(props) {
    }
})
