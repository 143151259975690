import {App} from 'vue';
import {
    createRouter,
    createWebHistory,
    RouteLocation,
    Router,
    RouteRecordRaw,
    useRoute as useVueRoute, useRouter as useVueRouter
} from 'vue-router';
import {klipIndex} from '@/router/klip-index';
import {klipApi} from '@/router/klip-api-route';
import {createAuthGuard} from '@/router/guards/authGuard';
import {createHashGuard} from '@/router/guards/hashGuard';
import {createScopeGuard} from '@/router/guards/scopeGuard';
import {createCleanupHook} from '@/router/guards/cleanupHook';

function getModuleRoutes(): RouteRecordRaw[] {
    const routeFiles = import.meta.glob(
        '@/app/**/*-routes.ts',
        { eager: true }
    );

    const routes: RouteRecordRaw[] = [];
    Object.entries(routeFiles).forEach(([key, m]) => {
        (m as any).default.forEach((route: RouteRecordRaw) => {
            routes.push(route);
        });
    });

    return routes;
}

function getErrorRoutes(): RouteRecordRaw[] {
    return [
        { path: '/400', name: '400', meta: { allowAnonymous: true }, component: () => import('@/app/error/error.vue') },
        { path: '/403', component: () => import('@/app/error/error.vue') },
        { path: '/404', component: () => import('@/app/error/error.vue') },
        { path: '/error', name: 'error', meta: { allowAnonymous: true }, component: () => import('@/app/error/error.vue') },
    ];
}

function getAllRoutes(): RouteRecordRaw[] {
    const routes = [
        klipIndex,
        klipApi,
        ...getModuleRoutes(),
        ...getErrorRoutes(),
        { path: '/:pathMatch(.*)', redirect: '/404' },
    ];
    return routes;
}

const router = createRouter({
    history: createWebHistory(),
    routes: getAllRoutes(),
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { left: 0, top: 0 };
        }
    },
})

export default {
    install(app: App): void {

        // TODO: define onError & use throw to redirect to error pages
        // see: https://router.vuejs.org/api/interfaces/Router.html#onError
        router.onError(async (error) => {
            console.error('router.onError', error);

            // TEMP: VUE3-MIGRATION-FIX
            // TODO?
        });

        router.beforeEach(async (to, from) => {
            // console.log('router.beforeEach - start', to)
            app.config.globalProperties.$Progress?.start();
        });
        router.beforeEach(createHashGuard());
        router.beforeEach(createAuthGuard());
        router.beforeEach(createScopeGuard());

        router.afterEach(createCleanupHook());

        router.afterEach(async (to, from) => {
            // console.log('router.afterEach - finish', to)
            app.config.globalProperties.$Progress?.finish();
        });

        app.use(router);
    }
};

export function useRouter(): Router {
    return useVueRouter();

    // TO INVESTIGATE:
    // exporting the 'router = createRouter' created in this has better results than the useRouter() in vue-router
    // BUT, I prefer not to use custom code
}

export function useRoute(): RouteLocation<string> {
    return useVueRoute<string>();

    // TO INVESTIGATE:
    // using the 'router.currentRoute' created in this has better results (better reactive behaviour) than the useRoute() in vue-router
    // CODE: return toReactive(router.currentRoute)
    // BUT, I prefer not to use custom code
}
