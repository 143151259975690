<div id="admin-settings-messages">
    <vl-region>
        <vl-form-grid mod-stacked>
            <vl-column width="8">
                <vl-title tag-name="h2">Berichten in webtoepassing</vl-title>
            </vl-column>
            <vl-column width="4">
                <vl-action-group class="vl-action-group--align-right--l vl-action-group--primary">
                    <vl-button mod-medium @click="newMessage">Nieuw bericht</vl-button>
                </vl-action-group>
            </vl-column>
            <vl-column>
                <ippro-datatable mod-zebra mod-hide-pagination mod-clickable-rows
                                 :dataset="messages"
                                 :columns="columns"
                                 :meta="meta"
                                 :fetching="isDataTableLoading"
                                 @row-clicked="rowClicked" >
                    <template v-slot:noresults>
                        <div v-vl-align:center>
                            <p>Geen berichten gevonden.</p>
                        </div>
                    </template>
                </ippro-datatable>
            </vl-column>
        </vl-form-grid>

    </vl-region>

<!--    <vl-form-validation-observer v-slot="{ handleSubmit, invalid, pristine }" v-if="message">-->
        <form class="vl-form" @submit="onSubmit">
            <vl-modal :id="messageModalId" mod-medium :closable="true">
                <vl-title tag-name="h3" v-if="isNewMessage">Nieuw bericht</vl-title>
                <vl-title tag-name="h3" v-else>Bericht</vl-title>

                <vl-form-grid mod-stacked>
                    <vl-column width-s="12" width-m="8" width-l="12">
                        <strong>Type:</strong>
                    </vl-column>
                    <vl-column width-l="12">
                        <vl-radio id="message-type-Announcement" name="message-type" v-model="message.type" value="Announcement">{{typeMetadata['Announcement'].translation}}</vl-radio>
                        <vl-radio id="message-type-Warning" name="message-type" v-model="message.type" value="Warning">{{typeMetadata['Warning'].translation}}</vl-radio>
                        <vl-radio id="message-type-WhatsNew" name="message-type" v-model="message.type" value="WhatsNew">{{typeMetadata['WhatsNew'].translation}}</vl-radio>
                    </vl-column>

                    <vl-column width-l="4">
                        <kl-field-validation-wrapper :field="startDatesField" #default="{hasErrors}" mod-required>
                            <kl-datepicker name="startDate" id="start-date" visual-format="d/m/Y" v-model="message.startDates" :mod-error="hasErrors"/>
                        </kl-field-validation-wrapper>
                    </vl-column>
                    <vl-column width-l="4">
                        <kl-field-validation-wrapper :field="endDatesField" #default="{hasErrors}">
                            <kl-datepicker name="endDate" id="end-date" visual-format="d/m/Y" v-model="message.endDates" :mod-error="hasErrors"/>
                        </kl-field-validation-wrapper>
                    </vl-column>

                    <vl-column width-l="12">
                        <kl-field-validation-wrapper :field="titleField" #default="{hasErrors}">
                            <vl-input-field name="title" id="title" v-model="message.title" :mod-error="hasErrors" mod-block/>
                        </kl-field-validation-wrapper>
                    </vl-column>

                    <vl-column width-l="12">
                        <kl-field-validation-wrapper :field="textField" #default="{hasErrors}" mod-required>
                            <vl-textarea rows="6" name="text" id="text" v-model="message.text" :mod-error="hasErrors" mod-block />
                        </kl-field-validation-wrapper>
                    </vl-column>

                    <vl-column width-s="12" width-m="8" width-l="12">
                        <vl-form-message-label>Preview</vl-form-message-label>
                        <kl-header-message v-if="isHeaderMessage" :messages="headerMessages"/>
                        <kl-whatsnew v-if="isWhatsNew" :title="message.title" :message="message.text"/>
                    </vl-column>

                </vl-form-grid>
                <template v-slot:modal-footer>
                    <vl-action-group>
                        <vl-button type="submit" mod-large :mod-disabled="saving">Bewaren</vl-button>
                        <vl-button @click="deleteMessage" mod-large mod-secondary :mod-loading="deleting" :mod-disabled="deleting" :mod-error="!deleting" v-if="!isNewMessage">Verwijderen</vl-button>
                        <vl-button class="vl-button--link" v-vl-modal-toggle="'message-modal'" icon="cross" mod-icon-before>Annuleer</vl-button>
                    </vl-action-group>
                </template>
            </vl-modal>
        </form>
<!--    </vl-form-validation-observer>-->
</div>
