<vl-form-grid>
  <!-- processing -->
  <vl-column v-if="processing || isValidating">
      <div :class="{'vl-u-highlight-content': modBordered, 'vl-u-align-center': true}">
          <vl-u-spacer />
          <vl-loader :message="config.processingMessage" />
          <vl-u-spacer />
      </div>
  </vl-column>
  <!-- ./processing -->

  <!-- upload -->
  <vl-column v-show="!isValidating && !processing && !error && !success">
      <kl-upload
        :class="{'vl-u-highlight-content': modBordered}"
        id="imkl-upload"
        name="imkl-upload"
        :config="config"
        :auto-process="true"
        :options="{timeout: 0}"
        :mod-revertable="revertable"
        :mod-disabled="modDisabled"
        @file-uploaded="onFileUploaded"
        @upload-success="onUploadSuccess"
        @upload-error="onUploadError"
        @revert="revert"/>
  </vl-column>
  <!-- ./upload -->

</vl-form-grid>