<vl-autocomplete
    ref="componentRef"
    :model-value="localValue"
    @update:modelValue="updateValue"
    :char-length="3"
    :loading="fetching"
    :options="data"
    :searchValue="searchValue"
    v-bind="$attrs"
    @change="change"
    @select="select"
/>
