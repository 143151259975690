<div class="admin-navigation">
    <ul class="admin-navigation__list">
        <li v-if="hasAdminRole" :class="{'admin-navigation__list__item': true, 'admin-navigation__list__item--active': isAdminHomeRoute}">
            <kl-router-link protect :to="routeAdminHome" class="admin-navigation__list__item__link">
                <span class="admin-navigation__list__item__icon">
                    <vl-icon icon="places-home" mod-large></vl-icon>
                </span>
            </kl-router-link>
        </li>
        <li v-if="hasAdminRole" :class="{'admin-navigation__list__item': true, 'admin-navigation__list__item--active': isAdminEventsRoute}">
            <kl-router-link protect :to="routeAdminEvents" class="admin-navigation__list__item__link">
                Events
            </kl-router-link>
        </li>
        <li :class="{'admin-navigation__list__item': true, 'admin-navigation__list__item--active': isAdminMriRoute}">
            <kl-router-link protect :to="routeAdminMri" class="admin-navigation__list__item__link">
                Planaanvragen
            </kl-router-link>
        </li>
        <li :class="{'admin-navigation__list__item': true, 'admin-navigation__list__item--active': isAdminUserRoute}">
            <kl-router-link protect :to="routeAdminUser" class="admin-navigation__list__item__link">
                Gebruikers
            </kl-router-link>
        </li>
        <li :class="{'admin-navigation__list__item': true, 'admin-navigation__list__item--active': isAdminOrganisationRoute}">
            <kl-router-link protect :to="routeAdminOrganisation" class="admin-navigation__list__item__link">
                Organisaties
            </kl-router-link>
        </li>
        <li v-if="hasAdminRole" :class="{'admin-navigation__list__item': true, 'admin-navigation__list__item--active': isAdminInvoiceRoute}">
            <kl-router-link protect :to="routeAdminInvoice" class="admin-navigation__list__item__link">
                Facturatie
            </kl-router-link>
        </li>
        <li v-if="hasAdminRole" :class="{'admin-navigation__list__item': true, 'admin-navigation__list__item--active': isAdminSettingsRoute}">
            <kl-router-link protect :to="routeAdminSettings" class="admin-navigation__list__item__link">
                Instellingen
            </kl-router-link>
        </li>
    </ul>
</div>