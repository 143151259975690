<div>
    <vl-alert
        v-for="message in messages"
        :key="message.id"
        :icon="message.icon"
        :title="message.title"
        :closable="message.isClosable"
        @close="closeMessage(message.id)"
        :mod-success="message.type === 'mod-success'"
        :mod-warning="message.type === 'mod-warning'"
    >
        <div v-html="message.message"></div>
    </vl-alert>
</div>
