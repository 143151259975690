import  {App, Plugin} from 'vue';
import { IpproIconText } from '@/app/shared/components/ippro-icon-text';
import { IpproDatatable } from '@/app/shared/components/ippro-datatable';
import { IpproAutocomplete } from '@/app/shared/components/ippro-autocomplete';
import { IpproMap } from '@/app/shared/components/ippro-map';

export default {
    install(app: App) {
        app.component('ippro-icon-text', IpproIconText);
        app.component('ippro-datatable', IpproDatatable);
        app.component('ippro-autocomplete', IpproAutocomplete);
        //app.component('ippro-map', IpproMap);
    }
} as Plugin;

export {
    IpproIconText,
    IpproDatatable,
    IpproAutocomplete,
    //IpproMap,
};
