<vl-form-grid mod-stacked v-if="unaZone">
    <vl-column v-if="!dataLoading && moveZoneOngoing">
        <vl-alert icon="alert-circle" title="LET OP: de zone is aan het verplaatsen!" mod-warning>
            <p>De zone wordt nog steeds verplaatst. Vernieuw de pagina om de laatste updates te bekijken.</p>
        </vl-alert>
    </vl-column>
    <slot name="breadcrumb"></slot>
    <vl-column width="10" width-xs="12">
        <vl-title tag-name="h1">{{ unaZone.zoneName }}<small v-if="unaZone.unaName"> - {{ unaZone.unaName
                }}</small></vl-title>
    </vl-column>
    <template v-if="dataLoading">
        <div class="vl-u-spacer-top">
            <vl-loader message="De gegevens worden geladen. Even geduld, a.u.b." />
        </div>
    </template>
    <template v-else>
        <vl-column width="2" width-xs="12" v-if="showMoveZone">
            <vl-action-group
                class="vl-action-group--align-right vl-action-group--align-left--xs vl-action-group--primary">
                <vl-button v-vl-modal-toggle="'una-zone-move-modal'" :mod-loading="isMovingZone" mod-large>Verplaats
                    zone</vl-button>
                <kl-una-zone-move @move="onMoveZone" :zone-id="unaZone.id"
                    :source-klip-organisation-id="unaZone.klipOrganisationId"></kl-una-zone-move>
            </vl-action-group>
        </vl-column>
        <vl-column width="12" width-xs="12" v-if="unaZone.isArchived">
            <vl-title tag-name="h3">Gearchiveerd</vl-title>
        </vl-column>
        <vl-column width-s="12" width-m="8" width-l="8">
            <kl-description-list>
                <kl-description-list-row label="GUID:" v-if="unaZone.id">{{ unaZone.id }}</kl-description-list-row>
                <kl-description-list-row label="Stuur planaanvragen naar:" v-if="unaZone.mapRequestEmail">{{
                    unaZone.mapRequestEmail }}</kl-description-list-row>
                <kl-description-list-row label="Namespace:" v-if="unaZone.namespace">{{ unaZone.namespace
                    }}</kl-description-list-row>
            </kl-description-list>
        </vl-column>
        <vl-column width-s="12" width-m="8" width-l="8">
            <span class="kl-description-list__term">U ontvangt volgende meldingen via e-mail:</span>
        </vl-column>
        <vl-column width-s="12" width-m="8" width-l="8">
            <vl-checkbox v-model="unaMailSettings.confirmation" mod-disabled>
                <span class="checkbox-label">Nieuwe planaanvraag te bevestigen</span>
                <vl-link :href="unaMailSettings.confirmationExampleUri" v-if="unaMailSettings.confirmationExampleUri"
                    icon="file" target="_blank" mod-icon-before>voorbeeld</vl-link>
            </vl-checkbox>
        </vl-column>
        <vl-column width-s="12" width-m="8" width-l="8">
            <vl-checkbox v-model="unaMailSettings.reminder1DayToRespond" mod-disabled>
                <span class="checkbox-label">Herinnering: nog 1 dag om te antwoorden</span>
                <vl-link :href="unaMailSettings.reminder1DayToRespondExampleUri"
                    v-if="unaMailSettings.reminder1DayToRespondExampleUri" icon="file" target="_blank"
                    mod-icon-before>voorbeeld</vl-link>
            </vl-checkbox>
        </vl-column>
        <vl-column width-s="12" width-m="8" width-l="8">
            <vl-checkbox v-model="unaMailSettings.uploadImklSuccess" mod-disabled>
                <span class="checkbox-label">Opladen IMKL: geslaagd</span>
                <vl-link :href="unaMailSettings.uploadImklSuccessExampleUri"
                    v-if="unaMailSettings.uploadImklSuccessExampleUri" icon="file" target="_blank"
                    mod-icon-before>voorbeeld</vl-link>
            </vl-checkbox>
        </vl-column>
        <vl-column width-s="12" width-m="8" width-l="8">
            <vl-checkbox v-model="unaMailSettings.uploadImklFailed" mod-disabled>
                <span class="checkbox-label">Opladen IMKL: gefaald</span>
                <vl-link :href="unaMailSettings.uploadImklFailedExampleUri"
                    v-if="unaMailSettings.uploadImklFailedExampleUri" icon="file" target="_blank"
                    mod-icon-before>voorbeeld</vl-link>
            </vl-checkbox>
        </vl-column>
        <vl-column width-s="12" width-m="8" width-l="8">
            <vl-checkbox v-model="unaMailSettings.respondedNotInvolved" mod-disabled>
                <span class="checkbox-label">Bevestiging antwoord: geen kabels en leidingen</span>
                <vl-link :href="unaMailSettings.respondedNotInvolvedExampleUri"
                    v-if="unaMailSettings.respondedNotInvolvedExampleUri" icon="file" target="_blank"
                    mod-icon-before>voorbeeld</vl-link>
            </vl-checkbox>
        </vl-column>
    </template>
</vl-form-grid>