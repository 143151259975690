import {computed, defineComponent} from 'vue';
import {canCurrentUserAccessRoute} from '@/router/route-utils';
import {RouteLocation} from 'vue-router';

export default defineComponent({
    name: 'KlRouterLink',
    props: {
        protect: {
            type: Boolean,
            default: false,
        },
        to: {
            type: Object as () => RouteLocation,
            required: true,
        },
        params: {
            type: Object,
            default: undefined,
        },
    },
    setup(props, { emit }) {

        const location = computed((): any => {
            const result = {
                path: props.to.path,
                name: props.to.name,
                hash: props.to.hash,
                params: props.params,
            };

            // TEMP: VUE3-MIGRATION-FIX

            // TODO: refactor > Quick global fix
            // Vue3 migration: { archived: false } would remove the archived param and result in an internal vue-router error, complaining about missing param
            //if (!result.params?.archived && (result.params?.archived !== undefined)) {
            if ((result.params?.archived !== undefined) && (typeof result.params?.archived !== 'string')) {
                result.params.archived = result.params?.archived + '';
                console.log('kl-router-link: location fixed', result);
            }

            return result;
        });

        const canShow = computed(() => {
            if (!props.protect) {
                return true;
            }
            return canCurrentUserAccessRoute(props.to);
        });

        return { location, canShow }
    }
})
