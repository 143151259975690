import {onBeforeMount, onMounted, onUnmounted} from 'vue';

// ref: https://develop--642e92e0cda6c627a0601f07.chromatic.com/iframe.html?viewMode=docs&id=components-vl-modal--docs#model---events

export interface IKlModalEventsUtilOptions {
    onOpened?: () => void;
    onClosed?: () => void;
    onModalToggle?: () => void;
}

export function useKlModalEventsUtil(modalId: string, options?: IKlModalEventsUtilOptions) {

    const _onOpened = (evt: any) => {
        if (evt.detail.modalId === modalId) {
            options?.onOpened();
        }
    }

    const _onClosed = (evt: any) => {
        if (evt.detail.modalId === modalId) {
            options?.onClosed();
        }
    }

    const _onModalToggle = (evt: any) => {
        if (evt.detail.modalId === modalId) {
            options?.onModalToggle();
        }
    }

    const triggerModalToggle = () => {
        document.dispatchEvent(new CustomEvent('modal-toggle', {
            detail: {
                modalId: modalId
            }
        }));
    }

    onBeforeMount(() => {
        if (options?.onOpened) {
            document.addEventListener('opened', _onOpened);
        }
        if (options?.onClosed) {
            document.addEventListener('closed', _onClosed);
        }
        if (options?.onModalToggle) {
            document.addEventListener('modal-toggle', _onModalToggle);
        }
    });

    onUnmounted(() => {
        if (options?.onOpened) {
            document.removeEventListener('opened', _onOpened);
        }
        if (options?.onClosed) {
            document.removeEventListener('closed', _onClosed);
        }
        if (options?.onModalToggle) {
            document.removeEventListener('modal-toggle', _onModalToggle);
        }
    });

    return {
        triggerModalToggle,
    };
}
