import {RouteLocation, RouteRecordRaw} from 'vue-router';
import {useUserStore} from '@/app/shared/state/UserDataModule';

export function safeRouteParamToString(param: string | string[]): string {
    if (Array.isArray(param)) {
        console.warn('safeRouteParamToString received an array', param);
        return param[0];
    }
    return param as string;
}

export function safeRouteNameToString(name: string | symbol): string {
    if (typeof name === 'symbol') {
        console.warn('safeRouteNameToString received a symbol', name);
        return (name as symbol).description;
    }
    return name as string;
}

export function canCurrentUserAccessRoute(route: RouteRecordRaw | RouteLocation) {
    if (route.meta && route.meta.authorize && route.meta.authorize.roles) {
        return useUserStore().hasRole(route.meta.authorize.roles);
    }

    return true;
}
