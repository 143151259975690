import {NavigationHookAfter, RouteLocation, RouteLocationNormalized} from 'vue-router';
import {useErrorStore} from '@/stores/error-store';
import {usePaymentStatusStore_toBeRemoved} from '@/app/shared/state/PaymentStatusModule';

export function createCleanupHook(): NavigationHookAfter {

    return async function (to: RouteLocation, from: RouteLocation) {

        // TODO: refactor!!!

        useErrorStore().resetAll();

        if ((from.name !== 'payment' && from.name !== 'checkout' && from.name !== 'mrimaprequestcreate') && usePaymentStatusStore_toBeRemoved().isPaymentMessagesVisible) {
            usePaymentStatusStore_toBeRemoved().clearPaymentMessages();
        }
        // The prepaid payment message needs to be cleared when coming from anywhere but the payment or prepaid view
        // But it doesn't need to be cleared when going from prepaid to checkoutprepaid
        if (((from.name !== 'payment' && from.name !== 'prepaid') || (from.name === 'prepaid' && to.name !== 'checkoutprepaid')) && (usePaymentStatusStore_toBeRemoved().hasPrepaidAmount || usePaymentStatusStore_toBeRemoved().hasPrepaidPaymentMessageData)) {
            if (from.name !== 'checkoutprepaid' && to.name !== 'prepaid') {
                usePaymentStatusStore_toBeRemoved().clearPrepaidAmount();
            }
            usePaymentStatusStore_toBeRemoved().clearPrepaidPaymentMessage();
        }
    }
}
