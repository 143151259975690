<div>
    <kl-search-maprequests
        :search-fields="data.searchFields"
        :search-options="data.searchOptions"
        :grouped-search-options="data.groupedSearchOptions"
        :default-search-option="defaultSearchOption"
        :default-filter="defaultFilter"
        ref="searchMaprequests"
        @search="search"
        v-if="data">
        <template v-slot:zones v-if="data.zones && data.zones.length > 1">
            <vl-form-message-label for="zones-replied" v-vl-visually-hidden>Zones</vl-form-message-label>
            <vl-select name="zones-replied" v-model="selectedZone" mod-block>
                <option value="all">Alle zones</option>
                <option :value="option.key" v-for="option in data.zones">
                    {{ option.label }}
                </option>
            </vl-select>
        </template>
    </kl-search-maprequests>
    <vl-u-spacer mod-small />
    <ippro-datatable v-if="mapRequests" :meta="mapRequestsMeta" :dataset="mapRequests" :columns="mapRequestsColumns" mod-pagination-on-top
        :fetching="mapRequestsFetching" @pager-clicked="pagerClicked" @column-clicked="columnClicked" @row-clicked="rowClicked" ref="mapRequestDatatable" mod-clickable-rows mod-zebra>
        <template v-slot:noresults>
            <vl-u-spacer mod-large />
            <div v-vl-align:center>
                <template v-if="searchUsed">
                    <p>Uw zoekopdracht heeft geen resultaten opgeleverd.</p>
                    <vl-button @click="clearSearch" class="vl-button--link">Zoekvelden wissen</vl-button>
                    <vl-u-spacer mod-xsmall />
                </template>
                <p v-else-if="isArchived">U heeft nog geen beantwoorde planaanvragen.</p>
                <p v-else>U heeft geen recent beantwoorde planaanvragen.<br /><a href="#" @click.prevent="switchToArchived">Ga naar uw gearchiveerde planaanvragen</a>.</p>
            </div>
        </template>
        <template v-slot:actions>
            <vl-button :tag-name="downloadButtonTagName" @click="download"
                :key="'download-' + downloadFileUri" class="vl-button--link" icon="download-harddisk"
                mod-icon-before :mod-loading="downloadFileUriLoading" :mod-disabled="downloadFileUriLoading"
                v-if="showDownloadLink">Download</vl-button>
        </template>
    </ippro-datatable>
</div>
