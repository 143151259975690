import {defineStore} from 'pinia';
import {useRoute, useRouter} from '@/router/router-vue3';
import {klipIndex} from '@/router/klip-index';
import {computed, ref, watch} from 'vue';
import {useCodeListStore} from '@/app/shared/state/CodeListModule';
import {RouteLocation, RouteRecordRaw} from 'vue-router';

export interface IBreadCrumb {
    label: string;
    route: RouteLocation;
}

export const useMetaStore = defineStore('meta-store', () => {

    const router = useRouter();
    const route = useRoute();

    // strange display problem when we start with an empty array ([]). ref: bug #180590
    const breadcrumbs = ref<IBreadCrumb[]>([{
        label: '',
        route: null,
    }]);

    const isBeta = computed(() => {
        return useCodeListStore().isBeta;
    });

    const setPageTitle = async (title: string, parentTitle?: string)=> {
        if (!route) {
            console.warn('useMetaStore.setPageTitle: no route');
            return;
        }

        if (title && route && route.meta && route.meta.breadcrumb && route.meta.breadcrumb.title) {
            const parent = route.meta.breadcrumb.parent;
            route.meta.breadcrumb.title = title.replace(/'/g, '`');

            if (parentTitle && parent && parent.meta.breadcrumb && parent.meta.breadcrumb.title) {
                parent.meta.breadcrumb.title = parentTitle.replace(/'/g, '`');
            }

            // fix: sometimes watch(() => route.meta.breadcrumb) doesn't register the change
            // reactivity problems with useRoute() ??
            _breadcrumbsChanged();
        }
    }

    const setParent = (parentRoute: RouteRecordRaw) => {
        if (route) {
            if (route && route.meta && route.meta.breadcrumb && route.meta.breadcrumb.title) {
                route.meta.breadcrumb.parent = parentRoute;

                // fix: sometimes watch(() => route.meta.breadcrumb) doesn't register the change
                // reactivity problems with useRoute() ??
                _breadcrumbsChanged();
            }
        }
    }

    const _breadcrumbsChanged = () => {
        if (route.meta && route.meta.breadcrumb) {
            const newBreadcrumbs: IBreadCrumb[] = [];

            if (route.meta.breadcrumb.title || route.meta.title) {
                newBreadcrumbs.push({
                    route: null, label: route.meta.breadcrumb.title ||
                        route.meta.title,
                });
            }

            let parent = route.meta.breadcrumb.parent;
            let breakClause = false;
            while (parent && !breakClause) {
                const parentRoute = router.resolve(parent);
                if (
                    parentRoute.href !== '/'
                    && parentRoute.meta
                    && parentRoute.meta.breadcrumb
                    && (parentRoute.meta.breadcrumb.title || parentRoute.meta.title)
                ) {
                    newBreadcrumbs.push({
                        route: parentRoute,
                        label: parentRoute.meta.breadcrumb.title || parentRoute.meta.title,
                    });
                    parent = parentRoute.meta.breadcrumb.parent;
                } else {
                    breakClause = true;
                    const klipTitle = isBeta.value ? 'KLIP BETA' : 'KLIP';
                    newBreadcrumbs.push({
                        route: router.resolve(klipIndex),
                        label: klipTitle
                    });
                }
            }

            const pageTitle = newBreadcrumbs.map((b) => b.label).join(' | ');

            // is there a better way to handle document changes?
            document.title = `${pageTitle} - Kabel- en Leidinginformatieplatform | Digitaal Vlaanderen`;

            breadcrumbs.value = newBreadcrumbs.reverse();
        }
    }

    watch(
        () => route.meta.breadcrumb,
        _breadcrumbsChanged,
        { immediate: true, deep: true })

    return {
        setPageTitle,
        setParent,

        breadcrumbs,
    }
});
