<form @submit.prevent="search" class="kl-search-maprequests vl-form" v-kl-u-background-alt v-kl-u-box-padding>
    <vl-form-grid mod-stacked>
        <!-- basic search -->
        <vl-column width-l="8">
            <div v-kl-u-background-white v-kl-u-box-padding class="kl-search-maprequests__keyword-search">
                <vl-form-grid>
                    <vl-column :width="keyWordSearchWidth" width-s="12" v-for="field in searchFields" :key="field.key">
                        <vl-radio :id="uniqueFieldId(field.key)" :value="field.key" v-model="searchField"
                                  @input="searchFieldChanged">{{ field.label }}
                        </vl-radio>
                    </vl-column>
                </vl-form-grid>
                <div class="vl-u-spacer vl-u-spacer--small"></div>
                <vl-input-field name="keyword" placeholder="Zoek trefwoord" autocomplete="off" v-model="keyword"
                                mod-block/>
            </div>
        </vl-column>
        <vl-column width-l="4">
            <div v-kl-u-background-white v-kl-u-box-padding>
                <vl-form-message-label>Aanvraagdatum</vl-form-message-label>
                <vl-form-grid mod-stacked>
                    <vl-column width="6">
                        <label for="date-from">van</label>
                        <kl-datepicker visual-format="d/m/Y" v-model="dateFrom" :id="uniqueFieldId('date-from')"
                                       :default-date="selectedFromDate" :max-date="dateFromMaxDate"/>
                    </vl-column>
                    <vl-column width="6">
                        <label for="date-to">tot</label>
                        <kl-datepicker visual-format="d/m/Y" v-model="dateTo" :id="uniqueFieldId('date-to')"
                                       :default-date="selectedToDate" :min-date="dateToMinDate"/>
                    </vl-column>
                </vl-form-grid>
            </div>
        </vl-column>
        <!-- ./basic search -->

        <!-- extra-options -->
        <vl-column width-l="4" v-if="searchOptions">
            <vl-form-message-label for="search-options" v-vl-visually-hidden>Aanvrager</vl-form-message-label>
            <vl-select :id="uniqueFieldId('search-options')" name="search-options" v-model="searchOption" mod-block>
                <option :value="option.value" v-for="option in searchOptions">
                    {{ option.label }}
                </option>
                <template v-if="groupedSearchOptions" v-for="groupedOptions in groupedSearchOptions">
                    <optgroup :label="groupedOptions.groupName">
                        <option :value="option.key" v-for="option in groupedOptions.codeListValues">
                            <span v-html="setDepth(option.order, option.label)"></span>
                        </option>
                    </optgroup>
                </template>
            </vl-select>
        </vl-column>
        <vl-column width-l="4" v-if="!modSimple">
            <kl-locationpicker ref="locationpicker" v-model="location"/>
        </vl-column>
        <vl-column width-l="4" v-if="!modSimple">
            <vl-form-message-label for="search-archive" v-vl-visually-hidden>Actief of archief</vl-form-message-label>
            <vl-select :id="uniqueFieldId('search-archive')" name="search-archive" v-model="searchArchive"
                       mod-no-placeholder mod-block>
                <option :value="option.key" v-for="option in searchArchiveOptions">
                    {{ option.label }}
                </option>
            </vl-select>
        </vl-column>
        <!-- ./extra-options -->

        <!-- tags -->
        <vl-column width-l="1" v-if="hasTags">
            <div class="kl-search-maprequests__tags-checkbox">
                <vl-checkbox v-model="tagsChecked" :mod-disabled="!tagsCheckboxEnabled">Tags:</vl-checkbox>
            </div>
        </vl-column>
        <vl-column width-l="11" v-if="hasTags" @click.native="clickTags">
            <label for="search-tags" v-vl-visually-hidden>Tags</label>
            <vl-multiselect
                    :id="uniqueFieldId('search-tags')"
                    name="searchTags"
                    v-model="searchTags"
                    placeholder="Tags toevoegen"
                    select-label="Selecteer tag"
                    tag-placeholder="Voeg nieuwe tag toe"
                    @update:value="tagsChanged"
                    :disabled="!tagsActive"
                    :options="tags"
                    :multiple="true">
                <template v-slot:noResult>
                    <span>Geen tags gevonden...</span>
                </template>
                <template v-slot:noOptions>
                    <span>Geen tags beschikbaar!</span>
                </template>
            </vl-multiselect>
        </vl-column>
        <!-- ./tags -->

        <!-- operations -->
        <vl-column width-l="4" v-if="$slots.operations">
            <slot name="operations"></slot>
        </vl-column>
        <!-- ./operations -->

        <!-- zones -->
        <vl-column width-l="4" v-if="$slots.zones">
            <slot name="zones"></slot>
        </vl-column>
        <!-- ./zones -->

        <!-- move -->
        <vl-column width-l="6" v-if="$slots.movemaprequest">
            <slot name="movemaprequest"></slot>
        </vl-column>
        <!-- ./move -->

        <vl-column width-l="12" v-vl-visibility:hide.s />

        <!-- form-actions -->
        <vl-column v-vl-visually-hidden>
            <vl-action-group>
                <vl-button type="submit" v-vl-visually-hidden>Zoeken</vl-button>
            </vl-action-group>
        </vl-column>
        <!-- ./form-actions -->
    </vl-form-grid>
</form>
