<vl-form-grid mod-stacked mod-v-center>
    <vl-column>
        <kl-draw-zone-map
            v-if="!showEmptyState"

            :input-geometries="drawZones"
            ref="pdaDrawZonesMap"
            rules=""
            :show-map="true"
            :mod-enable-multi-zones="true"
            :mod-show-km-hm-toggle="false"

            :for-klb-zones="true"
            :can-add-klb-zone="false"
            :can-add-klim-zone="false"

            :include-imkl="false"
            :mod-maprequest-zone="false"

            :mod-disabled="fetching"
            loadingMessage=""
        />
        <div v-vl-align:center v-if="showEmptyState">
            <p>Als openbaar domein beheerder ziet u aanwezige leidingbeheerders en planaanvragen op uw
                grondgebied.
            </p>
            <p>Maak één of meer zones aan van uw grondgebied om dit te kunnen bekijken. </p>
        </div>
    </vl-column>
</vl-form-grid>
