import {computed, defineComponent} from "vue";
import {useRoute} from '@/router/router-vue3';
import {safeRouteParamToString} from '@/router/route-utils';

export default defineComponent({
    props: {},
    setup(_props) {
        const route = useRoute();
        const userId = computed((): string => safeRouteParamToString(route.params.userId));
        const organisationId = computed((): string => safeRouteParamToString(route.params.organisationId));
        return {
            userId,
            organisationId
        };
    },
});
