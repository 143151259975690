<vl-form-grid mod-stacked>
    <vl-column width="4" width-m="6" width-s="8">
        <kl-field-validation-wrapper :field="streetField" #default="{hasErrors}" mod-required>
<!--            <kl-autocomplete :data="streetData" :fetching="streetDataFetching" name="straat" v-model="street" @input="onStreetInputChange" @select-option="onStreetSelect" :mod-error="hasErrors" mod-block />-->
            <kl-autocomplete2 :data="streetData" :fetching="streetDataFetching" name="straat" :model-value="street" @change="onStreetInputChange" @select="onStreetSelect" :mod-error="hasErrors" mod-block />
        </kl-field-validation-wrapper>
    </vl-column>
    <vl-column width="1" width-m="2">
        <kl-field-validation-wrapper :field="streetNumberField" #default="{hasErrors}" mod-required mod-no-wrap-error>
<!--            <kl-autocomplete :data="streetNumberData" :fetching="streetNumberDataFetching" name="nummer" v-model="streetNumber" @input="onStreetNumberInputChange" @select-option="onStreetNumberSelect" :mod-error="hasErrors" mod-block />-->
<!--            <kl-autocomplete2 :data="streetNumberData" :fetching="streetNumberDataFetching" name="nummer" :model-value="streetNumber" @change="onStreetNumberInputChange" @select="onStreetNumberSelect" :mod-error="hasErrors" mod-block />-->
            <vl-input-field ref="streetNumberRef" name="nummer" id="nummer" v-model="streetNumber" :mod-error="hasErrors" mod-block />
        </kl-field-validation-wrapper>
    </vl-column>
    <vl-column width="1" width-m="2">
        <kl-field-validation-wrapper :field="boxField" #default="{hasErrors}">
            <vl-input-field name="bus" id="box" v-model="box" :mod-error="hasErrors" mod-block />
        </kl-field-validation-wrapper>
    </vl-column>
    <vl-column width-l="6" width-m="2" width-s="12" v-vl-visibility:hide.s></vl-column>
    <vl-column width="1" width-m="2" width-s="3">
        <kl-field-validation-wrapper :field="zipCodeField" #default="{hasErrors}" mod-required>
            <vl-input-field name="postcode" id="zip" v-model="zipCode" :mod-error="hasErrors" mod-block />
        </kl-field-validation-wrapper>
    </vl-column>
    <vl-column width="3" width-m="4" width-s="9">
        <kl-field-validation-wrapper :field="cityField" #default="{hasErrors}" mod-required>
            <vl-input-field name="gemeente" id="city" v-model="city" :mod-error="hasErrors" mod-block />
        </kl-field-validation-wrapper>
    </vl-column>
    <vl-column width="3" width-m="4" width-s="8">
        <kl-field-validation-wrapper :field="countryCodeField" #default="{hasErrors}" mod-required>
            <vl-select name="land" id="country-code" v-model="countryCode" :mod-error="hasErrors" mod-block>
                <option :value="key" v-for="(value, key) in countries">
                    {{ value }}
                </option>
            </vl-select>
        </kl-field-validation-wrapper>
    </vl-column>
</vl-form-grid>
