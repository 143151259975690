<!--<vl-breadcrumb mod-separators>-->
<!--    <vl-breadcrumb-item :label="item.label" :to="to(item)"-->
<!--        :tag-name="routeName(item) ? 'router-link' : 'span'" :mod-active="!routeName(item)"-->
<!--        v-for="(item, index) in breadcrumbs" :key="index">-->
<!--        <span>{{ item.label }}</span>-->
<!--    </vl-breadcrumb-item>-->
<!--</vl-breadcrumb>-->

<!--<vl-breadcrumb :breadcrumbs="breadcrumbs" :mod-last-item-is-active="false">-->
<!--</vl-breadcrumb>-->

<div>
    <vl-breadcrumb-item :label="item.label" :to="to(item)"
        :tag-name="routeName(item) ? 'router-link' : 'span'" :mod-active="!routeName(item)"
        v-for="(item, index) in breadcrumbs" :key="index">
        <span>{{ item.label }}</span>
    </vl-breadcrumb-item>
</div>
