import {useUserStore} from '@/app/shared/state/UserDataModule';
import {EUserRole} from '@/app/shared/state/user-role';
import { OrganisationDataTable, OrganisationSetting } from '@/api/klip-api.proxy';
import { EditSubOrganisation, NewSubOrganisation } from './kl-organisation-routes';
import { escape } from 'html-escaper';
import {useSettingsStore} from '@/app/settings/settings-store';
import {computed, defineComponent, onMounted, ref} from 'vue';
import {useKlipApiProxy} from '@/plugins/proxy-client';
import {useRouter} from '@/router/router-vue3';


export default defineComponent({
    name: 'KlOrganisationSettings',
    props: {},
    setup(props) {

        const router = useRouter();

        const organisationSettings = ref<OrganisationSetting>(null);
        const organisationsDataTable = ref<OrganisationDataTable[]>(null);
        const columns = ref<IColumn[]>();
        const isLoading = ref<boolean>(true);

        //@Action(SettingsStore.store.actions.resetNamespaceZones, { namespace: SettingsStore.store.namespace })
        const _resetNamespaceZones = () => useSettingsStore().resetNamespaceZones();


        const routeNewSubOrganisation = computed(() => {
            return NewSubOrganisation;
        });

        const routeEditSubOrganisation = computed(() => {
            return EditSubOrganisation;
        });

        const loaded = computed((): boolean => {
            return !!organisationSettings.value && !!organisationsDataTable.value;
        });

        const meta = computed(() => {
            return {
                totalRows: organisationsDataTable.value.length,
                resultsPerPage: organisationsDataTable.value.length,
                currentPage: 0,
            };
        });

        const hasUnaRole = computed(() => {
            return useUserStore().hasRole([EUserRole.una_manager, EUserRole.una_operator, EUserRole.una_reader, EUserRole.klim_admin]);
        });

        const hasOrganisationAdminRole = computed(() => {
            return useUserStore().hasRole([EUserRole.org_manager]);
        });

        const _parseCell = (row: IRow, column: IColumn) => {
            const routeParams = {
                organisationId: row.id
            };
            const cellValue = escape(row[column.key]);
            if (column.key === 'name') {
                return { template: `<kl-router-link :to='${JSON.stringify(routeEditSubOrganisation.value)}' style='margin-left:${+row.depth - 1}em' :params='${JSON.stringify(routeParams)}'>${cellValue}</kl-router-link>` };
            }
            if (column.key === 'numberOfUtilityNetworkAuthorityZones') {
                return {
                    template: `
                    <kl-router-link :to='${JSON.stringify(routeEditSubOrganisation.value)}' class="align-right" :params='${JSON.stringify(routeParams)}'>
                        ${row.hasKlimZone ? `<img src="/img/logo-klim.svg" class="klim-pictogram" alt="KLIM pictogram" />` : ''}
                        <span>${cellValue}</span>
                    </kl-router-link>`,
                };
            }
            return { template: `<kl-router-link :to='${JSON.stringify(routeEditSubOrganisation.value)}' :params='${JSON.stringify(routeParams)}'>${cellValue}</kl-router-link>` };
        }

        onMounted(async () => {
            await _resetNamespaceZones();

            columns.value = [];
            columns.value.push({
                key: "name",
                type: "String",
                label: "Organisatie",
                sortable: false,
                width: 7,
                isVisible: true,
                parser: _parseCell
            });
            if (hasUnaRole.value) {
                columns.value.push({
                    key: "utilityNetworkAuthorityName",
                    type: "String",
                    label: "Naam als leidingbeheerder in KLIP",
                    sortable: false,
                    width: 7,
                    isVisible: true,
                    parser: _parseCell
                });
                columns.value.push({
                    key: "numberOfUtilityNetworkAuthorityZones",
                    type: "String",
                    label: "Aantal zones",
                    sortable: false,
                    width: 2,
                    isVisible: true,
                    parser: _parseCell
                });
            }
            _loadOrganisationSettings();
        });

        const _loadOrganisationSettings = () => {
            _redirectIfUnaAndOnlyOneOrganisation();
            useKlipApiProxy().organisation_LoadSettingsForOrganisation().then((organisationSettingsData) => {
                organisationSettings.value = organisationSettingsData.result.organisationSetting;
                organisationsDataTable.value = organisationSettingsData.result.organisationsOverviewTable;
                isLoading.value = false;
            });
        }

        const _redirectIfUnaAndOnlyOneOrganisation = () => {
            if (hasOrganisationAdminRole.value) {
                return;
            }

            if (useUserStore().user.subOrganisations.length === 1)
            {
                router.replace({
                    name: routeEditSubOrganisation.value.name,
                    params: { organisationId: useUserStore().user.klipOrganisationApiId }
                });
            }
        }

        return {
            organisationSettings,
            organisationsDataTable,
            columns,
            isLoading,

            routeNewSubOrganisation,
            routeEditSubOrganisation,
            loaded,
            meta,
            hasUnaRole,
            hasOrganisationAdminRole,
        }
    }
})
