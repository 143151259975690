import {defineComponent, ref} from 'vue';

export default defineComponent({
    name: 'VlFormValidationObserver',
    props: {
    },
    setup(props) {

        // TODO
        // handleSubmit, invalid, pristine
        // validate

        return {
            validate: () => { console.log('VlFormValidationObserver.validate called'); return true; },
            handleSubmit: () => { console.log('VlFormValidationObserver.handleSubmit called'); },
            invalid: false,
            pristine: true,
        };
    }
});
