<div>
    <kl-draw-zone-map
        :input-geometries="drawZones"

        rules=""
        :show-map="true"
        :mod-enable-multi-zones="true"
        :mod-show-km-hm-toggle="false"

        :for-geometry="true"
        :show-remove-geometry="showRemoveButton"
        @remove-geometry="onRemove"

        :include-imkl="false"
        :mod-maprequest-zone="false"
    />
</div>
