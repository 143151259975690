import { IUnaZoneEditView } from '@/api/klip-api.proxy';
import { EditSubOrganisation } from '../../../../kl-organisation-routes';
import {computed, defineComponent, onBeforeMount, onMounted, ref} from 'vue';
import {useRoute, useRouter} from '@/router/router-vue3';
import {useKlipApiProxy} from '@/plugins/proxy-client';
import {useMetaStore} from '@/stores/meta-store';

export default defineComponent({
    name: 'KlUnaZone',
    props: {
        zoneId: { type: String, default: '' },
        organisationId: { type: String, default: '' },
    },
    setup(props) {

        const route = useRoute();
        const router = useRouter();

        const zone = ref<IUnaZoneEditView>(null);
        const viewModelLoading = ref<boolean>(false);

        const mailSettings = ref({
            confirmation: false,
            confirmationExampleUri: '',
            reminder1DayToRespond: false,
            reminder1DayToRespondExampleUri: '',
            uploadImklSuccess: false,
            uploadImklSuccessExampleUri: '',
            uploadImklFailed: false,
            uploadImklFailedExampleUri: '',
            respondedNotInvolved: false,
            respondedNotInvolvedExampleUri: ''
        });

        const routeEditOrganinsation = computed(() => {
            return EditSubOrganisation;
        });

        onBeforeMount(() => {
            useMetaStore().setPageTitle('Zone detail');
        });

        onMounted(() => {
            viewModelLoading.value = true;
            if (route) {
                useKlipApiProxy().unaSettings_GetUnaZoneEditView(props.zoneId, props.organisationId)
                    .then((response) => {
                        if (!response.result || response.result.isArchived) {
                            router.replace({
                                name: routeEditOrganinsation.value.name,
                                params: {
                                    organisationId: props.organisationId,
                                }
                            });
                        } else {
                            zone.value = response.result;
                            mailSettings.value = {
                                confirmation: response.result.confirmation,
                                confirmationExampleUri: response.result.confirmationExampleUri,
                                reminder1DayToRespond: response.result.reminder1DayToRespond,
                                reminder1DayToRespondExampleUri: response.result.reminder1DayToRespondExampleUri,
                                uploadImklFailed: response.result.uploadImklFailed,
                                uploadImklFailedExampleUri: response.result.uploadImklFailedExampleUri,
                                uploadImklSuccess: response.result.uploadImklSuccess,
                                uploadImklSuccessExampleUri: response.result.uploadImklSuccessExampleUri,
                                respondedNotInvolved: response.result.respondedNotInvolved,
                                respondedNotInvolvedExampleUri: response.result.respondedNotInvolvedExampleUri
                            };
                            useMetaStore().setPageTitle(zone.value.zoneName);
                        }
                    })
                    .finally(() => {
                        viewModelLoading.value = false;
                    });
            }
        });

        return {
            zone,
            viewModelLoading,
            mailSettings,
            routeEditOrganinsation,
        }
    }
})
