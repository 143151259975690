﻿import { defineComponent, inject, ref, watch } from 'vue';
import * as KlipApi from '@/api/klip-api.proxy';
import { RegistrationModel } from "@/app/public/registrationModel";

export default defineComponent({
    props: {
        registrationModel: {
            type: Object as () => RegistrationModel,
            required: true
        }
    },
    setup(props) {
        const klipApiProxy = inject<KlipApi.IKlipApiProxy>('klipApiProxy');
        const isKnownInKlip = ref<boolean>(false);
        const isLegalRepresentative = ref<boolean>(false);
        const viewModelLoading = ref<boolean>(true);

        const mrrRegistrationLink = ref<string>('');
        const mriRegistrationLink = ref<string>('');
        const unaAdminRegistrationLink = ref<string>('');
        const unaOperatorRegistrationLink = ref<string>('');
        const unaReaderRegistrationLink = ref<string>('');
        const odbRegistrationLink = ref<string>('');

        const linkGebruikersBeheerVlaanderen = ref<string>(import.meta.env.VITE_LINKS_GEBRUIKERSBEHEERVLAANDEREN);

        const hasRequestedRole = (role: string) => {
            return props.registrationModel.roles.includes(role);
        }

        const registrationModelChanged = async (model: RegistrationModel) => {
            const linkResult = await klipApiProxy.acmIdmAuth_AcmIdmRegistrationLinks(model.organisationCode);
            viewModelLoading.value = false;
            isLegalRepresentative.value = model.isLegalRepresentative;

            if (!linkResult || !linkResult.isSuccessStatusCode || !linkResult.result) {
                return;
            }

            mrrRegistrationLink.value = linkResult.result.klipRaadplagerUrl;
            mriRegistrationLink.value = linkResult.result.klipPlanaanvraagUrl;
            unaAdminRegistrationLink.value = linkResult.result.klipKlbBeheerderUrl;
            unaOperatorRegistrationLink.value = linkResult.result.klipKlbOperatorUrl;
            unaReaderRegistrationLink.value = linkResult.result.klipKlbRaadplegerUrl;
            odbRegistrationLink.value = linkResult.result.klipOdbUrl;
            isKnownInKlip.value = true;
        }

        watch(props.registrationModel, registrationModelChanged, { immediate: true, deep: true });

        return {
            viewModelLoading,
            isKnownInKlip,
            linkGebruikersBeheerVlaanderen,
            isLegalRepresentative,
            mrrRegistrationLink,
            mriRegistrationLink,
            unaAdminRegistrationLink,
            unaOperatorRegistrationLink,
            unaReaderRegistrationLink,
            odbRegistrationLink,
            hasRequestedRole
        }
    }
});
