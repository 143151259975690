<div>
    <template v-if="processCounterLoading">
        <span class="vl-loader" aria-busy="true" /> Loading {{processCounterText}}
    </template>
    <template v-else-if="processCounterLoadingFailed">
        <vl-icon icon="alert-triangle" mod-large />
        loading failed ({{processCounterText}})
    </template>
    <template v-else>
        <kl-router-link protect :to="processCounterRoute" :class="processCounterCssClass(processCounter)">
            <vl-icon :icon="processCounterIcon(processCounter)" mod-large />
            <span v-if="processCounter > 0">{{processCounter}}&nbsp;</span>{{processCounterText}}
        </kl-router-link>
    </template>
</div>
