<script setup lang="ts">
import {onMounted, ref} from 'vue';

const renderMyModal2 = ref(false);

onMounted(() => {
    setTimeout(() => {
        console.log('renderMyModal2 > backdrop of my-modal1 is removed');
        renderMyModal2.value = true;
    }
    , 5000);
});

</script>

<template>
    <vl-modal id="my-modal1" open/>
    <vl-modal id="my-modal2" v-if="renderMyModal2"/>
</template>

<style scoped lang="scss">

</style>
