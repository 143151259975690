<vl-form-grid>
    <vl-column width-s="12" width-m="8" width-l="12">
        <vl-title :tag-name="titleTag" v-if="title">{{ title }}</vl-title>
        <kl-contact-card
            :title="contactCardTitle"
            :subtitle="contactCardSubtitle"
            :address="addressToContactCard(contact.address)"
            :phone="contact.telephone"
            :email="contact.email"
            mod-email-clickable>
            <template v-if="modAdmin" v-slot:operations>
                <vl-title tag-name="span" tag-style="h6" class="kl-contact-card__title">
                    <span v-if="!klipUserId || !klipOrganisationId">{{ contactCardTitle }}</span>
                    <kl-router-link v-else :to="routeAdminUserDetail" :params="{userId: klipUserId, organisationId: klipOrganisationId }">{{ contactCardTitle }}</kl-router-link>
                </vl-title>
                <p class="kl-contact-card__subtitle">
                    <span v-if="contact.role">{{ contact.role }}</span>
                    <span v-if="contact.role && contact.company"> - </span>
                    <span v-if="contact.company">
                        <span v-if="!klipOrganisationId">{{contact.company}}</span>
                        <kl-router-link v-else :to="routeAdminSubOrganisationDetail" :params="{ organisationId: klipOrganisationId }">{{contact.company}}</kl-router-link>
                    </span>
                </p>
            </template>
        </kl-contact-card>
    </vl-column>
</vl-form-grid>
