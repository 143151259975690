import {camelCase, upperFirst} from 'lodash-es';
import {App} from 'vue';

export function registerDirectives(app) {

    const requireDirective = import.meta.glob(
        '@/app/shared/directives/**/*.ts',
        { eager: true }
    );

    Object.entries(requireDirective).forEach(([path, directiveConfig]) => {
        const directiveName = upperFirst(
            camelCase(path.split('/').pop().replace(/\.\w+$/, ''))
        );

        app.directive(
            directiveName,
            (directiveConfig as any).default || directiveConfig
        );
    })


    app.directive('scroll', {
        mounted(el, binding) {
            if (binding.value !== false) {
                el.scrollIntoView(true)
            }
        }
    })

    app.directive('focus', {
        mounted: function (el) {
            el.focus()
        }
    })
}

export default {
    install(app: App) {
        registerDirectives(app)
    }
}
