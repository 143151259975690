import {computed, defineComponent} from 'vue';
import {useMetaStore, IBreadCrumb} from '@/stores/meta-store';

export default defineComponent({
    name: 'KlBreadcrumb',
    props: {},
    setup(props) {

        const breadcrumbs = computed(() => useMetaStore().breadcrumbs);

        const to = (breadcrumb: IBreadCrumb) => {
            return routeName(breadcrumb) ? {
                name: routeName(breadcrumb),
                hash: breadcrumb.route && breadcrumb.route.hash ? breadcrumb.route.hash : '',
            } : null;
        }

        const routeName = (breadcrumb: IBreadCrumb) => {
            return (breadcrumb.route ? breadcrumb.route.name : '');
        }


        return {
            breadcrumbs,
            to,
            routeName,
        }
    }
})
