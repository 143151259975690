import { computed, defineComponent, inject, onMounted, ref } from "vue";
import * as KlipApi from "@/api/klip-api.proxy";
import { IMailSettings } from "./mailsettings";
import {useUserStore} from "../../state/UserDataModule";
import {EUserRole} from '@/app/shared/state/user-role';
import { IZoneToMove } from "@/app/admin/organisation/components/kl-una-zone-move/kl-una-zone-move";
import { IAlertHandler } from '@/plugins/alert';
import { EAlertType } from '@/app/shared/state/AlertModule';
import { useErrorStore } from "../../../../stores/error-store";
import { useUnaZoneStore } from "@/app/shared/components/kl-una-zone-details/kl-una-zone-store";
import { useRouter } from '@/router/router-vue3';
import { AdminOrganisationDetail } from "@/app/admin/admin-routes";

export default defineComponent({
    props: {
        zone: {
            type: Object as () => KlipApi.UnaZoneEditView,
            required: true
        },
        organisation: {
            type: Object as () =>KlipApi.Organisation,
            default: null,
            required: false
        },
        mailSettings: {
            type: Object as () =>IMailSettings,
            required: true
        }
    },
    setup: function (props) {
        const unaZone = ref<KlipApi.UnaZoneEditView>(props.zone);
        const unaMailSettings = ref<IMailSettings>(props.mailSettings);
        const proxy = inject<KlipApi.IKlipApiProxy>('klipApiProxy');
        const alertHandler = inject<IAlertHandler>('alerts');
        const router = useRouter();
        const isMovingZone = ref<boolean>(false);
        const moveZoneOngoing = ref<boolean>(false);
        const dataLoading = ref<boolean>(true);

        const showMoveZone = computed((): boolean => {
            return useUserStore().hasRole([EUserRole.admin]) && !moveZoneOngoing.value;
        });

        const onMoveZone = (zoneToMove: IZoneToMove) => {
            isMovingZone.value = true;
            const request = KlipApi.MoveZoneInput.fromJS({});
            request.mailTo = zoneToMove.mailTo;
            request.reason = zoneToMove.reason;
            request.targetKlipOrganisationId = zoneToMove.destinationKlipOrganisationId;
            request.zoneId = zoneToMove.zoneId;

            useErrorStore().setBypassError(['400', '404']);
            proxy.unaSettings_MoveZone(request).then(async () => {
                useUnaZoneStore().setMoveZoneMessage(zoneToMove, unaZone.value.zoneName, props.organisation.name);
                await router.push({
                    name: AdminOrganisationDetail.name,
                    params: { organisationId: props.organisation.rootId }
                });
            }).catch((e) => {
                isMovingZone.value = false;
                showFailure(e);
            }).finally(() => {
                useErrorStore().resetBypassError();
            });
        }

        const showFailure = (e) => {
            const toasterId = `confirm-event-replay-failed-toaster-${Math.random()
                .toString(36)
                .substring(7)}`;

            let message = 'Er ging iets mis! De zone werd NIET in de queue gezet om te verplaatsen. Probeer je even opnieuw?';
            if (e?.response?.result?.length > 0) {
                message = `Er ging iets mis! ${e.response?.result[0].message}`;
            }

            alertHandler.addAlert({
                type: EAlertType.Error,
                id: toasterId,
                title: 'Zone verplaatsen mislukt',
                message: message,
                icon: 'info-circle',
                autoClose: 5000,
                closable: true,
            });
        };

        const isMoveZoneOngoing = async () => {
            dataLoading.value = true;

            const moveIsOngoingResult = await proxy.unaSettings_MoveZoneOngoing(
                KlipApi.MoveZoneInput.fromJS({ zoneId: unaZone.value.id }));

            moveZoneOngoing.value = moveIsOngoingResult.result;
            dataLoading.value = false;
        }

        onMounted(async () => {
            await isMoveZoneOngoing();
        });

        return {
            unaZone,
            unaMailSettings,
            showMoveZone,
            onMoveZone,
            isMovingZone,
            moveZoneOngoing,
            dataLoading
        }
    }
});
