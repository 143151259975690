<div class="kl-upload">
  <!-- error -->
  <!-- <vl-column v-if="isError">
    <vl-alert icon="alert-circle" :title="errorTitle" :content="errorContent" mod-error />
  </vl-column> -->
  <!-- ./error -->

  <!-- success -->
  <!-- <vl-column v-if="isSuccess">
    <vl-alert icon="alert-circle" :title="successTitle" :content="successContent" mod-success />
  </vl-column> -->
  <!-- ./success -->

  <!-- uploading -->
  <vl-column v-vl-align:center v-if="isUploading">
    <kl-loading-bar :progress="uploadedFilePercentage" />
    <div class="vl-u-spacer"></div>
    <p v-html="uploadProgressMessage"></p>
  </vl-column>
  <!-- ./uploading -->

  <!-- upload -->
  <vl-column v-show="!isUploading">
    <vl-form-grid mod-stacked>
      <vl-column :width-l="guidelinesFromConfig && !modCollapsed ? '6' : '12'">
        <div class="kl-upload__header" v-if="modHeader">
          <vl-title tag-style="h3">{{ attrsFromConfig.title || 'Laad uw bestand op' }}</vl-title>
          <vl-button @click="revert" v-if="modRevertable" class="vl-button--link kl-upload__header__revert-button"
            icon="cross" mod-icon-before>Annuleer</vl-button>
        </div>
        <vl-upload v-bind="attrsFromConfig" v-on="$listeners" ref="vlUpload" :max-files="maxFiles"
          @upload-file-added="fileAdded" @upload-files-added="filesAdded" @upload-success="success"
          @upload-error="error" @upload-removed-file="removedFile" @upload-sending="sending"
          @upload-upload-progress="uploadProgress" v-if="attrsFromConfig.url" />
        <vl-button @click="startUpload" :mod-disabled="uploadButtonDisabled || modDisabled" v-if="!$attrs['auto-process']">
          {{ $attrs['upload-button-label'] || 'Opladen' }}</vl-button>
      </vl-column>
      <vl-column :width-l="modCollapsed ? '12' : '6'" v-if="guidelinesFromConfig && !modHideGuideLines">
        <ul class="kl-upload__guidelines">
          <li v-for="(guideline, index) in guidelinesFromConfig" :key="index" class="kl-upload__guidelines__item">
            <span class="kl-upload__guidelines__item__label" v-if="guideline.label">{{ guideline.label }}: </span>
            <component :is="guidelineComponent(guideline)" :to="guideline.link" class="kl-upload__guidelines__item__value" v-if="guideline.value" v-html="guideline.value"></component>
          </li>
        </ul>
      </vl-column>
    </vl-form-grid>
  </vl-column>
  <!-- ./upload -->

</div>
