<div>
    <!-- heading -->
    <vl-region>
        <vl-layout>
            <vl-form-grid mod-stacked>
                <vl-column>
                    <vl-title tag-name="h1">Instellingen</vl-title>
                </vl-column>
            </vl-form-grid>
        </vl-layout>
    </vl-region>
    <!-- ./heading -->

    <vl-region>
        <vl-layout>
            <vl-form-grid>
                <!-- error -->
                <vl-column key="error" v-if="error">
                    <vl-alert icon="alert-circle" title="Onbekende fout" mod-error>
                        <p>Er heeft zich een onbekende fout voorgedaan. Gelieve later opnieuw te proberen.</p>
                    </vl-alert>
                </vl-column>
                <!-- ./error -->

                <!-- loading -->
                <vl-column key="loading" v-else-if="loading">
                    <div v-vl-align:center>
                        <vl-loader message="De gegevens worden geladen. Even geduld, a.u.b." />
                    </div>
                </vl-column>
                <!-- ./loading -->

                <vl-column key="settings" v-else>
                    <component :is="wrapperComponent" @tab-change="tabChange">
                        <component :is="sectionComponent" :label="showTabs?'Contactvoorkeuren':null" id="contact" v-if="showContactSettings">
                            <kl-contact-settings :settings="viewModel" />
                        </component>
                        <component :is="sectionComponent" :label="showTabs?'Organisaties':null" id="organisation" v-if="showOrganisationSettings">
                            <kl-organisation-settings v-if="showOrganisationTab" />
                        </component>
                    </component>
                </vl-column>
            </vl-form-grid>
        </vl-layout>
    </vl-region>
</div>