<vl-form-grid mod-stacked>
    <vl-column v-if="isCitizen">
        <vl-alert :closable="false">
            <vl-typography>
              <div><b>Let op!</b> U staat op het punt om af te rekenen <b>als particulier</b>. U zal een factuur op uw naam krijgen. Als particulier kan u <b>geen</b> facturen op naam van een organisatie krijgen.</div>
              <div><b>Heeft u een factuur nodig op naam van een organisatie?</b> Meld u dan aan voor de organisatie in kwestie.</div>
          </vl-typography>
      </vl-alert>
    </vl-column>
    <vl-column>
        <kl-contact-card :title="title"/>
    </vl-column>
    <template v-if="noVatNumberPresent && !isCitizen">
        <vl-column  width-s="8" width-m="6" width-l="4">
        <kl-field-validation-wrapper :field="vatNumberField" #default="{hasErrors}" mod-required>
            <vl-input-field name="KBO-nummer" id="vatNumber" v-model="invoiceDetails.vatNumber" :mod-error="hasErrors" mod-block />
        </kl-field-validation-wrapper>
    </vl-column>
    <vl-column width-s="4" width-m="6" width-l="8" v-vl-visibility:hide.s />
    </template>
    <vl-column>
        <kl-address-form ref="addressForm" :model-value="invoiceDetails.address" @update:modelValue="onAddressUpdate" />
    </vl-column>
    <vl-column width-s="8" width-m="6" width-l="7">
        <kl-field-validation-wrapper :field="emailAddressField" #default="{hasErrors}" :annotation="emailAddressAnnotation" mod-required>
            <vl-input-field name="e-mailadres" id="email-address" v-model="invoiceDetails.emailAddress" autocomplete="on" placeholder="E-mail" :mod-error="hasErrors" mod-block />
        </kl-field-validation-wrapper>
    </vl-column>
    <vl-column v-if="userEmail">
        <vl-checkbox name="use-alternative-email" id="use-alternative-email" v-model="invoiceDetails.useAlternativeEmail">Mail de factuur ook naar {{ userEmail }}</vl-checkbox>
    </vl-column>
    <vl-column width-s="8" width-m="6" width-l="7" v-if="showOwnReference">
        <kl-field-validation-wrapper :field="ownReferenceField" #default="{hasErrors}" :annotation="ownReferenceAnnotation">
            <vl-input-field name="ownReference" id="ownReference" v-model="invoiceDetails.ownReference" :mod-error="hasErrors" mod-block />
        </kl-field-validation-wrapper>
    </vl-column>
    <vl-column width-s="4" width-m="6" width-l="8" v-vl-visibility:hide.s />
</vl-form-grid>
