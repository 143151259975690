import {
    Organisation,
    OrganisationInvoiceData,
    OrganisationSettingsInput,
    PaymentTypes,
    SubOrganisation
} from '@/api/klip-api.proxy';
import { organisationSettings } from '@/app/settings/settings-routes';
import {computed, defineComponent, onMounted, ref} from 'vue';
import {useKlipApiProxy} from '@/plugins/proxy-client';
import {useRouter} from '@/router/router-vue3';
import {useField, useForm} from 'vee-validate';
import {IKlInvoiceSettingsData} from '@/app/shared/components/kl-invoice-settings/kl-invoice-settings';

export default defineComponent({
    name: 'KlCreateSubOrganisation',
    props: {},
    setup(props) {

        const router = useRouter()

        const { handleSubmit, meta } = useForm();

        const organisationNameField = useField<string>('Naam organisatie', 'required|max:450');
        const organisationParentIdField = useField<string>('Suborganisatie van', 'required');

        const organisationName = organisationNameField.value;
        const organisationParentId = organisationParentIdField.value;

        const isSending = ref<boolean>(false);
        const isLoading = ref<boolean>(true);

        const disableSubmit = computed((): boolean => !meta.value.valid || !meta.value.dirty);

        const routeSettings = computed(() => {
            return organisationSettings;
        });

        const suborganisations = ref<SubOrganisation[]>([]);
        const invoiceData = ref<OrganisationInvoiceData>();

        const onUpdateInvoiceSettings = (data: IKlInvoiceSettingsData) => {
            invoiceData.value.email = data.email;
            invoiceData.value.attention = data.attention;
            invoiceData.value.reference = data.reference;
        }

        const setDepth = (depth: string, name: string) => {
            const currentDepth = +depth;
            let left = "";
            for (let index = 0; index < currentDepth; index++) {
                left += "&nbsp; ";
            }
            return left + name;
        }

        const _submitForm = () => {
            isSending.value = true;
            const organisationInput = new OrganisationSettingsInput();
            organisationInput.organisation = new Organisation();
            organisationInput.organisation.name = organisationName.value;
            organisationInput.organisation.parentId = organisationParentId.value;

            if (invoiceData.value) {
                organisationInput.organisationInvoiceData = new OrganisationInvoiceData();
                organisationInput.organisationInvoiceData.attention = invoiceData.value.attention;
                organisationInput.organisationInvoiceData.email = invoiceData.value.email;
                organisationInput.organisationInvoiceData.reference = invoiceData.value.reference;
                organisationInput.organisationInvoiceData.vatNumber = invoiceData.value.vatNumber;
            }

            useKlipApiProxy().organisation_CreateOrganisation(organisationInput).then(async () => {
                await router.push({ name: routeSettings.value.name, hash: "#organisation" });
            }).finally(() => {
                isSending.value = false;
            });
        }

        onMounted(() => {
            useKlipApiProxy().organisation_LoadCreateOrganisationView().then((view) => {
                suborganisations.value = view.result.subOrganisations;
                invoiceData.value = view.result.invoiceData;
            }).finally(() => {
                isLoading.value = false;
            });
        });

        const _onInvalidSubmit = (submittedValues) => {
            console.log('_onInvalidSubmit', submittedValues);
        }

        const onSubmit = handleSubmit(_submitForm, _onInvalidSubmit);

        return {
            organisationNameField,
            organisationParentIdField,

            organisationParentId,
            organisationName,
            isSending,
            isLoading,
            suborganisations,
            invoiceData,

            onUpdateInvoiceSettings,

            setDepth,
            onSubmit,
            disableSubmit,
        }
    }
})
