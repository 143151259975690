<template>
    <vl-textarea v-model="geometries" mod-block style="height: 200px" />
    <vl-action-group>
        <vl-button v-if="!forKlbZones" @click.prevent="fillZone" mod-secondary>fill</vl-button>
        <vl-button v-if="!forKlbZones" @click.prevent="updateZone">emit('input')</vl-button>
        <vl-button @click.prevent="removeGeometry" v-if="forGeometry && showRemoveGeometry">emit('remove-geometry')</vl-button>
        <template v-if="forKlbZones"><vl-button v-for="(zone, i) in inputGeometries" @click="editKlbZone(zone)" :key="i">Edit zone</vl-button></template>
        <vl-button v-if="canAddKlbZone" @click="addNewKlbZone">Zone toevoegen</vl-button>
        <vl-button v-if="canAddKlimZone" @click="addNewKlimZone">KLIM-zone toevoegen</vl-button>
    </vl-action-group>
</template>

<script lang="ts">

import {computed, defineComponent, onMounted, ref, Ref} from 'vue';
import {IDrawZoneMapGeometry} from '@/app/shared/components/kl-draw-zone-map/kl-draw-zone-map';
import {useRouter} from '@/router/router-vue3';

export default defineComponent({
    name: 'KlDrawZoneMap',
    emits: ['input', 'remove-geometry'],
    props: {
        inputGeometries: { type: Array as () =>  IDrawZoneMapGeometry[], default: (): IDrawZoneMapGeometry[] => [], },
        forGeometry: { type: Boolean, default: false },
        showRemoveGeometry: { type: Boolean, default: false },
        forKlbZones: { type: Boolean, default: false },
        canAddKlbZone: { type: Boolean, default: false },
        canAddKlimZone: { type: Boolean, default: false },
        addKlbZoneLink: { type: Object, default: null, },
        addKlimZoneLink: { type: Object, default: null, },
    },
    setup(props, { emit }) {

        const router = useRouter();
        const geometries = ref('[]');

        const updateInputGeometries = (data: IDrawZoneMapGeometry[]) => {
            geometries.value = JSON.stringify(data);
        }

        const fillZone = () => {
            geometries.value = '[{ "isValid": true, "type": "Polygon", "coordinates": [[[171408, 164828], [171408, 164822],[171413, 164822],[171415,164828],[171408, 164828]]], "wkt": "POLYGON((171408 164828, 171408 164822,171413 164822,171415 164828,171408 164828))" }]';
        }

        const updateZone = () => {
            emit('input', JSON.parse(geometries.value));
        }

        const removeGeometry = () => {
            emit('remove-geometry');
        }

        const editKlbZone = (selectedZone) => {
            router.push(selectedZone.orgZoneInfo?.editLink);
        }

        const addNewKlbZone = () => {
            router.push(props.addKlbZoneLink);
        }

        const addNewKlimZone = () => {
            router.push(props.addKlimZoneLink);
        }


        onMounted(() => {
            updateInputGeometries(props.inputGeometries);
        });

        return {
            geometries,
            updateInputGeometries,
            updateZone,
            fillZone,
            removeGeometry,
            editKlbZone,
            addNewKlbZone,
            addNewKlimZone,
        }
    }
})

</script>

