import { useUserStore } from '@/app/shared/state/UserDataModule';
import { OrganisationInvoiceData2 } from '@/api/klip-api.proxy';
import { escape } from 'html-escaper';
import {computed, defineComponent, onMounted, ref, watch} from 'vue';
import {useField, useForm} from 'vee-validate';
import {isEmpty} from 'lodash-es';

export interface IKlInvoiceSettingsData {
    email?: string;
    attention?: string;
    reference?: string;
}

export default defineComponent({
    name: 'KlInvoiceSettings',
    emits: ['update:modelValue'],
    props: {
        // modelValue: { type: Object as () => IKlInvoiceSettingsData, default: (): IKlInvoiceSettingsData => ({}) },
        invoiceData: {
            type: Object as () => OrganisationInvoiceData2,
            default: () => ({}),
            required: true
        },
        editMode: { type: Boolean, default: true },
        modHideGroup: { type: Boolean, default: false },
        modAdmin: { type: Boolean, default: false },
        error: { type: Boolean, default: false },
    },
    setup(props, { emit }) {

        const helpdeskMail: string = import.meta.env.VITE_EMAIL_HELPDESK;

        const emailField = useField<string>('E-mail', 'required|email|max:320');
        const attentionField = useField<string>('Ter attentie van', 'max:200');
        const referenceField = useField<string>('Eigen referentie', 'max:25');

        const isSending = ref<boolean>(false);
        const ownReference= ref<string>('');

        const hasData = computed((): boolean => !isEmpty(props.invoiceData));


        const email = computed(() => {
            // return props.invoiceData && !props.modAdmin ? props.invoiceData.email : '';
            return props.modAdmin ? '' : emailField.value.value;
        });

        const attention = computed(() => {
            // return (props.invoiceData && props.invoiceData.attention) ? props.invoiceData.attention : '';
            return attentionField.value.value;
        });


        const reference = computed(() => {
            // return (props.invoiceData && props.invoiceData.reference) ? props.invoiceData.reference : '';
            return referenceField.value.value;
        });


        const user = computed(() => {
            return useUserStore().user;
        });

        const title = computed(() => {
            return kboNumber.value ? `${escape(name.value)} - ${kboNumber.value}` : escape(name.value);
        });

        const athumiGateWayUnavailable = computed(() => {
            return props.error || _isAthumiGateWayUnavailable.value;
        });

        const name = computed(() => {
            return props.invoiceData && props.invoiceData.company ? props.invoiceData.company :
                user.value.organisation ? user.value.organisation : '';
        });

        const vatNumber = computed(() => {
            return props.invoiceData ? props.invoiceData.vatNumber : '';
        });

        const kboNumber = computed(() => {
            return props.invoiceData ? props.invoiceData.kboNumber : '';
        });

        const _isAthumiGateWayUnavailable = computed(() => {
            return props.invoiceData ? props.invoiceData.isAthumiGatewayServiceUnavailable : false;
        });

        const contactData = computed(() => {
            const streetParts = `${[props.invoiceData.address.street, props.invoiceData.address.streetNumber, props.invoiceData.address.box].join(' ').trim()}`;
            const cityParts = `${[props.invoiceData.address.zip, props.invoiceData.address.city].join(' ').trim()}
                ${props.invoiceData.address.city && props.invoiceData.address.country ?
                ` - ${props.invoiceData.address.country}` : ''}`;
            return `${escape(streetParts)}${streetParts && cityParts ? '<br>' : ''}${escape(cityParts)}`.trim();
        });

        const displayAttention = computed(() => {
            return vatNumber.value ? `BTW ${escape(vatNumber.value)}` : '';
        });

        const isGroup = computed(() => {
            return props.invoiceData && props.invoiceData.isInGroup && !props.modHideGroup;
        });


        const _setData = (newData: IKlInvoiceSettingsData) => {
            emailField.value.value = newData?.email;
            attentionField.value.value = newData?.attention;
            referenceField.value.value = newData?.reference;
        }

        // watch(
        //     () => props.modelValue,
        //     (val: IKlInvoiceSettingsData) => {
        //         _setData(val);
        //     },
        //     { immediate: false, deep: true })

        watch(
            [emailField.value, attentionField.value, referenceField.value],
            () => {
                const newData: IKlInvoiceSettingsData = {
                    email: emailField.value.value,
                    attention: attentionField.value.value,
                    reference: referenceField.value.value,
                };
                emit('update:modelValue', newData);
            },
            { immediate: false, deep: true })

        onMounted(() => {
            emailField.resetField({ value: props.invoiceData?.email });
            attentionField.resetField({ value: props.invoiceData?.attention });
            referenceField.resetField({ value: props.invoiceData?.reference });
        })

        return {
            emailField,
            attentionField,
            referenceField,

            helpdeskMail,

            hasData,
            isSending,
            ownReference,

            user,
            title,
            athumiGateWayUnavailable,
            email,
            name,
            vatNumber,
            kboNumber,
            contactData,
            attention,
            displayAttention,
            reference,
            isGroup,
        }
    }
})
