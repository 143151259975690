<vl-form-grid mod-stacked v-if="hasLogs">
    <vl-column>
        <vl-title tag-name="h1">Auditlog</vl-title>
    </vl-column>
    <vl-column>
        <kl-description-list v-for="log in logs" :key="log.timestamp" class="audit-log">
            <kl-description-list-row :label="formatDate(log.timestamp)">{{ log.description }}</kl-description-list-row>
        </kl-description-list>
    </vl-column>
</vl-form-grid>
