<vl-form-grid>
  <!-- error -->
  <vl-column v-if="error">
    <vl-alert icon="alert-circle" title="Uw bestand kon niet verwerkt worden" title-tag-name="p" mod-error>
      <template v-if="errorMessages">
        <ul v-if="errorMessages.length > 1">
          <li v-for="(message, index) in errorMessages" :key="index">
            {{ message }}
          </li>
        </ul>
        <p v-else>{{ errorMessages[0] }}</p>
      </template>
      <template v-slot:actions>
        <vl-button icon="text-redo" @click="reset" mod-icon-before>Opnieuw proberen</vl-button>
      </template>
    </vl-alert>
  </vl-column>
  <!-- ./error -->

  <!-- upload -->
  <vl-column v-if="!error">
    <kl-upload
      id="zone-upload"
      ref="zoneUpload"
      name="zone-upload"
      :config="config"
      :auto-process="true"
      :options="{timeout: 0}"
      @upload-files-added="filesAdded"
      @upload-processing="onProcessing"
      @upload-success="onUploadSuccess"
      @upload-error="onUploadError"
      mod-collapsed
      :mod-header="false"
      :mod-hide-guide-lines="modHideGuideLines"
      :max-files="maxFiles"
    />
    <vl-link v-if="!modHideGuideLines && moreInfoLink" mod-icon-before icon="external" :to="moreInfoLink" target="_blank" style="margin-top:15px;">
      Meer info
    </vl-link>
  </vl-column>
  <!-- ./upload -->
</vl-form-grid>
