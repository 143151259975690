import {NavigationGuard, NavigationGuardReturn, RouteLocation} from 'vue-router';
import {useUserStore} from '@/app/shared/state/UserDataModule';

export function createScopeGuard(): NavigationGuard {

    return async function (to: RouteLocation, from: RouteLocation): Promise<NavigationGuardReturn> {

        if (!to?.meta?.allowAnonymous) {
            const scope = ['una', 'pda', 'mri'].find((item) => {
                return to.path.startsWith(`/${item}`);
            });
            if (scope) {
                console.log(`SET SCOPE: ${scope}`);
                useUserStore().setScope(scope);
            }
        }
    }
}
