<div :class="classes">
    <template v-if="!hasTopActions && rows.length && meta.resultsPerPage && !modHidePagination && modPaginationOnTop">
        <ippro-pagination :locale="locale" :page-size="meta.resultsPerPage" :current-page="currentPage"
                          :results="rows.length"
                          :total="totalRows" @page-change="changePage"/>
        <vl-u-spacer :mod-xsmall="modSmall"/>
    </template>
    <template v-if="hasTopActions && modPaginationOnTop">
        <vl-grid>
            <vl-column width-s="12" width-m="5" width-l="4" v-if="hasTopActions">
                <div v-vl-align:center.s>
                    <slot name="topactions"></slot>
                </div>
                <vl-u-spacer :mod-xsmall="modSmall"/>
            </vl-column>
            <vl-column width-s="12" :width-m="hasTopActions ? '7' : '12'" :width-l="hasTopActions ? '8' : '12'"
                       v-if="rows.length && meta.resultsPerPage">
                <template v-if="!modHidePagination">
                    <ippro-pagination :locale="locale" :page-size="meta.resultsPerPage" :current-page="currentPage"
                                      :results="rows.length" :total="totalRows" @page-change="changePage"/>
                    <vl-u-spacer :mod-xsmall="modSmall"/>
                </template>
            </vl-column>
        </vl-grid>
    </template>

    <div>
        <div v-if="!fetching && rows.length==0 && !modNoResults">
            <slot name="noresults"></slot>
        </div>
        <div v-else-if="modUseCards">
            <div v-for="(row) in rows">
                <slot name="card" :row="row"></slot>
            </div>
        </div>
        <div v-else>
            <!-- Add :mod-sticky when implemented-->
            <vl-data-table :mod-zebra="modZebra" :class="modSticky ? 'vl-data-table--sticky' : ''">
                <thead v-if="!modHideHeader">
                <tr>
                    <th v-if="modCheckableRows" style="width: 40px;"
                        :class="modStickyHeader && modSticky ? 'vl-data-table__cell--sticky' : ''">
              <span class="ippro-datatable__cell">
                <vl-checkbox :model-value="masterCheckbox" @update:modelValue="toggleMasterCheckbox" v-if="showMasterCheckbox"/>
              </span>
                    </th>
                    <th v-for="(column, index) in visibleColumns" :style="headerStyle(column)"
                        :class="modStickyHeader && modSticky ? 'vl-data-table__cell--sticky' : ''">
                        <component
                            :is="column.sortable ? 'vl-link' : 'ippro-icon-text'"
                            :tag-name="column.sortable ? 'button' : 'span'"
                            @click="sort(column)" :mod-icon-after="!!column.direction" :icon="sortIcon(column)">
                            <span :class="headerClass(column)">{{ column.label }}</span>
                        </component>
                    </th>
                </tr>
                </thead>
                <tbody>
                <template v-if="!fetching && rows.length > 0">
                    <template v-for="(row, index) in rows">
                        <transition name="fade-row">
                            <tr :class="rowClasses(row)" v-show="row.visible !== false">
                                <td v-if="modCheckableRows">
                                <span class="ippro-datatable__cell">
                                  <vl-checkbox :model-value="row.checked" @update:modelValue="toggleRowCheckbox(row, $event)" :mod-disabled="disableCheckbox(row)"/>
                                </span>
                                </td>
                                <td v-for="(column, index) in visibleColumns" @click="cellClicked(row, column)"
                                    :style="cellStyle(column)" v-bind:class="cellClasses(column)"
                                    >
                                    <component :is="parse(row, column)" class="ippro-datatable__cell"></component>
                                </td>
                            </tr>
                        </transition>
                    </template>
                </template>
                <tr class="ippro-datatable__row" v-for="n in placeholderRows" v-else-if="fetching || modNoResults">
                    <td class="vl-u-align-center" :colspan="placeholderColspan">
                        <div :class="placeholder" role="alert" aria-busy="true" v-if="n==spinnerPosition"
                             v-html="fetching?'&nbsp;':'Geen resultaten'">
                        </div>
                        <div v-else v-html="'&nbsp;'"></div>
                    </td>
                </tr>
                </tbody>
            </vl-data-table>
        </div>
    </div>

    <!-- actions & pagination -->
    <template v-if="hasActions || rows.length">
        <vl-grid>
            <vl-column width-s="12" width-m="5" width-l="4" v-if="hasActions">
                <vl-u-spacer mod-small/>
                <div v-vl-align:center.s>
                    <slot name="actions"></slot>
                </div>
            </vl-column>
            <vl-column width-s="12" :width-m="hasActions ? '7' : '12'" :width-l="hasActions ? '8' : '12'"
                       v-if="rows.length && meta.resultsPerPage">
                <template v-if="!modHidePagination">
                    <vl-u-spacer :mod-xsmall="modSmall"/>
                    <ippro-pagination :locale="locale" :page-size="meta.resultsPerPage" :current-page="currentPage"
                                      :results="rows.length"
                                      :total="totalRows" @page-change="changePage"/>
                </template>
            </vl-column>
        </vl-grid>
    </template>
    <!-- ./actions & pagination -->
</div>
