<template>
  <div>
    <div class="kl-locationpicker">
      <template v-if="location">
        <vl-pill class="kl-locationpicker__pill" tag-name="button" type="button" @click.native="editLocation" @close="removeLocation" mod-closable>Locatie ingesteld</vl-pill>
        <input type="hidden" name="location" v-model="location">
      </template>
      <button type="button" class="kl-locationpicker__button" @click.prevent="toggleModal" v-else>
        <vl-icon class="kl-locationpicker__button__icon" icon="location-gps" />
        <span class="kl-locationpicker__button__label">Kies locatie</span>
      </button>
    </div>
    <vl-modal title="Geef de zone aan waarin u wil zoeken" class="vl-modal--fullscreen" id="location-picker-modal" :closable="true" mod-large>
      <kl-draw-zone-map
        v-if="drawZoneActive"
        name="zone"
        ref="map"
        :input-geometries="zones"
        :no-confirmation="true"
        rules="is_polygon|not_self_intersecting_polygon"
        @input="onZonesChanged"
      />
      <vl-action-group>
        <vl-button type="button" @click.prevent="applyZone" mod-large :mod-disabled="applyButtonDisabled">Bevestigen</vl-button>
        <vl-button type="button" class="vl-button--link" v-vl-modal-toggle="'location-picker-modal'" icon="cross" mod-icon-before>Annuleer</vl-button>
      </vl-action-group>
    </vl-modal>
  </div>
</template>

<script lang="ts" src="./kl-locationpicker.ts">
</script>

<style lang="scss">
@import "./kl-locationpicker.scss";
</style>
