import {defineComponent, ref} from 'vue';

export default defineComponent({
    name: 'VlFormValidation',
    props: {
    },
    setup(props) {

        const hasErrors = ref(false);

        return {
            hasErrors,
        };
    }
});
