<div class="kl-profile-dropdown" @keyup.esc="close">
    <div v-if="isOnlyPdaManager" class="kl-profile-dropdown__toggle">
        <div class="kl-profile-dropdown__toggle__label">
            <span v-if="userScope">{{ userScope.label }}&nbsp;(<strong>{{ userScope.abbr }}</strong>)</span>
        </div>
    </div>
    <button v-else @click="toggle" class="kl-profile-dropdown__toggle" v-focus="toggleFocus" :aria-expanded="isOpen.toString()">
        <span class="kl-profile-dropdown__toggle__label">
            <span v-if="userScope">{{ userScope.label }}&nbsp;(<strong>{{ userScope.abbr }}</strong>)</span>
        </span>
        <vl-icon icon="up-down-arrows" mod-small />
    </button>

    <div class="kl-profile-dropdown__content" :class="{'kl-profile-dropdown__content__no_min_width': applyMinWidth}" v-focus="isOpen" v-if="isOpen" v-kl-click-outside="close">
        <div class="kl-profile-dropdown__content__wrapper">
            <ul v-if="showBalance" class="kl-profile-dropdown__list">
                <li class="kl-profile-dropdown__list__item">
                    <kl-router-link :to="routePrepaid" protect class="kl-profile-dropdown__list__item__link"
                        @click.native="close">
                        <vl-icon icon="coin-stack" />
                        <span v-if="financialServiceAvailable" class="kl-profile-dropdown__list__item__label">Uw saldo:<strong v-if="hasPrepaidSaldo">&nbsp;{{$filters.toCurrency(prepaidSaldo)}}</strong><vl-loader class="prepaid-loader" v-if="!hasPrepaidSaldo" /></span>
                        <span v-if="!financialServiceAvailable" class="kl-profile-dropdown__list__item__label">Saldo tijdelijk niet beschikbaar.</span>
                    </kl-router-link>
                </li>
            </ul>
            <ul class="kl-profile-dropdown__list">
                <li class="kl-profile-dropdown__list__item">
                    <kl-router-link :to="routeMriInvoices" protect class="kl-profile-dropdown__list__item__link"
                        @click.native="close">
                        <vl-icon icon="file-new" />
                        <span class="kl-profile-dropdown__list__item__label">Facturen</span>
                    </kl-router-link>
                </li>
                <li class="kl-profile-dropdown__list__item" v-if="showSettings">
                    <kl-router-link protect :to="routeSettings" class="kl-profile-dropdown__list__item__link"
                        @click.native="close">
                        <vl-icon icon="switches" />
                        <span class="kl-profile-dropdown__list__item__label">Instellingen</span>
                    </kl-router-link>
                </li>
            </ul>
            <ul class="kl-profile-dropdown__list kl-profile-dropdown__list--rolepicker" v-if="showRolepicker">
                <li class="kl-profile-dropdown__list__item kl-profile-dropdown__list__item--heading">
                    <span class="kl-profile-dropdown__list__item__label">Kies uw rol:</span>
                </li>
                <li :class="{'kl-profile-dropdown__list__item': true, 'kl-profile-dropdown__list__item--active': scope.key === userDataScope}"
                    v-for="scope in userDataScopes">
                    <button class="kl-profile-dropdown__list__item__link" @click.prevent="switchScope(scope.key)">
                        <vl-icon icon="bullet" v-if="scope.key === userDataScope" />
                        <span class="kl-profile-dropdown__list__item__label">{{ scope.label }} ({{ scope.abbr }})</span>
                    </button>
                </li>
            </ul>
        </div>
    </div>
</div>
