import {defineComponent, ref, inject, reactive, toRefs, watch} from "vue";
import debounce from "lodash-es/debounce";
import {useKlipApiProxy} from '@/plugins/proxy-client';

export interface ISelectedOrganisation {
    organisationId?: string;
    name?: string | undefined;
    parentId?: string | undefined;
    namePath?: string | undefined;
}

export default defineComponent({
    emits: ['selected'],
    props: {
        showId: {
            type: Boolean,
            default: false,
            required: false
        },
        onlyIncludeUnaOrganisations: {
            type: Boolean,
            default: false,
            required: false
        },
        onlyIncludeRootOrganisations: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    setup(props, { emit }) {

        const klipApiProxy = useKlipApiProxy();

        const selectedOrganisationNames = ref<string[]>([]);
        const organisationDataFetching = ref<boolean>(false);
        const organisationData = ref<AutoCompleteResult[]>([]);

        const onOrganisationSelect = (selectedOrganisation: AutoCompleteResult) => {
            const organisationIds = !selectedOrganisation.value ? [] : selectedOrganisation.value.split('|');

            const organisation: ISelectedOrganisation = {
                organisationId: organisationIds.length == 0 ? null : organisationIds[0],
                name: selectedOrganisation.title,
                namePath: !selectedOrganisation.subtitle ? '' : selectedOrganisation.subtitle,
                parentId:  organisationIds.length > 1 && organisationIds[1] !== 'null' ? organisationIds[1] : null
            }
            emit('selected', organisation);
        };

        const debouncedOrganisationSearch = debounce((searchValue) => {
            if (!searchValue) {
                return;
            }

            organisationDataFetching.value = true;
            klipApiProxy.organisation_FilterOrganisationsByName(searchValue, props.onlyIncludeUnaOrganisations, props.onlyIncludeRootOrganisations)
                .then((filterResult) => {
                    organisationData.value = filterResult.result.map((item) => {
                        const suffix = props.showId ? ` - GUID: ${item.organisationId}` : '';
                        return {
                            title: `${item.name}${suffix}`,
                            subtitle: item.namePath,
                            value: `${item.organisationId}|${item.parentId}`
                        }

                    });
                }).finally(() => {
                    organisationDataFetching.value = false;
                });
        }, 400);

        const reset = () => {
            selectedOrganisationNames.value = [];
        }

        const onOrganisationInputChange = (searchValue: string) => {
            debouncedOrganisationSearch(searchValue);
        };


        return {
            selectedOrganisationNames,
            organisationData,
            organisationDataFetching,
            onOrganisationInputChange,
            onOrganisationSelect,
            reset,
        };
    }
});
