import {App, Plugin} from 'vue';
import Multiselect from 'vue-multiselect';
import VueJsonPretty from 'vue-json-pretty';

export default {
    install(app: App) {
        app.component("vue-json-pretty", VueJsonPretty)
        app.component('multiselect', Multiselect);
    }
} as Plugin;
