<div id="admin-settings-versions">
    <vl-region>
        <vl-form-grid mod-stacked>
            <vl-column>
                <vl-title tag-name="h2">Feature flags</vl-title>
            </vl-column>
            <vl-column>
                <ippro-datatable id="featureflags-operations-table"
                                 :meta="featureFlagsMeta"
                                 :dataset="featureFlags"
                                 :columns="featureFlagsColumns"
                                 :fetching="featureFlagsFetching"
                                 mod-hide-pagination
                                 mod-zebra>
                </ippro-datatable>
            </vl-column>
        </vl-form-grid>
    </vl-region>
</div>
