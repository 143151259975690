<div id="admin-settings-prices">
    <vl-region>
        <vl-form-grid mod-stacked>
            <vl-column>
                <vl-title tag-name="h2">Prijs instellingen</vl-title>
            </vl-column>
            <vl-column>
                <ippro-datatable class="events" mod-zebra mod-hide-pagination
                                 :meta="pricesMeta" :dataset="prices" :columns="columns"
                                 :fetching="isPricesDataTableLoading">
                    <template v-slot:actions>
                        <div v-vl-align:left>
                            <vl-button @click="toggleHistory" class="vl-button--link" icon="calendar" mod-icon-before>
                                {{ toggleHistoryButtonText }}
                            </vl-button>
                        </div>
                    </template>
                </ippro-datatable>
            </vl-column>
        </vl-form-grid>
    </vl-region>
</div>
