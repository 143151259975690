<form @submit.prevent="search" class="kl-search-organisations vl-form" v-kl-u-background-alt v-kl-u-box-padding>
    <div v-kl-u-background-white v-kl-u-box-padding>
        <vl-form-grid mod-stacked>
            <vl-column width="4" s-width="12" v-for="field in searchFields" :key="field.key">
                <vl-radio :id="uniqueFieldId(field.key)" :value="field.key" v-model="searchField"
                    @input="searchFieldChanged" name="filter">{{ field.label }}</vl-radio>
            </vl-column>
            <vl-column width="12">
                <vl-input-field name="keyword" placeholder="Zoek trefwoord" autocomplete="off" v-model="keyword"
                    mod-block />
            </vl-column>
            <vl-column width="12">
                <vl-checkbox v-model="onlyRootOrganisations" mod-block>Toon alleen hoofdorganisaties</vl-checkbox>
            </vl-column>
        </vl-form-grid>
    </div>
</form>