import {defineComponent, ref, computed} from "vue";
import * as KlipApi from "@/api/klip-api.proxy";
import {ISelectedOrganisation} from "@/app/admin/components/kl-search-organisation/kl-search-organisation";
import {useKlipApiProxy} from '@/plugins/proxy-client';
import {useField, useForm} from 'vee-validate';
import {useKlModalEventsUtil} from '@/app/shared/helpers/kl-modal-events-util';

export interface IZoneToMove {
    zoneId: string;
    sourceKlipOrganisationId: string;
    destinationKlipOrganisationId: string;
    destinationKlipOrganisationName: string;
    reason: string;
    mailTo: string[];
}

export default defineComponent({
    emits: ['move'],
    props: {
        zoneId: {
            type: String,
            required: true
        },
        sourceKlipOrganisationId:  {
            type: String,
            required: true
        }
    },
    setup(props, { emit }) {
        const proxy = useKlipApiProxy();

        const unaZoneMoveModalEvents = useKlModalEventsUtil('una-zone-move-modal', {
            onClosed() {
                form.resetForm();

                destinationOrganisation.value = null;
                hasDestinationOrganisation.value = false;
            },
        });

        const form = useForm();
        const disableSubmit = computed((): boolean => {
            return !form.meta.value.valid || !form.meta.value.dirty || movingZone.value;
        });

        const selectedDestinationOrganisationField = useField<string>('Nieuwe organisatie', 'required');
        const reasonField = useField<string>('Reden van verplaatsing', 'required');
        const mailToField = useField<string>('Mail naar', 'required|validKlipEmails');

        const selectedDestinationOrganisation = selectedDestinationOrganisationField.value;
        const reason = reasonField.value;
        const mailTo = mailToField.value;

        const movingZone = ref<boolean>(false);
        const hasDestinationOrganisation = ref<boolean>(false);
        const destinationOrganisation = ref<ISelectedOrganisation>(null);

        const onCancelMoveUnaZone = () => {
            unaZoneMoveModalEvents.triggerModalToggle();
        };

        const _move = () => {
            emit('move', <IZoneToMove> {
                zoneId: props.zoneId,
                sourceKlipOrganisationId: props.sourceKlipOrganisationId,
                destinationKlipOrganisationId: destinationOrganisation.value.organisationId,
                destinationKlipOrganisationName: destinationOrganisation.value.name,
                reason: reason.value,
                mailTo: mailTo.value.split(";").map(m => m.trim())
            });

            unaZoneMoveModalEvents.triggerModalToggle();
        }

        const onOrganisationSelect = (selectedOrganisation: ISelectedOrganisation) => {
            if (!selectedOrganisation || !selectedOrganisation.organisationId) {
                return;
            }

            hasDestinationOrganisation.value = !!selectedOrganisation;
            destinationOrganisation.value = selectedOrganisation;
            selectedDestinationOrganisation.value = selectedOrganisation.name

            // get the una emailaddresses
            const request = KlipApi.GetUnaManagerEmailAddressesQuery.fromJS({});
            request.klipOrganisationIds = [props.sourceKlipOrganisationId, selectedOrganisation.organisationId];
            proxy.unaSettings_GetUnaManagerEmailAddresses(request).then((r) => {
                mailTo.value = r.result.join("; ");
            });
        };

        const _onInvalidSubmit = (submittedValues) => {
            console.log('_onInvalidSubmit', submittedValues);
        }

        const onSubmit = form.handleSubmit(_move, _onInvalidSubmit);

        return {
            selectedDestinationOrganisationField,
            reasonField,
            mailToField,
            selectedDestinationOrganisation,
            reason,
            mailTo,

            movingZone,
            destinationOrganisation,
            hasDestinationOrganisation,
            onOrganisationSelect,
            onCancelMoveUnaZone,

            onSubmit,
            disableSubmit,
        };
    }
});
