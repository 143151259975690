<template>
  <div class="vl-autocomplete">
    <vl-input-field
      v-bind="$attrs"
      v-model="search"
      mod-block
      @blur="resultsVisible = false"
      @click="resultsVisible = true"
      @focus="resultsVisible = true"
      @input="inputValue"
      @keydown="resultsVisible = true"
      @keydown.esc="resultsVisible = false"
      @keydown.enter="select()"
      @keyup.down="arrowDown"
      @keyup.up="arrowUp"
      autocomplete="off"
    />
    <div v-if="loading" class="vl-autocomplete__loader">
      <vl-loader />
    </div>
    <div v-else class="vl-autocomplete__wrapper">
      <div v-if="(results.length > 0 && resultsVisible) || (showNoResults && resultsVisible)" class="vl-autocomplete__list_wrapper">
        <ul class="vl-autocomplete__list">
          <li v-for="r in results" :key="r.title + r.subtitle" class="vl-autocomplete__item">
            <a
              :class="{ 'vl-autocomplete__cta--focus': r.selected }"
              :data-vl-value="r"
              class="vl-autocomplete__cta"
              data-vl-focus
              data-vl-record
              href="#"
              tabindex="-1"
              @click.prevent="selectFromList(r)"
              @pointerdown.prevent="resultsVisible = true"
              ><span class="vl-autocomplete__cta__title">
                {{ r.title }}
              </span>
              <vl-annotation v-if="r.subtitle">
                {{ r.subtitle }}
              </vl-annotation>
            </a>
          </li>
        </ul>
        <div v-if="showNoResults">
          <p class="vl-autocomplete__feedback">{{ feedback || 'Geen resultaten voor ' + search }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" src="./vl-autocomplete.ts" />
<style lang="scss" scoped src="./vl-autocomplete.scss"></style>
