import { defineComponent, ref } from "vue";
import {ISelectedOrganisation} from "@/app/admin/components/kl-search-organisation/kl-search-organisation";
import {EAlertType} from "@/app/shared/state/AlertModule";
import {IAlertHandler} from "@/plugins/alert";
import {useField, useForm} from 'vee-validate';
import {useKlModalEventsUtil} from '@/app/shared/helpers/kl-modal-events-util';

export interface IMapRequestToMove {
    destinationKlipOrganisationId: string;
    destinationParentKlipOrganisationId?: string | undefined;
    reason: string;
}

export class MoveMapRequestNotifications {
    static ShowFailure(alertHandler: IAlertHandler) {
        const toasterId = `move-failed-toaster-${Math.random()
            .toString(36)
            .substring(7)}`;
        const message =
            'Er ging iets mis! Uw planaanvragen zijn NIET in de queue gezet om te verplaatsen. Probeer je even opnieuw?';
        alertHandler.addAlert({
            type: EAlertType.Error,
            id: toasterId,
            title: 'Planaanvragen verplaatsen mislukt',
            message: message,
            icon: 'info-circle',
            autoClose: 5000,
            closable: true,
        });
    }

    static ShowSuccess(alertHandler: IAlertHandler, numberOfMovedMapRequests: number) {
        const toasterId = `move-success-toaster-${Math.random()
            .toString(36)
            .substring(7)}`;
        const message =
            numberOfMovedMapRequests > 1
                ? `Er zijn ${numberOfMovedMapRequests} planaanvragen in de queue gezet om te verplaatsen. U ontvangt een e-mail met het resultaat.`
                : `Er is 1 planaanvraag in de queue gezet om te verplaatsen. U ontvangt een e-mail met het resultaat.`;
        alertHandler.addAlert({
            type: EAlertType.Success,
            id: toasterId,
            title: 'Planaanvragen verplaatsen',
            message: message,
            icon: 'info-circle',
            autoClose: 5000,
            closable: true,
        });
    }
}

export default defineComponent({
    emits: ['move'],
    setup(props, { emit }) {

        const modalEvents = useKlModalEventsUtil('move-map-request-modal');

        const form = useForm();

        const selectedDestinationOrganisationField = useField<string>('Organisatie', 'required');
        const reasonField = useField<string>('Reden van verplaatsing', 'required');

        const selectedDestinationOrganisation = selectedDestinationOrganisationField.value;
        const reason = reasonField.value;


        const movingMapRequests = ref<boolean>(false);
        // const selectedDestinationOrganisation = ref<string>('');
        // const reason = ref<string>('');
        const hasDestinationOrganisation = ref<boolean>(false);
        const destinationOrganisation = ref<ISelectedOrganisation>(null);

        const onCloseMoveMapRequestModal = () => {
            destinationOrganisation.value = null;
            hasDestinationOrganisation.value = false;
            reason.value = '';
            selectedDestinationOrganisation.value = '';

            modalEvents.triggerModalToggle();
        };

        const _move = () => {
            emit('move', <IMapRequestToMove> {
                destinationKlipOrganisationId: destinationOrganisation.value.organisationId,
                destinationParentKlipOrganisationId: destinationOrganisation.value.parentId,
                reason: reason.value
            });

            onCloseMoveMapRequestModal();
        }

        const onOrganisationSelect = (selectedOrganisation: ISelectedOrganisation) => {
            hasDestinationOrganisation.value = !!selectedOrganisation;
            destinationOrganisation.value = selectedOrganisation;
            selectedDestinationOrganisation.value = selectedOrganisation.name
        };

        const _onInvalidSubmit = (submittedValues) => {
            console.log('_onInvalidSubmit', submittedValues);
        }

        const onSubmit = form.handleSubmit(_move, _onInvalidSubmit);

        return {
            selectedDestinationOrganisationField,
            reasonField,
            selectedDestinationOrganisation,
            reason,

            movingMapRequests,
            destinationOrganisation,
            hasDestinationOrganisation,

            onOrganisationSelect,
            onCloseMoveMapRequestModal,
            onSubmit,
        };
    }
});
