<div>
    <vl-title tag-name="h2" >Zones van {{ displayName }}</vl-title>
    <div v-vl-align:center v-if="zonesFetching">
        <vl-loader message="De gegevens worden geladen. Even geduld, a.u.b." />
    </div>
    <template v-else-if="namespaces">
        <kl-draw-zone-map
            :input-geometries="drawZones"
            :mod-disabled="false"
            name="zones"
            ref="drawZonesMap"
            rules=""
            :show-map="true"
            :mod-enable-multi-zones="true"
            :mod-show-km-hm-toggle="false"

            :for-klb-zones="true"
            :can-add-klb-zone="canAddKlbZone"
            :can-add-klim-zone="canAddKlimZone"
            :add-klb-zone-link="{ name: routeUnaZoneCreate.name, params: { organisationId: value.organisationId }}"
            :add-klim-zone-link="{ name: routeUnaKlimZoneCreate.name, params: { organisationId: value.organisationId }}"

            :include-imkl="false"
            :mod-maprequest-zone="false"
        />
    </template>
</div>
