<kl-field-validation-wrapper :field="tagsField" #default="{hasErrors}">
    <vl-multiselect
        ref="multiselect"
        id="tags"
        v-bind="$attrs"
        v-model="tags"
        placeholder="Tags toevoegen"
        select-label="Selecteer tag"
        tag-placeholder="Voeg nieuwe tag toe"
        autocomplete="off"
        :options="tagSuggestions"
        :multiple="true"
        :taggable="true"
        :disabled="modDisabled"
        @select="onAddSelect"
        @remove="onRemoveSelect"
        @tag="onTagAdded">
    </vl-multiselect>
</kl-field-validation-wrapper>
