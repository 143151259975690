<vl-column v-if="isVisible">
    <vl-alert icon="credit-card" title="Hoe een testbetaling uitvoeren?" :closable="false">
        <vl-typography>
            <div>Volgende mogelijkheden om testbetalingen uit te voeren:</div>
            <ol>
                <li>Bancontact app: <br />
                    <ul>
                        <li>QR: klik op de QR code</li>
                    </ul>
                </li>
                <li>
                    Betalen met kaart:<br />
                    <ul>
                        <li>VISA: kaartnummer 4111 1111 1111 1111</li>
                        <li>MASTERCARD: kaartnummer 5555 5555 5555 0004</li>
                        <li>BANCONTACT: kaartnummer 6703 2222 2222 2222 7</li>
                    </ul>
                </li>
                <li>
                   Meer mogelijkheden op: <a href="https://developer.myccv.eu/documentation/payment_api/online_payments/developer_resources/test_cards/#3d-secure-test-cards" target="_blank">Online Payments Test Cards | CCV Docs (myccv.eu)</a>
                </li>
            </ol>
      </vl-typography>
  </vl-alert>
</vl-column>