import {computed, defineComponent, onMounted, ref} from "vue";
import * as KlipApi from "@/api/klip-api.proxy";
import { useRoute } from '@/router/router-vue3';
import { IUnaZone } from "./una-zone-model";
import { AdminSubOrganisationDetailUnaZone, AdminSubOrganisationDetailUnaKlimZone } from "@/app/admin/admin-routes";
import {IDrawZoneMapGeometry} from '@/app/shared/components/kl-draw-zone-map/kl-draw-zone-map';
import KlDrawZoneMap from '@/app/shared/components/kl-draw-zone-map/kl-draw-zone-map.vue';
import {useKlipApiProxy} from '@/plugins/proxy-client';
import {useField, useForm} from 'vee-validate';
import {RouteRecordRaw} from 'vue-router';

export default defineComponent({
    props: {
        organisation: {
            type: KlipApi.SubOrganisationDetail,
            default: null,
            required: true
        }
    },
    setup(props) {
        const proxy = useKlipApiProxy();

        const klbZonesMap = ref<InstanceType<typeof KlDrawZoneMap>>();
        const klbZonesKey = ref<string>('klbZonesKey');

        const form = useForm();
        const disableSubmit = computed((): boolean => {
            return !form.meta.value.valid || !form.meta.value.dirty;
        });

        const confirmationField = useField<boolean>('Nieuwe planaanvraag te bevestigen', null, { initialValue: props.organisation.unaData.confirmation });
        const confirmation = confirmationField.value;


        const displayName = computed((): string => props.organisation.displayName);
        const confirmationExampleUri = computed((): string => props.organisation.unaData.confirmationExampleUri);
        const subjectSigningCertificate = computed((): string => props.organisation.subjectSigningCertificate);

        const route = useRoute();
        const zonesFetching = ref<boolean>(true);
        const isSending = ref<boolean>(false);
        const alsoSearchArchivedZones = ref<boolean>(false);
        const zones = ref<IUnaZone[]>([]);
        const routeUnaKlimZone = ref<RouteRecordRaw>(AdminSubOrganisationDetailUnaKlimZone);
        const routeUnaZone = ref<RouteRecordRaw>(AdminSubOrganisationDetailUnaZone);

        const drawZones = computed(() => {
            const today = new Date();

            const myNamespaces = zones.value;
            const allDrawZones = myNamespaces.map((zone, index): IDrawZoneMapGeometry => {

                const activationDate = new Date(zone.zoneGeometryActivationDate);
                const isActive = !zone.isArchived && (zone.isKlimZone || !zone.zoneGeometryActivationDate || (activationDate < today));

                return {
                    name: zone.zoneName,
                    wkt: zone.zoneGeometry,
                    orgZoneInfo: {
                        isKlim: zone.isKlimZone,
                        guid: zone.zoneId,
                        namespace: zone.namespace,
                        isActive: isActive,
                        isArchived: zone.isArchived,
                        willBeActivatedOn: !isActive ? activationDate : undefined,
                        editLink: {
                            name: zone.link?.route?.name,
                            params: { zoneId: zone.zoneId },
                        },
                    }
                }
            });

            let filteredDrawZones = allDrawZones;
            if (!alsoSearchArchivedZones.value) {
                filteredDrawZones = allDrawZones.filter((cz: IDrawZoneMapGeometry): boolean => !cz.orgZoneInfo.isArchived);
            }
            return filteredDrawZones;
        });

        const search = async (value: boolean) => {
            alsoSearchArchivedZones.value = value;
            await _loadZones();

            // hack: force an update of the kl-draw-zone-map component
            // because vue-custom-scrollbar doesn't properly update the scrollbar
            klbZonesKey.value = klbZonesKey.value + '*';
            // alternative for hack (how it is supposed to work)
            // await componentRef<InstanceType<typeof KlDrawZoneMap>>(klbZonesMap).value.updateInputGeometries(drawZones.value);
        };

        const _loadZones = async () => {
            zonesFetching.value = true;
            const unaZones = await proxy.organisation_LoadUnaZonesForOrganisation(props.organisation.organisationId, alsoSearchArchivedZones.value);
            zones.value = unaZones.result
                .filter((item) => item.zoneId !== route.params.removedItem)
                .map((zone): IUnaZone => ({
                    zoneId: zone.zoneId,
                    zoneName: zone.zoneName,
                    namespace: zone.namespace,
                    isKlimZone: zone.isKlimZone,
                    unaId: zone.unaId,
                    zoneGeometryActivationDate: zone.zoneGeometryActivationDate,
                    zoneGeometry: zone.zoneGeometry,
                    isArchived: zone.isArchived,
                    link: {
                        route: zone.isKlimZone ? routeUnaKlimZone : routeUnaZone,
                        params: {
                            organisationId: props.organisation.organisationId,
                            zoneId: zone.zoneId,
                        },
                    },
                }));

            zonesFetching.value = false;
        }

        const _updateConfirmationInUnaZoneSettings = async () => {
            isSending.value = true;

            const input = KlipApi.UpdateConfirmationInUnaZoneSettingsInput.fromJS({
                unaId: props.organisation.unaData.unaId,
                confirmation: confirmation.value,
            });

            await proxy.operations_UpdateConfirmationInUnaZoneSettings(input);

            form.resetForm({ values: form.values });

            isSending.value = false;
        }

        onMounted(async () => {
            await _loadZones();
        });

        const _onInvalidSubmit = (submittedValues) => {
            console.log('_onInvalidSubmit', submittedValues);
        }

        const onSubmit = form.handleSubmit(_updateConfirmationInUnaZoneSettings, _onInvalidSubmit);

        return {
            confirmationField,
            confirmation,

            klbZonesMap,
            klbZonesKey,

            displayName,
            isSending,
            confirmationExampleUri,
            subjectSigningCertificate,
            zones,
            zonesFetching,
            search,

            drawZones,
            alsoSearchArchivedZones,

            onSubmit,
            disableSubmit,
        };
    }
});

