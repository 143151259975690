<div id="app" :class="routeClass">
    <div class="global-header-wrapper">
        <div class="global-header"></div>
    </div>
    <functional-header/>
    <vl-u-spacer />
    <kl-application-messages v-if="userLoggedOn"></kl-application-messages>
    <div class="content">
        <div>
            <div class="global-errors" v-if="hasErrors">
                <vl-region>
                    <kl-application-errors />
                </vl-region>
            </div>
            <router-view mode="out-in" v-if="!hasErrors" />
        </div>
    </div>
    <vl-toaster mod-bottom-right>
        <vl-alert
            v-for="alert in alerts"
            :key="alert.id"
            :icon="alert.icon"
            :title="alert.title"
            :closable="alert.closable"
            :mod-success="alert.type === 'mod-success'"
            :mod-warning="alert.type === 'mod-warning'"
            :mod-error="alert.type === 'mod-error'"
            @close="close(alert.id)">
                <div v-html="alert.message"></div>
        </vl-alert>
    </vl-toaster>
    <vue-progress-bar></vue-progress-bar>
    <div class="global-footer-wrapper">
        <div class="global-footer"></div>
    </div>
</div>
