import * as KlipApi from '@/api/klip-api.proxy';
import { useUserStore } from './UserDataModule';
import { useErrorStore } from '../../../stores/error-store';
import {klipApiProxy} from '@/plugins/proxy-client';
import {defineStore} from 'pinia';
import {computed, ref, Ref} from 'vue';
import {ShoppingCartStatus} from "@/api/klip-api.proxy";
import {useRouter} from '@/router/router-vue3';


export const useShoppingCartStore = defineStore('shopping-cart-store', () => {

    const router = useRouter();

    const items: Ref<any> = ref([]);
    const totalAmount: Ref<number> = ref(0);
    const totalPriceInclVatFormatted: Ref<string> = ref('');
    const totalPriceExclVatFormatted: Ref<string> = ref('');
    const totalVatFormatted: Ref<string> = ref('');
    const vatPercentage: Ref<number | undefined> = ref();
    const idToBeRemoved: Ref<string> = ref('');
    const status: Ref<string> = ref('');
    const paymentLink: Ref<string> = ref('');


    const numberOfItems = computed((): number => {
        return items.value.length;
    });

    const shoppingCartItems = computed((): any[] => {
        return items.value;
    });

    const shoppingCartTotalPriceExclVat = computed((): string => {
        return `€ ${totalPriceExclVatFormatted.value}`;
    });

    const shoppingCartTotalPriceInclVat = computed((): string => {
        return `€ ${totalPriceInclVatFormatted.value}`;
    });

    const shoppingCartTotalVat = computed((): string => {
        return `€ ${totalVatFormatted.value}`;
    });

    const shoppingVatPercentage = computed((): string => {
        return `${vatPercentage.value}%`;
    });

    const getTotalAmount = computed((): number => {
        return totalAmount.value;
    });

    const isShoppingCartActive = computed((): boolean => {
        if (!!useUserStore().user && useUserStore().user.roles) {
            return useUserStore().user.roles.includes('mri') && useUserStore().user.isShoppingCartVisible;
        }
        return false;
    })

    const shoppingCartStatus = computed((): string => {
        return status.value;
    });

    const shoppingCartPaymentLink = computed((): string => {
        return paymentLink.value;
    });

    const numberOfMapRequests = computed((): number => {
        return items.value.filter((item: any) => item.type === 'PA').length;
    });

    const hasMapRequestExtras = computed((): boolean => {
        return items.value.filter((item: any) => item.isIndependent === true).length > 0;
    });

    const hasMapRequestsWithImkl = computed((): boolean => {
        return items.value.filter((item: any) => item.isIndependent === false).length > 0;
    });

    const hasInvalidItems = computed((): boolean => {
        return items.value.filter((item: any) => item.error === true).length > 0;
    });


    const _setShoppingCartItems = (value: any) => {
        items.value = value ? value.sort((a: any, b: any) => Date.parse(a.dateReceived) - Date.parse(b.dateReceived)) : null;
    }

    const _addExtraShoppingCartItems = (value: KlipApi.ShoppingCartMapRequestExtra[]) => {
        const createImklItem = (item: any, isIndependent: boolean) => {
            return {
                type: 'IMKL',
                id: item.id,
                delete: true,
                reference: `IMKL: ${item.reference}`,
                priceLabel: `€ ${useUserStore().applyVat ? item.priceFormatted : item.price}`,
                mapRequestId: item.mapRequestId,
                isIndependent,
                isAlreadyPaid: item.isAlreadyPaid,
                error: item.isAlreadyPaid,
                organisation: item.organisation,
            };
        };

        value.forEach(e => {
            const index = items.value.findIndex((m: any) => m.id === e.mapRequestId);
            if (index !== -1) {
                items.value.splice(index + 1, 0, createImklItem(e, false));
            } else {
                items.value.push(createImklItem(e, true));
            }
        });
    }

    const _setTotalAmount = (value: number) => {
        totalAmount.value = value;
    }

    const _setTotalPriceInclVatFormatted = (value: string) => {
        totalPriceInclVatFormatted.value = value;
    }

    const _setTotalPriceExclVatFormatted = (value: string) => {
        totalPriceExclVatFormatted.value = value;
    }

    const _setTotalVatFormatted = (value: string) => {
        totalVatFormatted.value = value;
    }

    const _setVatPercentage = (value: number | undefined) => {
        vatPercentage.value = value;
    }

    const _updateShoppingCartMutation = (newStatus: ShoppingCartStatus) => {
        status.value = newStatus.status;
        paymentLink.value = newStatus.paymentLink;
    }


    const setIdToBeRemoved = (value: string) => {
        idToBeRemoved.value = value;
    }



    const updateShoppingCart = async () => {
        if (isShoppingCartActive.value) {
            const shoppingCartStatusResponse = await klipApiProxy.payment_GetShoppingCartStatus();
            _updateShoppingCartMutation(shoppingCartStatusResponse.result);
            if (shoppingCartStatusResponse.result.status === 'created' || shoppingCartStatusResponse.result.status === 'submitted') {
                try {
                    useErrorStore().setBypassError(['405']);
                    const shoppingCartViewResponse = await klipApiProxy.payment_GetShoppingCartView();
                    _setShoppingCartItems(shoppingCartViewResponse.result.mapRequests.map(m => {
                        return {
                            type: 'PA',
                            dateReceived: m.dateReceived,
                            id: m.id,
                            isExpired: m.isExpired,
                            price: m.price,
                            reference: `Planaanvraag: ${m.reference}`,
                            priceLabel: `€ ${useUserStore().applyVat ? m.priceFormatted : m.price}`,
                            delete: true,
                            error: m.isExpired,
                            organisation: m.organisation,
                        };
                    }));

                    if (shoppingCartViewResponse.result.mapRequestExtras && shoppingCartViewResponse.result.mapRequestExtras.length > 0) {
                        _addExtraShoppingCartItems(shoppingCartViewResponse.result.mapRequestExtras);
                    }
                    _setTotalAmount(shoppingCartViewResponse.result.totalPriceExclVat);
                    _setTotalPriceInclVatFormatted(shoppingCartViewResponse.result.totalPriceInclVatFormatted);
                    _setTotalPriceExclVatFormatted(shoppingCartViewResponse.result.totalPriceExclVatFormatted);
                    _setTotalVatFormatted(shoppingCartViewResponse.result.totalVatFormatted);
                    _setVatPercentage(shoppingCartViewResponse.result.vatPercentage);
                } catch (error: any) {
                    if (error.response && error.response.status && error.response.status === 405) {
                        useUserStore().setShoppingCartInvisible();
                        _setShoppingCartItems(null);
                        _setTotalAmount(0);
                        useErrorStore().resetBypassError();
                    } else {
                        throw error;
                    }
                }
            }
        }
    }

    const removeItemFromShoppingCart = async () => {
        if (idToBeRemoved.value && idToBeRemoved.value !== '') {
            const item = items.value.find((i: any) => i.id === idToBeRemoved.value);
            let response;
            if (item.type === 'PA') {
                response = await klipApiProxy.payment_DeleteShoppingCartMapRequest(idToBeRemoved.value);
            } else {
                response = await klipApiProxy.payment_DeleteShoppingCartMapRequestExtra(parseInt(idToBeRemoved.value));
            }
            if (response.statusCode === 200) {
                updateShoppingCart();
                setIdToBeRemoved('');

                if (!isShoppingCartActive.value) {
                    router.push({ name: 'mri' });
                }
            }
        }
    }

    return {
        numberOfItems,
        shoppingCartItems,
        shoppingCartTotalPriceExclVat,
        shoppingCartTotalPriceInclVat,
        shoppingCartTotalVat,
        shoppingVatPercentage,
        getTotalAmount,
        isShoppingCartActive,
        shoppingCartStatus,
        shoppingCartPaymentLink,
        numberOfMapRequests,
        hasMapRequestExtras,
        hasMapRequestsWithImkl,
        hasInvalidItems,
        setIdToBeRemoved,
        updateShoppingCart,
        removeItemFromShoppingCart
    }
});
