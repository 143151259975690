<vl-form-grid>
  <vl-column>
    <kl-upload
      id="zone-upload"
      ref="zoneUpload"
      name="zone-upload"
      :config="config"
      :auto-process="true"
      @upload-processing="onProcessing"
      @upload-file-added="fileAdded"
      @upload-files-added="filesAdded"
      mod-collapsed
      :mod-header="false"
      :mod-hide-guide-lines="modHideGuideLines"
      :max-files="maxFiles"
    />
    <vl-link v-if="!modHideGuideLines && moreInfoLink" mod-icon-before icon="external" :to="moreInfoLink" target="_blank" style="margin-top:15px;">
      Meer info
    </vl-link>
  </vl-column>
</vl-form-grid>
