import {App} from 'vue';
import kinks from '@turf/kinks';
import * as KlipApi from '@/api/klip-api.proxy';
import { defineRule } from 'vee-validate';
import {required, numeric, max_value, email, max, regex, min_value} from '@vee-validate/rules';
import { IDrawZone } from '@/app/shared/components/kl-draw-zone-map/components/kl-draw-zone-sidebar/kl-draw-zone-sidebar';
import dayjs from 'dayjs';
import {useKlipApiProxy} from '@/plugins/proxy-client';
import WKT from 'ol/format/WKT';
import Polygon from 'ol/geom/Polygon';
import MultiPolygon from 'ol/geom/MultiPolygon';
import DateUtil from '@/app/shared/helpers/date-util';
import {find, isEmpty} from 'lodash-es';

// TEMP: VUE3-MIGRATION-FIX'
// TEMP SOLUTION te ensure we have the correct error message labels
// ref: https://vee-validate.logaretm.com/v4/guide/i18n/
const defineVeeValidateRules = () => {
    defineRule('required', (value: any, params: [string | number], ctx) => {
        if (required(value) === true) {
            return true;
        }
        return `${ctx.field} is verplicht`;
    });

    defineRule('numeric', (value: any, params: [string | number], ctx) => {
        if (numeric(value) === true) {
            return true;
        }
        return `${ctx.field} mag alleen nummers bevatten`;
    });

    defineRule('max', (value: any, params: [string | number], ctx) => {
        if (max(value, params) === true) {
            return true;
        }
        return `${ctx.field} mag niet groter zijn dan ${params} karakters`;
    });

    defineRule('max_value', (value: any, params: [string | number], ctx) => {
        if (max_value(value, params) === true) {
            return true;
        }
        return `${ctx.field} mag maximaal ${params} zijn`;
    });

    defineRule('min_value', (value: any, params: [string | number], ctx) => {
        if (min_value(value, params) === true) {
            return true;
        }
        return `${ctx.field} moet minimaal ${params} zijn`;
    });

    defineRule('email', (value: any, params: [string | number], ctx) => {
        if (email(value) === true) {
            return true;
        }
        return `${ctx.field} moet een geldig e-mailadres zijn`;
    });

    defineRule('regex', (value: any, params: [string | RegExp], ctx) => {
        if (regex(value, params) === true) {
            return true;
        }
        return `${ctx.field} heeft een ongeldig formaat`;
    });
}

const defineKlipRules = () => {
    defineRule('displayNameIsUnique', async (value: string, [initialName]: string[]) => {
        if (initialName === value) {
            return true;
        }
        const exist = await useKlipApiProxy().unaSettings_DisplayNameExists(value);
        if (!exist.result) {
            return true;
        }
        return `Er bestaat al een organisatie met deze naam.`;
    });

    defineRule('uniqueNamespace', async (value: string, [id]: string[]) => {
        const exist = await useKlipApiProxy().unaSettings_NamespaceExists(value, id);
        if (!exist.result) {
            return true;
        }
        return `Er bestaat al een zone met deze namespace.`;
    });

    defineRule('zoneNameIsUnique', async (value: string, [zoneId, initialName]: string[]) => {
        if (initialName === value) {
            return true;
        }
        const exist = await useKlipApiProxy().unaSettings_ZoneNameExist(value, zoneId);
        if (!exist.result) {
            return true;
        }
        return `Er bestaat al een zone met deze naam.`;
    });

    defineRule('phone_with_112', (value: string) => {
        if (isEmpty(value)) {
            return true;
        }
        const pattern = /(^|,)(^([\\+]?([0-9 \\(\\)\\/\\-\\.]){9,20})|^112)$/;
        const phoneString = value.toString();

        if (pattern.test(phoneString)) {
            return true;
        }

        return `Dit is geen geldig telefoonnummer.`;
    });

    defineRule('phone', (value: string) => {
        if (isEmpty(value)) {
            return true;
        }
        const pattern = /(^|,)(^([\\+]?([0-9 \\(\\)\\/\\-\\.]){9,20}))$/;
        const phoneString = value.toString();

        if (pattern.test(phoneString)) {
            return true;
        }

        return `Dit is geen geldig telefoonnummer.`;
    });

    defineRule('max_40_days_ahead', (value: string[]) => {
        const ok = !((value[0] ? DateUtil.businessDaysBetween(dayjs().startOf('day'), dayjs(value[0])) : 0) > 40);
        if (ok) {
            return true;
        }

        return `De startdatum mag maximaal 40 werkdagen in de toekomst liggen.`;
    });

    defineRule('validKlipEmail', (value: string) => {
        const pattern = /^[\w.% '!#$&*/=?^_`{|}~+-]+@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,10}$/;
        if (pattern.test(value) && value.length <= 320) {
            return true;
        }

        return `Dit is geen geldig email adres.`;
    });

    defineRule('validKlipEmails', (value: string) => {
        if (!value) {
            return 'Bevat geen email adressen.';
        }

        const pattern = /^[\w.% '!#$&*/=?^_`{|}~+-]+@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,10}$/;

        const emailAddresses = value.split(";").map(m => m.trim()).filter(e => !!e);
        if (emailAddresses.every(e => pattern.test(e) && e.length <= 320)) {
            return true;
        }

        return `Bevat een ongeldig email adres.`;
    });

    defineRule('tags', (values: string[]) => {
        const ok = !(find(values, (tag) => tag.includes(',')) || find(values, (tag) => tag.length < 3 || tag.length > 100));
        if (ok) {
            return true;
        }

        return `Een tag mag geen komma bevatten en moet tussen 3 en 100 karakters lang zijn.`;
    });

    defineRule('tag', (value: string) => {
        const ok = !(value.includes(',') || value.length < 3 || value.length > 100);
        if (ok) {
            return true;
        }

        return `Een tag mag geen komma bevatten en moet tussen 3 en 100 karakters lang zijn.`;
    });

    defineRule('zone_partially_in_flanders', async (value: string) => {
        const input = KlipApi.IsGeometryIntersectingFlandersInput.fromJS({
            geometry: JSON.stringify(value),
        });
        const validationResult = await useKlipApiProxy().validation_IsGeometryIntersectingFlanders(input);
        if (validationResult.result) {
            return true;
        }

        return 'De zone moet minstens een deel in Vlaanderen liggen.';
    });

    defineRule('zone_in_buffered_flanders', async (value: string) => {
        const input = KlipApi.IsGeometryWithinBufferedFlandersInput.fromJS({
            geometry: JSON.stringify(value),
        });
        const validationResult = await useKlipApiProxy().validation_IsGeometryWithinBufferedFlanders(input);
        if (validationResult.result) {
            return true;
        }

        return 'De zone mag niet meer dan 50m buiten Vlaanderen liggen.';
    });

    defineRule('is_polygon', (value: IDrawZone) => {
        if (!!value.area) {
            return true;
        }
        return `De zone moet een polygoon zijn. Punten en lijnen zijn niet toegelaten.`;
    });

    defineRule('not_self_intersecting_polygon', (value: IDrawZone) => {
        if (!kinks(value as any).features.length) {
            return true;
        }
        return `De zone is niet geldig: de zijden mogen elkaar niet snijden.`;
    });

    defineRule('zone_smaller_than', (value: IDrawZone, [min, message]: string[]) => {
        if (value.area < Number(min)) {
            return true;
        }
        return `De oppervlakte van de zone moet kleiner zijn dan ${message} m².`;
    });

    defineRule('zone_shorter_than', (value: IDrawZone, [min, message]: string[]) => {
        if (value.length < Number(min)) {
            return true;
        }
        return `De omtrek van de zone moet kleiner zijn dan ${message} meter.`;
    });

    defineRule('zone_geometry_smaller_than_x_bytes', async (value: IDrawZone, [max]: string[]) => {
        let geometry = null;

        if (value.type === 'Polygon') {
            geometry = new Polygon(value.coordinates);
        }
        if (value.type === 'MultiPolygon') {
            geometry = new MultiPolygon(value.coordinates);
        }

        if (!geometry) {
            console.warn('zone_geometry_smaller_than_x_bytes without valid geometry');
            return false;
        }

        const wkt: string = new WKT().writeGeometry(geometry);
        // const size = new Blob([wkt]).size; // = would be correct size

        // current server-side logic uses c# unicode-16 string
        // > #bytes = wkt.length * 2
        const size = wkt.length * 2;


        if (size < Number(max)) {
            return true;
        }

        return `De zone bevat te veel coördinaten.`;
    });

    defineRule('vatNumberValid', (input: string) => {

        const errorMessage = 'Het opgegeven btw-nummer is ongeldig. Contacteer accounting@athumi.eu indien u toch facturen op dit btw-nummer wenst.';

        // add 'required' rule when this is not valid
        if (isEmpty(input)) {
            return true;
        }
        // if(isCitizen && (!input || input.length === 0)){
        //     return true;
        // }

        const validCountry = [
            'AT',
            'BE',
            'BG',
            'CY',
            'CZ',
            'DE',
            'DK',
            'EE',
            'ES',
            'FI',
            'FR',
            'GB',
            'EL',
            'HR',
            'HU',
            'IE',
            'IT',
            'LT',
            'LU',
            'LV',
            'MT',
            'NL',
            'PL',
            'PT',
            'RO',
            'SE',
            'SI',
            'SK'
        ];

        if (!input || input.length < 4) {
            return errorMessage;
        }


        if (!(input.match(/^[a-zA-Z]{2}.*/))){
            return errorMessage;
        }

        const country = input.substring(0,2);
        if (!validCountry.includes(country.toUpperCase())){
            return errorMessage;
        }

        if (country.toUpperCase() !== 'BE') {
            return true;
        }

        const inputNumber = parseInt(input.substring(2,input.length));
        const inputChecksum = parseInt(input.substring(input.length-2,input.length));

        if(inputChecksum === Number.NaN || inputNumber === Number.NaN){
            return errorMessage;
        }

        const result = 97 - (((inputNumber - (inputNumber % 100)) / 100) - (((inputNumber - (inputNumber % 100)) / 100 / 97 - (inputNumber - (inputNumber % 100)) / 100 / 97 % 1) * 97));
        const result97 = (result === 0) ? 97 : result;
        if (inputChecksum === result97) {
            return true;
        }

        return errorMessage;
    });
}

export default {
    install(app: App): void {
        defineVeeValidateRules();
        defineKlipRules();
    },
};
