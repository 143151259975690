<vl-region>
    <kl-maprequest-una-table>
        <template v-slot:header>
            <kl-una-message :title="unaMessage.title" :content="unaMessage.content"
                            v-if="unaMessage"/>
        </template>
        <kl-accordion
                v-if="unaZones"
                v-for="(unaZone, index) in unaZones"
                :key="unaZone.zoneId"
                :title="accordionTitle(unaZone)"
                :subtitle="unaZone.extraInformation">
            <kl-contact-card :title="unaZone.contactName" :phone="unaZone.contactTelephone"
                             :email="unaZone.contactEmail"
                             :emergency-phone="unaZone.alarmCentralTelephone" mod-email-clickable>
            </kl-contact-card>
        </kl-accordion>
    </kl-maprequest-una-table>
</vl-region>