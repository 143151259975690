const KlUBorder = {
    beforeMount: (el: HTMLElement, binding: { modifiers?: object, arg?: string }) => {
        const baseClass = 'kl-u-border';

        const modifiers = Object.keys(binding.modifiers);
        for (const modifier of modifiers) {
            el.classList.add(`${baseClass}-${binding.arg}--${modifier}`);
        }
        if (!modifiers.length) {
            el.classList.add(`${baseClass}-${binding.arg ? binding.arg : 'left'}`);
        }
    },
};

export default KlUBorder;
