import { defineComponent } from 'vue';
import { IAlertNotification } from '@/api/klip-api.proxy';

export default defineComponent({
    name: 'KlHeaderMessage',
    emits: ['message-closed'],
    props: {
        messages: {
            type: Array<IAlertNotification>,
            default: "",
            required: true
        }
    },
    setup(props, { emit }) {
        const closeMessage = async (messageId: string) => {
            emit('message-closed', messageId);
        };

        return {
            closeMessage
        };
    }
});
