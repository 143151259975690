import {camelCase, upperFirst} from 'lodash-es';
import {App, Plugin} from 'vue';

export default {
    install(app: App) {

        const componentFiles = import.meta.glob(
            // '@/app/**/kl-*.vue',
            //['@/app/shared/**/kl-*.vue', '!**/kl-draw-zone-map/**/*.vue'],
            ['@/app/**/kl-*.vue', '!**/kl-draw-zone-map/**/*.vue'],
            { eager: true }
        );

        Object.entries(componentFiles).forEach(([path, componentConfig]) => {
            const componentName = upperFirst(
                camelCase(path.split('/').pop().replace(/\.\w+$/, ''))
            );

            app.component(
                `${componentName}`, (componentConfig as any).default
            );
        });
    },
} as Plugin;
