import { computed, defineComponent, inject, onBeforeMount, onMounted, ref } from 'vue';
import * as KlipApi from '@/api/klip-api.proxy';
import { pdaIndex } from '@/app/pda/pda-routes';
import { useRoute, useRouter } from '@/router/router-vue3';
import QueryFilter from '../../helpers/queryFilter';
import {IZoneDetails} from '@/api/klip-api.proxy';
import {useKlipApiProxy} from '@/plugins/proxy-client';
import {useMetaStore} from '@/stores/meta-store';
import {RouteRecordRaw} from 'vue-router';
import {safeRouteParamToString} from '@/router/route-utils';

export default defineComponent({
    name: 'KlPdaZone',
    props: {
        organisationId: {
            type: String,
            default: null,
            required: false
        },
        parentOrganisationId: {
            type: String,
            default: null,
            required: true
        },
        maprequestDetailRoute: {
            type: Object as () => RouteRecordRaw,
            default: null,
            required: true
        },
        zoneDetails: {
            type: Object as () => IZoneDetails,
            default: null,
        }
    },
    setup(props) {
        const meta = useMetaStore();
        const proxy = useKlipApiProxy();
        const route = useRoute();
        const router = useRouter();

        const zoneId = ref<string>('');
        const zone = ref<KlipApi.ZoneDetails>(null);

        const tabToLoad = ref<number>(-1);
        const unaZonesTabId = 'klb';
        const maprequestsTabId = 'maprequests'
        const activeTab = ref<string>(unaZonesTabId);
        const tabs = [unaZonesTabId, maprequestsTabId];

        const organisationIdValue = ref<string>(props.organisationId);
        const parentOrganisationIdValue = ref<string>(props.parentOrganisationId);

        const queryFilter = new QueryFilter();

        const zoneDetailView = ref<KlipApi.ZoneDetailView>(null);

        const routePdaIndex = computed((): RouteRecordRaw => {
            return pdaIndex;
        });

        const routePdaMapRequest = computed((): RouteRecordRaw => {
            return props.maprequestDetailRoute;
        });

        onBeforeMount(async () => {
            meta.setPageTitle('Zone detail');
        });

        onMounted(async () => {
            zoneId.value = safeRouteParamToString(route.params.zoneId);

            activeTab.value = unaZonesTabId;
            tabToLoad.value = tabs.indexOf(unaZonesTabId);

            await _fetchZone(zoneId.value);
        });

        const _fetchZone = async (zoneId: string) => {
            const response = await proxy.pda_GetZoneDetailView(zoneId);

            if (!response.result) {
                return;
            }

            if (!response.result.zoneDetails) {
                await router.replace(routePdaIndex.value);
            } else {
                zone.value = response.result.zoneDetails;
                meta.setPageTitle(zone.value.name);

                zoneDetailView.value = response.result;
            }
        }

        const tabChange = (tab: { id?: string }) => {
            activeTab.value = tab.id;

            queryFilter.removeQuery(route);
        }

        const unaZonesLoaded = computed(() => {
            return activeTab.value == unaZonesTabId;
        });

        const maprequestsLoaded = computed(() => {
            return activeTab.value == maprequestsTabId
        });

        return {
            zone,
            zoneId,
            routePdaIndex,
            routePdaMapRequest,
            tabChange,
            tabToLoad,
            unaZonesTabId,
            maprequestsTabId,
            unaZonesLoaded,
            maprequestsLoaded,
            zoneDetailView,
            organisationIdValue,
            parentOrganisationIdValue,
        };
    }
})
