<div v-vl-align:center v-if="viewModelLoading">
    <vl-loader message="De gegevens worden geladen. Even geduld, a.u.b." />
</div>

<div v-else class="admin-maprequest-index">
        <vl-form-grid mod-stacked mod-v-center>
            <vl-column>
                <kl-search-maprequests v-if="mapRequestsSearchFields" ref="searchMaprequests" 
                    @search="search"
                    :search-fields="mapRequestsSearchFields"
                    :search-options="mapRequestsSearchOptions"
                    :default-search-option="mapRequestDefaultSearchOption"
                    :default-filter="defaultFilter">
                    <template v-slot:movemaprequest v-if="mapRequestsMeta.totalRows > 0 && isAdmin">
                        <vl-checkbox v-model="moveMapRequests" @input="moveMapRequestsChanged">Ik wil planaanvragen verplaatsen</vl-checkbox>
                        &nbsp;&nbsp; 
                        <vl-button @submit.prevent v-if="moveMapRequests" v-vl-modal-toggle="'move-map-request-modal'" :mod-disabled="hasCheckedMapRequest">Verplaats planaanvragen</vl-button>
                        <kl-move-map-requests @move="onMoveMapRequests" />
                    </template>
                </kl-search-maprequests>
            </vl-column>
            
            <vl-column>
                <ippro-datatable id="mri-operations-table" 
                    :meta="mapRequestsMeta" 
                    :dataset="mapRequests" 
                    :columns="mapRequestsColumns"
                    :mod-checkable-rows="moveMapRequestChecked" 
                    :fetching="mapRequestsFetching"
                    @checkbox-change="checkboxChanged"
                    @pager-clicked="pagerClicked" 
                    @column-clicked="columnClicked" 
                    @row-clicked="rowClicked" mod-clickable-rows mod-zebra mod-pagination-on-top>
                        <template v-slot:topactions>
                        <vl-link v-vl-modal-toggle="'move-map-request-modal'" v-if="moveMapRequests && mapRequestsMeta.totalRows > 0">
                            <vl-icon icon="move-left-right" class="vl-link__icon vl-link__icon--before" />Verplaats alle {{mapRequestsMeta.totalRows}} planaanvragen.
                        </vl-link>
                    </template>
                    <template v-slot:actions>
                        <vl-link v-vl-modal-toggle="'move-map-request-modal'" v-if="moveMapRequests && mapRequestsMeta.totalRows > 0">
                            <vl-icon icon="move-left-right" class="vl-link__icon vl-link__icon--before" />Verplaats alle {{mapRequestsMeta.totalRows}} planaanvragen.
                        </vl-link>
                    </template>
                    <template v-slot:noresults>
                        <div v-vl-align:center>
                            <p>Uw zoekopdracht heeft geen resultaten opgeleverd.</p>
                            <vl-button @click="clearSearch" class="vl-button--link">Zoekvelden wissen</vl-button>
                        </div>
                    </template>
                </ippro-datatable>
            </vl-column>
        </vl-form-grid>  
</div>