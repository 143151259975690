<div>
    <!-- loading -->
    <vl-region v-if="isLoading">
        <vl-layout>
            <div v-vl-align:center>
                <vl-loader message="De gegevens worden geladen. Even geduld, a.u.b." />
            </div>
        </vl-layout>
    </vl-region>
    <!-- ./loading -->
    <vl-region v-else>
<!--        <vl-form-validation-observer  ref="editZoneValidationObserver" v-slot="{ handleSubmit, invalid, pristine }">-->
            <form class="vl-form" @submit="onSubmit">
                <vl-region mod-medium>
                    <vl-layout>
                        <!-- heading -->
                        <vl-form-grid mod-v-center mod-stacked>
                            <vl-column width="6" width-xs="12">
                                <vl-title v-bind:tag-name="'h1'">{{ title }} <small v-if="unaDisplayName"> - {{ unaDisplayName }}</small></vl-title>
                            </vl-column>
                            <vl-column width="6" width-xs="12" v-if="editMode">
                                <vl-action-group class="vl-action-group--align-right vl-action-group--align-left--xs vl-action-group--primary">
                                    <vl-button v-vl-modal-toggle="'remove-zone'" mod-error mod-large>Verwijder zone</vl-button>
                                </vl-action-group>
                            </vl-column>
                        </vl-form-grid>
                        <!-- ./heading -->
                    </vl-layout>
                </vl-region>

                <vl-region>
                    <vl-layout>
                        <!-- create-edit-form -->
                        <vl-form-grid mod-stacked>
                            <vl-column width-s="12" width-m="8" width-l="6">
                                <template>
                                    <kl-field-validation-wrapper :field="nameField" #default="{hasErrors}" mod-required>
                                        <vl-input-field name="naam zone"  id="name-zone" v-model="name" :mod-error="hasErrors" mod-block />
                                    </kl-field-validation-wrapper>
                                </template>
                            </vl-column>
                            <vl-column width-s="0" width-m="4" width-l="6" v-vl-visibility:hide.s></vl-column>
                            <vl-column width-s="12" width-m="8" width-l="6">
                                <kl-field-validation-wrapper :field="mailDestinationField" #default="{hasErrors}" mod-required>
                                    <vl-input-field name="e-mail planaanvragen" id="mail-destination"
                                        v-model="mailDestination" :mod-error="hasErrors" mod-block />
                                </kl-field-validation-wrapper>
                            </vl-column>
                            <vl-column width-s="0" width-m="4" width-l="6" v-vl-visibility:hide.s></vl-column>
                            <vl-column width-s="12" width-m="8" width-l="8">
                                <vl-form-message-label>Welke meldingen wil u ontvangen via e-mail?</vl-form-message-label>
                                <vl-typography>
                                    <p>Contacteer <vl-link :href="'mailto:' + helpdeskMail">{{ helpdeskMail }}</vl-link> als u planaanvragen via de services ophaalt en mails met nieuwe planaanvragen niet meer wenst te ontvangen. Als u een planaanvraag te laat bevestigt of beantwoordt, sturen we u sowieso een herinnering.</p>
                                </vl-typography>
                            </vl-column>
                            <vl-column width-s="12" width-m="8" width-l="8">
                                <vl-checkbox v-model="mailSettings.confirmation" mod-disabled>
                                    <span class="checkbox-label">Nieuwe planaanvraag te bevestigen</span>
                                    <vl-link :href="mailSettings.confirmationExampleUri" v-if="mailSettings.confirmationExampleUri" icon="file" target="_blank" mod-icon-before>voorbeeld</vl-link>
                                </vl-checkbox>
                            </vl-column>
                            <vl-column width-s="12" width-m="8" width-l="8">
                                <vl-checkbox v-model="mailSettings.reminder1DayToRespond">
                                    <span class="checkbox-label">Herinnering: nog 1 dag om te antwoorden</span>
                                    <vl-link :href="mailSettings.reminder1DayToRespondExampleUri" v-if="mailSettings.reminder1DayToRespondExampleUri" icon="file" target="_blank" mod-icon-before>voorbeeld</vl-link>
                                </vl-checkbox>
                            </vl-column>
                            <vl-column width-s="12" width-m="8" width-l="8">
                                <vl-checkbox v-model="mailSettings.uploadImklSuccess">
                                    <span class="checkbox-label">Opladen IMKL: geslaagd</span>
                                    <vl-link :href="mailSettings.uploadImklSuccessExampleUri" v-if="mailSettings.uploadImklSuccessExampleUri" icon="file" target="_blank" mod-icon-before>voorbeeld</vl-link>
                                </vl-checkbox>
                            </vl-column>
                            <vl-column width-s="12" width-m="8" width-l="8">
                                <vl-checkbox v-model="mailSettings.uploadImklFailed">
                                    <span class="checkbox-label">Opladen IMKL: gefaald</span>
                                    <vl-link :href="mailSettings.uploadImklFailedExampleUri" v-if="mailSettings.uploadImklFailedExampleUri" icon="file" target="_blank" mod-icon-before>voorbeeld</vl-link>
                                </vl-checkbox>
                            </vl-column>
                            <vl-column width-s="12" width-m="8" width-l="8">
                                <vl-checkbox v-model="mailSettings.respondedNotInvolved">
                                    <span class="checkbox-label">Bevestiging antwoord: geen kabels en leidingen</span>
                                    <vl-link :href="mailSettings.respondedNotInvolvedExampleUri" v-if="mailSettings.respondedNotInvolvedExampleUri" icon="file" target="_blank" mod-icon-before>voorbeeld</vl-link>
                                </vl-checkbox>
                            </vl-column>
                        </vl-form-grid>
                        <!-- ./create-edit-form -->
                    </vl-layout>
                </vl-region>

                <!-- geometry -->
                <vl-region>
                    <vl-layout>
                        <vl-title v-bind:tag-name="'h2'">Geometrie</vl-title>
                        <vl-form-grid mod-stacked>
                            <vl-column v-if="!isLoading && emptyGeometry && !addingNewGeometry">
                                <ippro-icon-text icon="alert-circle-filled" v-vl-text.error>Er moet minimaal 1 geometrie zijn.</ippro-icon-text>
                            </vl-column>
                            <vl-column v-for="(geometry, index) in geometries" :key="index + '/' + geometries.length + '-refresh-with-length'">
                                <kl-geometry-details-on-map
                                    :geometry="geometry"
                                    :all-geometries="geometries"
                                    :show-remove-button="!geometry.isActive"
                                    @remove-geometry="geometryBeforeDelete(geometry)"
                                />
                            </vl-column>
                            <vl-column v-if="addingNewGeometry">
                                <kl-create-geometry-on-map
                                    ref="createGeometryOnMap"
                                    @geometry-update="geometryUpdate"
                                    @geometry-upload-finished="geometryUploadFinished"
                                    @revert="cancelNewGeometry"
                                    :show-actions="editMode"
                                    :mod-revertable="canRevert"
                                >
                                    <template v-slot:content>
                                        <template v-if="editMode">
                                            <p>Een <strong>uitbreiding</strong> van de geometrie activeert u 45 werkdagen voor ze op terrein
                                                effectief van kracht wordt.</p>
                                            <p>Een <strong>inkrimping</strong> van de geometrie activeert u pas wanneer u effectief in de te
                                                schrappen gebieden geen
                                                leidingen meer beheert.</p>
                                            <p>Als u uw geometrie zowel wil uitbreiden als inkrimpen, doet u dat best in 2 keer.</p>
                                        </template>
                                        <template v-else>
                                            <p>Een <strong>nieuwe</strong> geometrie activeert u 45 werkdagen voor ze op terrein effectief van kracht wordt.</p>
                                        </template>
                                    </template>
                                </kl-create-geometry-on-map>
                            </vl-column>
                            <vl-column v-if="canAddGeometry">
                                <vl-u-spacer mod-small />
                                <vl-button class="vl-button--large vl-button--ghost" icon="plus" @click="addNewGeometry" mod-icon-before>
                                    Geometrie toevoegen
                                </vl-button>
                            </vl-column>
                        </vl-form-grid>
                    </vl-layout>
                </vl-region>
                <!-- ./geometry -->

                <!-- namespace -->
                <vl-region>
                    <vl-layout>
                        <vl-form-grid mod-stacked>
                            <vl-column>
                                <vl-title v-bind:tag-name="'h2'">Namespace</vl-title>
                                <vl-typography>
                                        <p>Om naamgevingsconflicten te vermijden bij het samenvoegen van IMKL-antwoorden, heeft elke zone
                                    een unieke namespace nodig. <vl-link :href="namespaceUrl" target="_blank">Lees meer over namespaces</vl-link>.</p>
                                    </vl-typography>
                            </vl-column>
                            <vl-column width-s="12" width-m="8" width-l="6">
                                <kl-field-validation-wrapper :field="namespaceField" #default="{hasErrors}" mod-required
                                    annotation="Minimum 5 en maximaal 50 karakters. Enkel alfanumeriek (a-Z, 0-9). (Liggend) streepje is toegelaten. Geen spaties." mod-no-wrap-annotation>
                                    <vl-input-field name="namespace" id="namespace"
                                        v-model="namespace" :mod-error="hasErrors" mod-block />
                                </kl-field-validation-wrapper>
                            </vl-column>
                        </vl-form-grid>
                    </vl-layout>
                </vl-region>
                <!-- ./namespace -->

                <!-- contact -->
                <vl-region>
                    <vl-layout>
                        <vl-form-grid mod-stacked>
                            <vl-column>
                                <vl-title v-bind:tag-name="'h2'">Contactgegevens</vl-title>
                                <vl-typography>
                                    <p>Geef hier contactgegevens mee voor vragen over de planaanvraag. Deze gegevens worden getoond in
                                            het <vl-link :href="overlayUrl" target="_blank">overzicht van aanwezige KLB's</vl-link>.</p>
                                </vl-typography>
                            </vl-column>
                            <vl-column width-s="12" width-m="8" width-l="6">
                                <kl-field-validation-wrapper :field="contactNameField" #default="{hasErrors}" mod-required
                                    annotation="bv. Helpdesk of Jan Jansens">
                                    <vl-input-field  name="naam contact" id="contact-name"
                                        v-model="contact.name" :mod-error="hasErrors" mod-block />
                                </kl-field-validation-wrapper>
                            </vl-column>
                            <vl-column width-s="0" width-m="4" width-l="6" v-vl-visibility:hide.s></vl-column>
                            <vl-column width-s="12" width-m="8" width-l="4">
                                <kl-field-validation-wrapper :field="contactPhoneField" #default="{hasErrors}" mod-required>
                                    <vl-input-field  placeholder="+32" name="telefoon contact"
                                        id="contact-phone" v-model="contact.phone" :mod-error="hasErrors" mod-block />
                                </kl-field-validation-wrapper>
                            </vl-column>
                            <vl-column width-s="0" width-m="4" width-l="6" v-vl-visibility:hide.s></vl-column>
                            <vl-column width-s="12" width-m="8" width-l="6">
                                <kl-field-validation-wrapper :field="contactEmailField" #default="{hasErrors}" mod-required>
                                    <vl-input-field  name="email contact" id="contact-email"
                                        v-model="contact.email" :mod-error="hasErrors" mod-block />
                                </kl-field-validation-wrapper>
                            </vl-column>
                            <vl-column width-s="12" width-m="12" width-l="12">
                                <kl-field-validation-wrapper :field="contactMessageField" #default="{hasErrors}"
                                    annotation="Enkel te gebruiken indien u de planaanvrager iets belangrijks wil melden, zoals: 'Er moet steeds een medewerker van onze KLB ter plaatse komen.'">
                                    <vl-input-field name="boodschap planaanvrager" id="message-pa-request"
                                        v-model="contact.message" :mod-error="hasErrors" mod-block />
                                </kl-field-validation-wrapper>
                            </vl-column>
                            <vl-column width-s="12" width-m="8" width-l="4">
                                <vl-u-spacer />
                                <kl-field-validation-wrapper :field="contactPhoneEmergencyField" #default="{hasErrors}"
                                    annotation="Dit nummer wordt getoond in zowel het overzicht van aanwezige KLB's als in de contactgegegevens van elke leiding of elk leidingelement."
                                    mod-required mod-no-wrap-annotation>
                                    <vl-input-field placeholder="+32" name="telefoon noodgeval"
                                        id="contact-phone-emergency" v-model="contact.phoneEmergency" :mod-error="hasErrors"
                                        mod-block />
                                </kl-field-validation-wrapper>
                            </vl-column>
                        </vl-form-grid>
                    </vl-layout>
                </vl-region>
                <!-- ./contact -->

                <vl-region>
                    <vl-layout>
                        <vl-button type="submit" :mod-disabled="disableSubmit" :mod-loading="isSending" mod-large>
                            {{ submitButtonLabel }}
                        </vl-button>
                        <ippro-icon-text v-if="hasValidationErrors" icon="alert-circle" v-vl-text.error class="vl-u-spacer-left--medium">
                            Vul alle verplichte velden in en probeer opnieuw!
                        </ippro-icon-text>
                        <ippro-icon-text v-if="hasGeometryValidationErrors" icon="alert-circle" v-vl-text.error class="vl-u-spacer-left--medium">
                            Kijk uw geometrie na en probeer opnieuw!
                        </ippro-icon-text>
                    </vl-layout>
                </vl-region>

                <!-- delete-zone -->
                <vl-modal v-if="editMode" id="remove-zone" :closable="true">
                    <vl-title v-bind:tag-name="'h3'">Bent u zeker dat u de zone wil verwijderen?</vl-title>
                    <vl-typography>
                        Dit kan niet ongedaan worden gemaakt.<br> Planaanvragen die u in deze zone ontving, blijven wel
                        beschikbaar,
                    </vl-typography>
                    <vl-action-group>
                        <vl-button @click="deleteZone" mod-large :mod-loading="deletingZone"
                            :mod-disabled="deletingZone" :mod-error="!deletingZone">Ja, verwijder zone</vl-button>
                        <vl-button class="vl-button--link" v-vl-modal-toggle="'remove-zone'" icon="cross" mod-icon-before>Annuleer
                        </vl-button>
                    </vl-action-group>
                </vl-modal>
                <!-- ./delete-zone -->

                <!-- delete-geometry -->
                <vl-modal id="remove-geometry" :closable="true">
                    <vl-title v-bind:tag-name="'h3'">Bent u zeker dat u de geometrie wil verwijderen?</vl-title>
                    <vl-typography>
                        Dit kan niet ongedaan worden gemaakt.
                    </vl-typography>
                    <vl-action-group>
                        <vl-button @click="geometryDelete" v-vl-modal-toggle="'remove-geometry'" mod-large :mod-loading="deletingGeometry" :mod-disabled="deletingGeometry" :mod-error="!deletingGeometry">Ja, verwijder geometrie</vl-button>
                        <vl-button class="vl-button--link" v-vl-modal-toggle="'remove-geometry'" icon="cross" mod-icon-before>Annuleer</vl-button>
                    </vl-action-group>
                </vl-modal>
                <!-- ./delete-geometry -->
            </form>
<!--        </vl-form-validation-observer>-->
    </vl-region>
</div>
