<div>
    <vl-title :tag-name=" modAdmin ? 'h5' : 'h2'">Facturatiegegevens</vl-title>

    <vl-u-spacer mod-medium/>

    <!-- error -->
    <vl-alert icon="alert-circle" title="Onbekende fout" title-tag-name="h3" v-if="error" mod-error>
        <p>Er heeft zich een onbekende fout voorgedaan. Gelieve later opnieuw te proberen.</p>
    </vl-alert>
    <!-- ./error -->

    <template v-if="hasData">
        <kl-contact-card :title="title" :subtitle="displayAttention" :address="!athumiGateWayUnavailable ? contactData : null" :email="email" mod-email-clickable />
        <vl-u-spacer mod-xsmall />
        <p v-if="reference && !modAdmin"><span v-vl-text.light>Eigen referentie:</span> {{ reference }}</p>
        <vl-u-spacer mod-xsmall />
        <ippro-icon-text v-if="athumiGateWayUnavailable" icon="alert-circle-filled" v-vl-text.bold.error>
            Uw facturatiegegevens konden niet geladen worden. Probeer later nog eens opnieuw.
        </ippro-icon-text>
        <vl-u-spacer mod-medium />
        <vl-typography v-if="isGroup">
            <p>Uw organisatie behoort tot een facturatiegroep. Contacteer <vl-link :href="'mailto:' + helpdeskMail">{{ helpdeskMail }}</vl-link> als u iets wil aanpassen aan uw facturatiegegevens.</p>
        </vl-typography>
        <template v-if="editMode">
            <vl-typography v-if="!modAdmin">
                <p>Contacteer <vl-link :href="'mailto:' + helpdeskMail">{{ helpdeskMail }}</vl-link> als u afwijkende facturatie-instellingen wenst of suborganisaties gegroepeerd wil laten factureren.</p>
            </vl-typography>
            <vl-u-spacer mod-medium />
            <vl-form-grid mod-stacked>
                <vl-column width-s="12" width-m="8" width-l="4">
                    <kl-field-validation-wrapper :field="emailField" mod-required
                                        annotation="Wordt gebruikt om de facturen naar op te sturen."
                                        #default="{hasErrors}">
                        <vl-input-field name="E-mail" v-model="emailField.value.value" :mod-error="hasErrors" mod-block />
                    </kl-field-validation-wrapper>
                </vl-column>
                <vl-column width-s="0" width-m="4" width-l="8" v-vl-visibility:hide.s />
                <vl-column width-s="12" width-m="8" width-l="4">
                    <kl-field-validation-wrapper :field="attentionField" #default="{hasErrors}">
                        <vl-input-field name="Ter attentie van" v-model="attentionField.value.value"
                                        :mod-error="hasErrors" mod-block />
                    </kl-field-validation-wrapper>
                </vl-column>
                <vl-column width-s="0" width-m="4" width-l="8" v-vl-visibility:hide.s />
                <vl-column width-s="12" width-m="8" width-l="4">
                    <kl-field-validation-wrapper :field="referenceField" #default="{hasErrors}"
                                        annotation="Optioneel veld op uw volgende factuur. Vul bv. een interne bestelbon- of PO-nummer in.">
                        <vl-input-field name="Eigen Referentie" v-model="referenceField.value.value" :mod-error="hasErrors"
                                        mod-block />
                    </kl-field-validation-wrapper>
                </vl-column>
            </vl-form-grid>
        </template>
    </template>
</div>
