import {klipIndex} from '@/router/klip-index';
import {RouteRecordRaw} from 'vue-router';

export const ImklTest: RouteRecordRaw = {
    path: '/imkltest',
    name: 'imkltest',
    component: () => import('./imkltest.vue'),
    meta: {
        breadcrumb: { title: 'IMKL-pakket testen', parent: klipIndex },
    },
};

export const ImklTestResult: RouteRecordRaw = {
    path: '/imkltest/:id',
    name: 'imkltestresult',
    component: () => import('./imkltestresult.vue'),
    meta: {
        breadcrumb: { title: 'IMKL-pakket testresultaat', parent: klipIndex },
        allowAnonymous: true,
    },
};

export const ImklTestViewer: RouteRecordRaw = {
    path: '/imkltest/:imklFileId/map',
    name: 'imkltestviewer',
    component: () => import('./../viewer/index.vue'),
    meta: {
        breadcrumb: { title: 'Leidingviewer', parent: klipIndex },
        allowAnonymous: true,
    },
};

export default [
    ImklTest,
    ImklTestResult,
    ImklTestViewer,
];
