import {App, Plugin} from 'vue';
import { KlipApiProxy } from '@/api/klip-api.proxy';
import { httpClient } from '@/app/shared/service/http-client';

const baseUrl = `${import.meta.env.VITE_BASE_URI}`;

export const klipApiProxy = new KlipApiProxy(baseUrl, httpClient);

export default {
    install(app: App) {
        app.config.globalProperties.$client = klipApiProxy;
    }
} as Plugin;

export function useKlipApiProxy(): KlipApiProxy {
    return klipApiProxy;
}
