<template>
    <form class="vl-form" @submit="onSubmit">
        <kl-invoice-settings @update:model-value="onUpdate" :invoice-data="invoiceData" :edit-mode="true" />
        <vl-u-spacer mod-medium />
        <vl-action-group>
            <vl-button type="submit" :mod-disabled="!form.meta.value.valid || !form.meta.value.dirty" :mod-loading="isSending" mod-large>
                Bewaar facturatiegegevens
            </vl-button>
            <vl-button @click.prevent="onCancel" icon="cross" class="vl-button--link" mod-icon-before>
                Annuleer
            </vl-button>
        </vl-action-group>
    </form>
</template>

<script setup lang="ts">

import {OrganisationInvoiceData2} from '@/api/klip-api.proxy';
import {IKlInvoiceSettingsData} from '@/app/shared/components/kl-invoice-settings/kl-invoice-settings';
import {useForm} from 'vee-validate';
import {onMounted, ref} from 'vue';

const props = defineProps({
    invoiceData: { type: Object as () => OrganisationInvoiceData2, default: () => ({}), required: true },
    isSending: { type: Boolean, default: false },
});

const emit = defineEmits(['update:modelValue', 'cancel']);

const form = useForm();

const formData = ref<IKlInvoiceSettingsData>({});

const onUpdate = (invoiceEditData: IKlInvoiceSettingsData) => {
    formData.value = invoiceEditData;
}

const onCancel = () => {
    emit('cancel');
}

const _submitForm = () => {
    emit('update:modelValue', formData.value);
}
const _onInvalidSubmit = () => {
    console.log('invalid submit')
}

const onSubmit = form.handleSubmit(_submitForm, _onInvalidSubmit);

onMounted(() => {
    formData.value = props.invoiceData;
});

</script>
