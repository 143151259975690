﻿<div>
    <vl-checkbox-tile value="mrr" id="registration-roles-mrr"
                   v-model="roles"
                   title="Plannen raadplegen"
                   info="U wil plannen van uw organisatie enkel kunnen bekijken." />
    <vl-checkbox-tile value="mri" id="registration-roles-mri"
                   v-model="roles"
                   title="Plannen aanvragen"
                   info="U wil plannen voor uw organisatie kunnen aanvragen en raadplegen." />
    <vl-checkbox-tile value="pda" id="registration-roles-pda"
                   v-model="roles"
                   title="Openbaar domeinbeheerder"
                   info="Planaanvragen (beperkte inhoud) en leidingbeheerders op uw openbaar domein raadplegen." />                   
    <vl-checkbox-tile value="una" id="registration-roles-una"
                   v-model="roles"
                   title="Kabel- en leidingbeheerder"
                   info="Mijn organisatie beheert ondergrondse kabels en/of leidingen." />                   
</div>