import {RouteLocation, RouteRecordRaw} from 'vue-router';

export const klipApi: RouteRecordRaw = {
    component: undefined,
    path: '/api',
    name: 'klipApi',
    meta: { allowAnonymous: true },

    beforeEnter: async (to: RouteLocation, from: RouteLocation, next) => {
        window.location.href = import.meta.env.VITE_PUBLIC_API_URL;
    }
};
