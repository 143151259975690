<div class="global-messages">
    <vl-layout>
        <vl-form-grid>
            <vl-column>
                <template v-if="isBeta">
                    <vl-alert :title="betaMessage.title"
                              :closable="betaMessage.isClosable">
                        <div v-html="betaMessage.message"></div>
                    </vl-alert>
                </template>

                <template v-if="messages">
                    <kl-header-message :messages="messages" @message-closed="closeMessage"/>
                </template>

                <vl-modal id="whatsnew-modal" closable :open="openWhatsNew">
                    <kl-whatsnew :title="whatsNew?.title" :message="whatsNew?.message"></kl-whatsnew>
                    <template v-slot:modal-footer>
                        <vl-action-group>
                            <vl-button v-vl-modal-toggle="'whatsnew-modal'">Verdergaan</vl-button>
                        </vl-action-group>
                    </template>
                </vl-modal>

                <vl-modal id="sessiontimeout-modal" :open="isSessionTimeoutVisible">
                    <vl-title tag-name="h3">Uw beveiligde sessie is verlopen</vl-title>
                    <vl-typography>Geen probleem, meld opnieuw aan om verder te werken.</vl-typography>
                    <vl-u-spacer mod-medium/>
                    <template>
                        <vl-action-group>
                            <vl-button tag-name="a" @click.prevent="login" class="vl-button vl-button--large"
                                       icon="text-redo" mod-icon-before>
                                Opnieuw aanmelden
                            </vl-button>
                        </vl-action-group>
                    </template>
                </vl-modal>

                <vl-modal id="impersonation-modal" :open="isRequestingForImpersonation">
                    <vl-title tag-name="h3">{{userImpersonation?.impersonatorName}} van onze helpdesk vraagt toestemming om zich als u aan te melden bij KLIP</vl-title>
                    <vl-typography>De Athumi Helpdesk wil zich als u en uw organisatie aanmelden bij KLIP om u verder te kunnen helpen.</vl-typography>
                    <vl-u-spacer mod-medium/>
                    <template v-slot:modal-footer>
                        <vl-action-group>
                            <vl-button @click.prevent="consentImpersonation" mod-large v-vl-modal-toggle="'impersonation-modal'">Ja, sta dit toe</vl-button>
                            <vl-button @click.prevent="revokeImpersonation" mod-error mod-large v-vl-modal-toggle="'impersonation-modal'">Nee, sta dit niet toe</vl-button>
                        </vl-action-group>
                    </template>
                </vl-modal>
            </vl-column>
        </vl-form-grid>
    </vl-layout>
</div>
