import {useUserStore} from '@/app/shared/state/UserDataModule';
import {EUserRole} from '@/app/shared/state/user-role';
import {RouteLocation, RouteRecordRaw} from 'vue-router';

export const klipIndex: RouteRecordRaw = {
    component: undefined,
    path: '/',
    name: 'klip',
    meta: { authorize: { roles: [] } },

    beforeEnter: async (to: RouteLocation, from: RouteLocation) => {
        console.log('klipIndex beforeEnter')

        // Redirect to / mri, /pda or /una
        // pages depending on the user role / scope
        const newRoute = useUserStore().scope;
        console.log('klipIndex newRoute:', newRoute);

        const isOrgManagerOnly = useUserStore().hasRole([EUserRole.org_manager]) &&
            !useUserStore().hasRole([EUserRole.una_operator]) &&
            !useUserStore().hasRole([EUserRole.una_reader]) &&
            !useUserStore().hasRole([EUserRole.una_manager]) &&
            !useUserStore().hasRole([EUserRole.pda_manager]) &&
            !useUserStore().hasRole([EUserRole.mri]) &&
            !useUserStore().hasRole([EUserRole.mrr]);

        console.log('klipIndex UserData.scopes', useUserStore().scopes);

        if (isOrgManagerOnly) {
            return { path: `/settings` };
        }

        if (newRoute) {
            if (from.name === newRoute) {

                console.log('TO INVESTIGATE: wtf???')

                // history.pushState(
                //     {},
                //     null,
                //     from.path,
                // );
                // return { path: `/${newRoute}` };
            } else {
                return { path: `/${newRoute}` };
            }
        }
    },
};
