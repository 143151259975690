<div id="admin-invoice-groups">
    <vl-region v-if="loading">
        <div v-vl-align:center>
            <vl-loader message="De gegevens worden geladen. Even geduld, a.u.b."/>
        </div>
    </vl-region>

    <template v-else>
        <vl-region>
            <vl-form-grid mod-stacked>
                <vl-column width="8">
                    <vl-title tag-name="h2">Facturatiegroepen</vl-title>
                </vl-column>
                <vl-column width="4">
                    <vl-action-group class="vl-action-group--align-right--l vl-action-group--primary">
                        <kl-router-link :to="routeAdminNewInvoiceGroup"
                                        class="vl-button vl-button--primary vl-button--medium">
                            Nieuwe facturatiegroep
                        </kl-router-link>
                    </vl-action-group>
                </vl-column>
                <vl-column v-if="invoiceGroups">
                    <ippro-datatable id="invoicegroups-operations-table" :meta="meta" :dataset="invoiceGroups"
                                     :columns="invoiceGroupsColumns" :fetching="loading" mod-clickable-rows mod-zebra
                                     mod-pagination-on-top>
                        <template v-slot:noresults>
                            <div v-vl-align:center>
                                <p>Geen facturatiegroepen gevonden.</p>
                            </div>
                        </template>
                    </ippro-datatable>
                </vl-column>
            </vl-form-grid>
        </vl-region>
    </template>
</div>