import { useCodeListStore } from '@/app/shared/state/CodeListModule';
import { Mri } from '@/app/mri/shared/services/services';
import {isEmpty} from 'lodash-es';
import {computed, defineComponent, onMounted, ref, watch} from 'vue';
import {useKlipApiProxy} from '@/plugins/proxy-client';
import {useField} from 'vee-validate';
import KlFieldValidationWrapper2 from '@/app/shared/components/kl-form-fields/kl-field-validation-wrapper.vue';
import { useFocus } from '@vueuse/core'

export default defineComponent({
    name: 'KlAddressForm',
    components: {KlFieldValidationWrapper2},
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            type: Object as () => Mri.IAddress,
            required: false,
            default: () => ({}),
        }
    },
    setup(props, { emit }) {

        const streetMinLength: number = 2;

        const streetNumberRef = ref(null);
        const streetNumberRefFocussed = useFocus(streetNumberRef);

        const streetData = ref<AutoCompleteResult[]>([]);
        const streetDataFetching = ref<boolean>(false);
        const streetNumberData = ref<AutoCompleteResult[]>([]);
        const streetNumberDataFetching = ref<boolean>(false);

        const streetField = useField<string>('Straat', 'required|max:200');
        const streetNumberField = useField<string>('Nummer', 'required|max:50');
        const boxField = useField<string>('Bus', 'max:10');
        const zipCodeField = useField<string>('Postcode', 'required|max:10');
        const cityField = useField<string>('Gemeente', 'required|max:200');
        const countryCodeField = useField<string>('Land', 'required');

        const street = streetField.value;
        const streetNumber = streetNumberField.value;
        const box = boxField.value;
        const zipCode = zipCodeField.value;
        const city = cityField.value;
        const countryCode = countryCodeField.value;

        const address = computed<Mri.IAddress>(() => ({
            box: box.value,
            city: city.value,
            countryCode: countryCode.value,
            street: street.value,
            streetNumber: streetNumber.value,
            zip: zipCode.value,
        }));


        const autocompleteActive = computed(() => {
            return countryCode.value === 'BE';
        });

        const countries = computed(() => {
            return useCodeListStore().countryCodes;
        });


        const onStreetInputChange = (newValue: string) => {
            streetField.value.value = newValue;
            if (autocompleteActive.value && newValue?.length > streetMinLength) {
                _fetchStreet(newValue);
            }
        }

        const onStreetNumberInputChange = () => {
            if (autocompleteActive.value && street.value && city.value) {
                _fetchStreetNumbers(street.value, city.value);
            }
        }

        const onStreetSelect = (street: AutoCompleteResult) => {
            const newAddress = _explodedAddressString(street.value);
            if (!newAddress.street) {
                return;
            }
            street.value = newAddress.street;
            if (newAddress.streetNumber) {
                streetNumber.value = newAddress.streetNumber;
            } else {
                if (!streetNumber.value) {
                    _focusToStreetNumber();
                }
            }
            if (newAddress.box) { box.value = newAddress.box; }
            if (newAddress.zip) {
                zipCode.value = newAddress.zip;
            } else {
                if (newAddress.city && newAddress.street) {
                    _fetchZipCodes(newAddress.street, newAddress.city);
                }
            }
            if (newAddress.city) {
                city.value = newAddress.city;
            }
        }

        const _focusToStreetNumber = () => {
            streetNumberRefFocussed.focused.value = true;
        }

        const onStreetNumberSelect = (streetNumber: AutoCompleteResult) => {
            if (streetNumber.value.length) {
                const parts = streetNumber.value.match(/[a-z]+|[^a-z]+/gi);
                if (parts[0]) {
                    streetNumber.value = parts[0];
                }
                if (parts[1]) {
                    box.value = parts[1];
                }
            }
        }

        const _fetchStreet = (street: string) => {
            streetDataFetching.value = true;
            useKlipApiProxy().location_GetAddressSuggestion(street)
                .then((response) => {
                    streetData.value = response.result
                        .map((item) => {
                            const parts = item.split(',');
                            return {
                                title: parts[0],
                                subtitle: parts[1],
                                value: item,
                            };
                        });
                }).finally(() => {
                streetDataFetching.value = false;
            });
        }

        const _fetchStreetNumbers = (street: string, city: string) => {
            if (street && city) {
                streetNumberDataFetching.value = true;
                useKlipApiProxy().location_GetStreetNumbers(street, city)
                    .then((response) => {
                        if (isEmpty(response.result)) {
                            return;
                        }
                        streetNumberData.value = response.result
                            .filter((currentNr: string) => {
                                if (isEmpty(currentNr)) {
                                    return false;
                                }
                                if (isEmpty(streetNumber.value)) {
                                    return true;
                                }
                                return currentNr.startsWith(streetNumber.value);
                            })
                            .map((currentNr: string) => {
                                return {
                                    title: currentNr,
                                    value: currentNr,
                                };
                            });
                    }).finally(() => {
                    streetNumberDataFetching.value = false;
                });
            }
        }

        const _fetchZipCodes = (street: string, city: string) => {
            useKlipApiProxy().location_GetZipCodes(street, city)
                .then((response) => {
                    if (response.result.length !== 0) {
                        zipCode.value = response.result[0];
                    }
                });
        }

        const _explodedAddressString = (address: string) => {
            const explodedAddress: Mri.IAddress = {};
            if (address.length) {
                if (address.includes(', ')) {
                    const addressParts = address.split(', ');
                    const streetDetails = addressParts[0];
                    const cityDetails = addressParts[1];
                    if (streetDetails.includes(' ')) {
                        const lastSpace = streetDetails.lastIndexOf(' ');
                        const firstPart = streetDetails.substr(0, lastSpace);
                        const secondPart = streetDetails.substr(lastSpace + 1);
                        explodedAddress.street = streetDetails;
                        if (parseInt(secondPart, 10)) {
                            explodedAddress.street = firstPart;
                            explodedAddress.streetNumber = secondPart;
                        }
                    }
                    if (!explodedAddress.street) { explodedAddress.street = streetDetails; }

                    if (cityDetails.includes(' ')) {
                        const cityParts = cityDetails.split(' ');
                        // There are some cities with spaces in the name (ex: De Haan, De Pinte), se we need to check if the possible zipcode is a number.
                        const isInvalidZipCode = isNaN(+cityParts[0]);
                        explodedAddress.zip = isInvalidZipCode ? '' : cityParts[0];
                        explodedAddress.city = isInvalidZipCode ? cityDetails : cityParts[1];
                    } else {
                        explodedAddress.city = cityDetails;
                    }
                } else {
                    explodedAddress.street = address;
                }
            }
            return explodedAddress;
        }

        const _setAddress = (newAddress: Mri.IAddress) => {
            //console.log('_setAddress', newAddress);
            if (!isEmpty(newAddress)) {
                boxField.handleChange(newAddress?.box, false);
                cityField.handleChange(newAddress?.city, false);
                countryCodeField.handleChange(newAddress?.countryCode || 'BE', false);
                streetField.handleChange(newAddress?.street, false);
                streetNumberField.handleChange(newAddress?.streetNumber, false);
                zipCodeField.handleChange(newAddress?.zip, false);

                // boxField.value.value = newAddress?.box;
                // cityField.value.value = newAddress?.city;
                // countryCodeField.value.value = newAddress?.countryCode || 'BE';
                // streetField.value.value = newAddress?.street;
                // streetNumberField.value.value = newAddress?.streetNumber;
                // zipCodeField.value.value = newAddress?.zip;
            }
            else {
                boxField.resetField();
                cityField.resetField();
                countryCodeField.resetField({ value: 'BE' });
                streetField.resetField();
                streetNumberField.resetField();
                zipCodeField.resetField();
            }
        }

        watch(
            () => props.modelValue,
            (val: Mri.IAddress) => {
                _setAddress(val);
            },
            { immediate: true, deep: true })

        watch(
            address,
            (val: Mri.IAddress) => {
                emit('update:modelValue', val);
            },
            { immediate: false, deep: true })

        watch(
            autocompleteActive,
            (val: boolean, oldVal: boolean) => {
                if (!val) {
                    streetData.value = [];
                    streetNumberData.value = [];
                }
            },
            { immediate: false, deep: false })


        onMounted(() => {
            _setAddress(props.modelValue);
        });


        return {
            streetNumberRef,

            streetData,
            streetDataFetching,
            streetNumberData,
            streetNumberDataFetching,
            autocompleteActive,
            countries,

            onStreetInputChange,
            onStreetNumberInputChange,
            onStreetSelect,
            onStreetNumberSelect,

            streetField,
            streetNumberField,
            boxField,
            zipCodeField,
            cityField,
            countryCodeField,

            street,
            streetNumber,
            box,
            zipCode,
            city,
            countryCode,
        }
    }
})
