<template>
    <vl-datepicker
        :placeholder="placeholder"
        :visual-format="visualFormat"
        :default-date="defaultDate"
        v-bind="$attrs"
        ref="VlDatepicker"
        :modelValue="value"
        @input="emit('input', $event)"
    />
</template>

<script setup lang="ts">

import { useAttrs } from 'vue';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

defineProps({
    value: { type: Object, required: true },
    // modelValue: { type: Object, required: true },
    placeholder: { type: String, default: 'dd/mm/jjjj' },
    visualFormat: { type: String, default: 'd/m/Y' },
    defaultDate: { type: String, default: '' },
});
const emit = defineEmits(['update:modelValue', 'input']);

const $attrs = useAttrs();

</script>

<!--<script lang="ts" src="./kl-datepicker.ts">-->
<!--</script>-->
