<vl-region v-if="organisationDetail">
    <vl-form-grid mod-stacked>
        <vl-column>
            <vl-title tag-name="h2">
                Organisatie(s)
            </vl-title>
        </vl-column>
        <vl-column>
            <kl-search-active-organisations ref="searchOrganisations" @search="search" />
        </vl-column>
        <vl-column>
            <ippro-datatable id="suborgansations-table" mod-zebra mod-hide-pagination mod-clickable-rows
                :fetching="false" :meta="subOrganisationsMeta" :dataset="subOrganisations"
                :columns="subOrganisationsColumns">
                <template v-slot:noresults>
                    <div v-vl-align:center>
                        <vl-u-spacer mod-large />
                        <p>Er zijn nog geen suborganisaties binnen uw organisatie.</p>
                    </div>
                </template>
            </ippro-datatable>
        </vl-column>
    </vl-form-grid>
</vl-region>