import {
    AdminArchivedMapRequestDetail,
    AdminEvents,
    AdminServiceDetail,
    AdminHome,
    AdminInvoice,
    AdminInvoiceGroups,
    AdminInvoiceGroup,
    AdminMapRequestDetail,
    AdminMapRequestDetailAsMri,
    AdminMapRequestDetailAsUna,
    AdminMapRequestOverview,
    AdminOrganisation,
    AdminOrganisationDetail,
    AdminSubOrganisationDetail,
    AdminSubOrganisationDetailUnaZone,
    AdminSubOrganisationDetailUnaKlimZone,
    AdminSettings,
    AdminUser,
    AdminUserDetail
} from '@/app/admin/admin-routes';
import {useUserStore} from '../../state/UserDataModule';
import {EUserRole} from '@/app/shared/state/user-role';
import {computed, defineComponent} from 'vue';
import {useRoute} from '@/router/router-vue3';
import {RouteRecordRaw} from 'vue-router';

export default defineComponent({
    name: 'AdminNavigation',
    props: {},
    setup(props) {

        const adminMriRoutes = [
            AdminMapRequestOverview.name,
            AdminMapRequestDetail.name,
            AdminArchivedMapRequestDetail.name,
            AdminMapRequestDetailAsMri.name,
            AdminMapRequestDetailAsUna.name,
        ];

        const adminUserRoutes = [
            AdminUser.name,
            AdminUserDetail.name
        ];

        const adminInvoiceRoutes = [
            AdminInvoice.name,
            AdminInvoiceGroups.name,
            AdminInvoiceGroup.name
        ];

        const adminOrganisationRoutes = [
            AdminOrganisation.name,
            AdminOrganisationDetail.name,
            AdminSubOrganisationDetail.name,
            AdminSubOrganisationDetailUnaZone.name,
            AdminSubOrganisationDetailUnaKlimZone.name
        ];

        const route = useRoute();

        const routeAdminHome = computed((): RouteRecordRaw => {
            return AdminHome;
        });

        const hasAdminRole = computed((): boolean => {
            return useUserStore().hasRole([EUserRole.admin]);
        });

        const isAdminHomeRoute = computed(() => {
            return route.name == AdminHome.name || route.name == AdminServiceDetail.name;
        });

        const routeAdminEvents = computed((): RouteRecordRaw => {
            return AdminEvents;
        });

        const isAdminEventsRoute = computed(() => {
            return route.name == AdminEvents.name;
        });

        const routeAdminMri = computed((): RouteRecordRaw => {
            return AdminMapRequestOverview;
        });

        const isAdminMriRoute = computed((): boolean => {
            return adminMriRoutes.includes(route.name);
        });

        const routeAdminUser = computed((): RouteRecordRaw => {
            return AdminUser;
        });

        const isAdminUserRoute = computed((): boolean => {
            return adminUserRoutes.includes(route.name);
        });

        const routeAdminOrganisation = computed((): RouteRecordRaw => {
            return AdminOrganisation;
        });

        const isAdminOrganisationRoute = computed((): boolean => {
            return adminOrganisationRoutes.includes(route.name);
        });

        const routeAdminInvoice = computed((): RouteRecordRaw => {
            return AdminInvoice;
        });

        const isAdminInvoiceRoute = computed((): boolean => {
            return adminInvoiceRoutes.includes(route.name);
        });

        const routeAdminSettings = computed((): RouteRecordRaw => {
            return AdminSettings;
        });

        const isAdminSettingsRoute = computed((): boolean => {
            return route.name == AdminSettings.name;
        });


        return {
            routeAdminHome,
            hasAdminRole,
            isAdminHomeRoute,
            routeAdminEvents,
            isAdminEventsRoute,
            routeAdminMri,
            isAdminMriRoute,
            routeAdminUser,
            isAdminUserRoute,
            routeAdminOrganisation,
            isAdminOrganisationRoute,
            routeAdminInvoice,
            isAdminInvoiceRoute,
            routeAdminSettings,
            isAdminSettingsRoute,
        }
    }
})
