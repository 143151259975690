import {defineComponent, onMounted, PropType, reactive, ref, toRefs, watch} from 'vue'

export default defineComponent({
    name: 'KlAutocomplete2',
    props: {
        modelValue: { type: String, required: true },
        data: {
            type: Array as PropType<AutoCompleteResult[]>,
            required: true
        },
        searchValue: {
            type: String,
            required: false,
            default: ''
        },
        fetching: {
            type: Boolean,
            default: false
        },
        charLength: {
            type: Number,
            default: 3
        }
    },
    emits: ['input', 'select', 'change'],
    setup(props, { emit }) {
        const componentRef = ref<HTMLElement>()

        const data = reactive({
            localValue: props.modelValue,
            error: ''
        })

        watch(
            () => props.modelValue,
            (newValue) => {
                data.localValue = newValue
            },
            { immediate: true, deep: true }
        )

        const select = (result: AutoCompleteResult) => {
            data.localValue = result.title;
            emit('select', result)
        }

        const updateValue = (value: string) => {
            data.localValue = value;
            emit('input', value)
        }

        const change = (value: string) => {
            data.localValue = value;
            emit('change', value)
        }

        return {
            select,
            change,
            updateValue,
            componentRef,
            ...toRefs(data),
        }
    }
})
