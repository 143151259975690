import {klipIndex} from '@/router/klip-index';
import {EUserRole} from '@/app/shared/state/user-role';
import {RouteLocation, RouteRecordRaw} from 'vue-router';

export const UnaIndex: RouteRecordRaw = {
    path: '/una',
    name: 'una',
    component: () => import(/* webpackChunkName: "una" */ './index.vue'),
    meta: {
        authorize: { roles: [EUserRole.una_manager, EUserRole.una_operator, EUserRole.una_reader, EUserRole.klim_admin] },
        breadcrumb: { title: '', parent: klipIndex },
    },
};

export const UnaMapRequest: RouteRecordRaw = {
    path: '/una/maprequest/:id/:zoneId/:archived',
    name: 'unamaprequest',
    component: () => import(/* webpackChunkName: "una" */ './map-request.vue'),
    meta: {
        authorize: { roles: [EUserRole.una_operator, EUserRole.una_reader] },
        breadcrumb: { title: 'Planaanvraag', parent: UnaIndex },
    },
};

export const UnaViewer: RouteRecordRaw = {
    path: '/una/viewer/:id/:zoneId',
    name: 'unaviewer',
    component: () => import('./../viewer/index.vue'),
    meta: {
        authorize: { roles: [EUserRole.una_operator, EUserRole.una_reader, EUserRole.helpdesk, EUserRole.admin] },
        breadcrumb: {
            title: 'Leidingviewer',
            parent: UnaIndex // UnaViewer is opened on a separate tab = we do not need a UnaMapRequest as parent (+ UnaMapRequest requires the :archived param)
        },
    },
};

export default [
    UnaIndex,
    UnaMapRequest,
    UnaViewer,
];
