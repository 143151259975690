<!--    <vl-form-validation-observer  ref="validationObserver" v-slot="{ handleSubmit, invalid, pristine }">-->
    <form class="vl-form" @submit="onSubmit">
        <vl-region mod-medium>
            <vl-layout>
                <!-- heading -->
                <vl-form-grid mod-v-center mod-stacked>
                    <vl-column width="6" width-xs="12">
                        <vl-title v-bind:tag-name="'h1'">{{ title }} <small v-if="unaDisplayName"> - {{ unaDisplayName }}</small></vl-title>
                    </vl-column>
                </vl-form-grid>
                <!-- ./heading -->
            </vl-layout>
        </vl-region>

        <vl-region>
            <vl-layout>
                <!-- create-edit-form -->
                <vl-form-grid mod-stacked>
                    <vl-column width-s="12" width-m="8" width-l="6">
                        <template>
                            <kl-field-validation-wrapper :field="nameField" #default="{hasErrors}" mod-required>
                                <vl-input-field name="naam zone"  id="name-zone" v-model="name" :mod-error="hasErrors" mod-block />
                            </kl-field-validation-wrapper>
                        </template>
                    </vl-column>
                    <vl-column width-s="0" width-m="4" width-l="6" v-vl-visibility:hide.s></vl-column>
                    <vl-column width-s="12" width-m="8" width-l="6">
                        <kl-field-validation-wrapper :field="mailDestinationField" #default="{hasErrors}"
                            mod-required>
                            <vl-input-field name="e-mail planaanvragen" id="mail-destination"
                                v-model="mailDestination" :mod-error="hasErrors" mod-block />
                        </kl-field-validation-wrapper>
                    </vl-column>
                    <vl-column width-s="0" width-m="4" width-l="6" v-vl-visibility:hide.s></vl-column>
                    <vl-column width-s="12" width-m="8" width-l="8">
                        <vl-form-message-label>Welke meldingen wil u ontvangen via e-mail?</vl-form-message-label>
                        <vl-typography>
                            <p>Contacteer <vl-link :href="'mailto:' + helpdeskMail">{{ helpdeskMail }}</vl-link> als u planaanvragen via de services ophaalt en mails met nieuwe planaanvragen niet meer wenst te ontvangen. Als u een planaanvraag te laat bevestigt of beantwoordt, sturen we u sowieso een herinnering.</p>
                        </vl-typography>
                    </vl-column>
                    <vl-column width-s="12" width-m="8" width-l="8">
                        <vl-checkbox v-model="mailSettings.confirmation" mod-disabled>
                            <span class="checkbox-label">Nieuwe planaanvraag te bevestigen</span>
                            <vl-link :href="mailSettings.confirmationExampleUri" v-if="mailSettings.confirmationExampleUri" icon="file" target="_blank" mod-icon-before>voorbeeld</vl-link>
                        </vl-checkbox>
                    </vl-column>
                    <vl-column width-s="12" width-m="8" width-l="8">
                        <vl-checkbox v-model="mailSettings.reminder1DayToRespond">
                            <span class="checkbox-label">Herinnering: nog 1 dag om te antwoorden</span>
                            <vl-link :href="mailSettings.reminder1DayToRespondExampleUri" v-if="mailSettings.reminder1DayToRespondExampleUri" icon="file" target="_blank" mod-icon-before>voorbeeld</vl-link>
                        </vl-checkbox>
                    </vl-column>
                    <vl-column width-s="12" width-m="8" width-l="8">
                        <vl-checkbox v-model="mailSettings.uploadImklSuccess">
                            <span class="checkbox-label">Opladen IMKL: geslaagd</span>
                            <vl-link :href="mailSettings.uploadImklSuccessExampleUri" v-if="mailSettings.uploadImklSuccessExampleUri" icon="file" target="_blank" mod-icon-before>voorbeeld</vl-link>
                        </vl-checkbox>
                    </vl-column>
                    <vl-column width-s="12" width-m="8" width-l="8">
                        <vl-checkbox v-model="mailSettings.uploadImklFailed">
                            <span class="checkbox-label">Opladen IMKL: gefaald</span>
                            <vl-link :href="mailSettings.uploadImklFailedExampleUri" v-if="mailSettings.uploadImklFailedExampleUri" icon="file" target="_blank" mod-icon-before>voorbeeld</vl-link>
                        </vl-checkbox>
                    </vl-column>
                    <vl-column width-s="12" width-m="8" width-l="8">
                        <vl-checkbox v-model="mailSettings.respondedNotInvolved">
                            <span class="checkbox-label">Bevestiging antwoord: geen kabels en leidingen</span>
                            <vl-link :href="mailSettings.respondedNotInvolvedExampleUri" v-if="mailSettings.respondedNotInvolvedExampleUri" icon="file" target="_blank" mod-icon-before>voorbeeld</vl-link>
                        </vl-checkbox>
                    </vl-column>
                </vl-form-grid>
                <!-- ./create-edit-form -->
            </vl-layout>
        </vl-region>

        <!-- namespace -->
        <vl-region>
            <vl-layout>
                <vl-form-grid mod-stacked>
                    <vl-column>
                        <vl-title v-bind:tag-name="'h2'">Namespace</vl-title>
                        <vl-typography>
                                <p>Om naamgevingsconflicten te vermijden bij het samenvoegen van IMKL-antwoorden, heeft elke zone
                            een unieke namespace nodig. <vl-link :href="namespaceUrl" target="_blank">Lees meer over namespaces</vl-link>.</p>
                            </vl-typography>
                    </vl-column>
                    <vl-column width-s="12" width-m="8" width-l="6">
                        <kl-field-validation-wrapper :field="namespaceField" #default="{hasErrors}" mod-required
                            annotation="Minimum 5 en maximaal 50 karakters. Enkel alfanumeriek (a-Z, 0-9). (Liggend) streepje is toegelaten. Geen spaties." mod-no-wrap-annotation>
                            <vl-input-field name="namespace" id="namespace"
                                v-model="namespace" :mod-error="hasErrors" mod-block />
                        </kl-field-validation-wrapper>
                    </vl-column>
                </vl-form-grid>
            </vl-layout>
        </vl-region>
        <!-- ./namespace -->

        <!-- contact -->
        <vl-region>
            <vl-layout>
                <vl-form-grid mod-stacked>
                    <vl-column>
                        <vl-title v-bind:tag-name="'h2'">Contactgegevens</vl-title>
                        <vl-typography>
                            <p>Geef hier contactgegevens mee voor vragen over de planaanvraag. Deze gegevens worden getoond in
                                    het <vl-link :href="overlayUrl" target="_blank">overzicht van aanwezige KLB's</vl-link>.</p>
                        </vl-typography>
                    </vl-column>
                    <vl-column width-s="12" width-m="8" width-l="6">
                        <kl-field-validation-wrapper :field="contactNameField" #default="{hasErrors}" mod-required
                            annotation="bv. Helpdesk of Jan Jansens">
                            <vl-input-field  name="naam contact" id="contact-name"
                                v-model="contact.name" :mod-error="hasErrors" mod-block />
                        </kl-field-validation-wrapper>
                    </vl-column>
                    <vl-column width-s="0" width-m="4" width-l="6" v-vl-visibility:hide.s></vl-column>
                    <vl-column width-s="12" width-m="8" width-l="4">
                        <kl-field-validation-wrapper :field="contactPhoneField" #default="{hasErrors}" mod-required>
                            <vl-input-field  placeholder="+32" name="telefoon contact"
                                id="contact-phone" v-model="contact.phone" :mod-error="hasErrors" mod-block />
                        </kl-field-validation-wrapper>
                    </vl-column>
                    <vl-column width-s="0" width-m="4" width-l="6" v-vl-visibility:hide.s></vl-column>
                    <vl-column width-s="12" width-m="8" width-l="6">
                        <kl-field-validation-wrapper :field="contactEmailField" #default="{hasErrors}" mod-required>
                            <vl-input-field  name="email contact" id="contact-email"
                                v-model="contact.email" :mod-error="hasErrors" mod-block />
                        </kl-field-validation-wrapper>
                    </vl-column>
                    <vl-column width-s="12" width-m="12" width-l="12">
                        <kl-field-validation-wrapper :field="contactMessageField" #default="{hasErrors}"
                            annotation="Enkel te gebruiken indien u de planaanvrager iets belangrijks wil melden, zoals: 'Er moet steeds een medewerker van onze KLB ter plaatse komen.'">
                            <vl-input-field name="boodschap planaanvrager" id="message-pa-request"
                                v-model="contact.message" :mod-error="hasErrors" mod-block />
                        </kl-field-validation-wrapper>
                    </vl-column>
                    <vl-column width-s="12" width-m="8" width-l="4">
                        <vl-u-spacer />
                        <kl-field-validation-wrapper :field="contactPhoneEmergencyField" #default="{hasErrors}"
                            annotation="Dit nummer wordt getoond in zowel het overzicht van aanwezige KLB's als in de contactgegegevens van elke leiding of elk leidingelement."
                            mod-required mod-no-wrap-annotation>
                            <vl-input-field placeholder="+32" name="telefoon noodgeval"
                                id="contact-phone-emergency" v-model="contact.phoneEmergency" :mod-error="hasErrors"
                                mod-block />
                        </kl-field-validation-wrapper>
                    </vl-column>
                </vl-form-grid>
            </vl-layout>
        </vl-region>
        <!-- ./contact -->

        <vl-region>
            <vl-layout>
                <vl-button type="submit" :mod-disabled="disableSubmit" :mod-loading="isSending" mod-large>
                    {{ submitButtonLabel }}
                </vl-button>
            </vl-layout>
        </vl-region>
    </form>
<!--</vl-form-validation-observer>-->
