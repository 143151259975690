import {computed, defineComponent, ref, watch} from "vue";
import * as KlipApi from "@/api/klip-api.proxy";
import {AdminOrganisationDetailPdaZone} from "@/app/admin/admin-routes";
import {useKlipApiProxy} from '@/plugins/proxy-client';
import {RouteRecordRaw} from 'vue-router';

export default defineComponent({
    props: {
        organisation: {
            type: KlipApi.OrganisationDetail,
            default: null,
            required: true
        }
    },
    setup(props) {
        const klipApiProxy = useKlipApiProxy();
        const organisationDetail = ref<KlipApi.OrganisationDetail>(null);
        const fetchingPdaZones = ref<boolean>(false);
        const pdaZones = ref<KlipApi.IZoneListItem[]>([]);

        const organisationChanged = async (organisation: KlipApi.OrganisationDetail) => {
            organisationDetail.value = organisation;
            await loadPdaZones(organisation.organisationId);
        };

        const routePdaZoneDetail = computed((): RouteRecordRaw => {
            return AdminOrganisationDetailPdaZone;
        });

        const loadPdaZones = async (organisationId: string) => {
            fetchingPdaZones.value = true;
            const zonesResult: KlipApi.EnvelopeOfIListOfZoneListItem = await klipApiProxy.operations_GetPdaZones(organisationId);

            if (zonesResult.result) {
                pdaZones.value = zonesResult.result;

            }
            fetchingPdaZones.value = false;
        };

        watch(props.organisation, organisationChanged, {immediate: true, deep: true});

        return {
            organisationDetail,
            loadPdaZones,
            fetchingPdaZones,
            pdaZones,
            routePdaZoneDetail
        };
    }
});
