import {App} from 'vue';
import DateUtil from '@/app/shared/helpers/date-util';

export interface IFilters {
    toCurrency: (value: number) => string;
    periodStringFromDates: (startDate: string, endDate: string) => string;
}

export default {
    install(app: App) {

        const toCurrency = (value) => {
            if (typeof value !== "number") {
                return value;
            }
            const formatter = new Intl.NumberFormat('nl-BE', {
                style: 'currency',
                currency: 'EUR'
            });
            return formatter.format(value);
        };

        app.config.globalProperties.$filters = {
            toCurrency,
            periodStringFromDates: DateUtil.periodStringFromDates,
        };
    },
};
