<vl-region v-if="userDetail">
    <vl-form-grid mod-stacked>
        <vl-column>
            <vl-title tag-name="h2">Gebruikersinstellingen</vl-title>
        </vl-column>
        <vl-column>
            <kl-description-list>
                <kl-description-list-row label="Rollen:" v-if="rolesInOrganisation">{{ rolesInOrganisation }}</kl-description-list-row>
                <kl-description-list-row label="Source:">{{ userDetail.source }}</kl-description-list-row>
                <kl-description-list-row label="Laatst actief:" v-if="lastActiveInOrganisation">{{ lastActiveInOrganisation }}</kl-description-list-row>
                <kl-description-list-row label="Email:" v-if="user.email">{{ userDetail.email }}</kl-description-list-row>
            </kl-description-list>
            <vl-u-spacer mod-small />
        </vl-column>
        <vl-column v-if="hasMriRole">
            <vl-title tag-name="h3">Meldingen?</vl-title>
            <vl-form-grid mod-stacked>
                <vl-form-column>
                    <vl-typography>
                        <vl-title tag-name="h5">Als planaanvrager</vl-title>
                    </vl-typography>
                </vl-form-column>
                <vl-form-column>
                    <vl-typography>
                        <p>Als uw aangevraagde plannenpakket volledig beschikbaar is, sturen we altijd een mail.</p>
                    </vl-typography>
                </vl-form-column>
                <vl-form-column>
                    <vl-checkbox v-model="userDetail.confirmationMail" mod-disabled>
                        <span class="checkbox-label">Bevestiging planaanvraag</span>
                        <vl-link :href="user.confirmationMailExampleUri" v-if="userDetail.confirmationMailExampleUri" icon="file" target="_blank" mod-icon-before>voorbeeld</vl-link>
                    </vl-checkbox>
                </vl-form-column>
                <vl-form-column>
                    <vl-checkbox v-model="userDetail.incompletePackageAvailableMail" mod-disabled>
                        <span class="checkbox-label">Plannenpakket onvolledig beschikbaar</span>
                        <vl-link :href="userDetail.incompletePackageAvailableMailExampleUri" v-if="userDetail.incompletePackageAvailableMailExampleUri" icon="file" target="_blank" mod-icon-before>voorbeeld</vl-link>
                    </vl-checkbox>
                </vl-form-column>
                <vl-form-column>
                    <vl-typography>
                        <p>Planaanvragen worden na 6 maand automatisch gearchiveerd.</p>
                    </vl-typography>
                </vl-form-column>
                <vl-form-column>
                    <vl-checkbox v-model="userDetail.soonToBeArchivedMail" mod-disabled>
                        <span class="checkbox-label">Melding voor planaanvragen die binnenkort gearchiveerd zullen worden</span>
                        <vl-link :href="userDetail.soonToBeArchivedMailExampleUri" v-if="userDetail.soonToBeArchivedMailExampleUri" icon="file" target="_blank" mod-icon-before>voorbeeld</vl-link>
                    </vl-checkbox>
                </vl-form-column>
            </vl-form-grid>
        </vl-column>
    </vl-form-grid>
</vl-region>
