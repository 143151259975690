<vl-form-grid mod-stacked>
    <vl-column v-if="searchFields">
        <kl-search-invoices ref="searchInvoices" @search="search" v-if="searchFields.date" />
    </vl-column>
    <vl-column v-if="showFinancialServiceWarning">
        <vl-alert icon="alert-circle" mod-warning>
            <p>Deze lijst bevat enkel nog facturen & betalingsbewijzen van voor 01/09/2024 (aansluiting op het Clearing House). Sindsdien zijn de facturen en betalingsbewijzen enkel nog beschikbaar op organisatie-niveau.</p>
        </vl-alert>
    </vl-column>
    <vl-column>
        <ippro-datatable class="invoices"  modZebra:="false" :meta="meta" :dataset="displayedInvoices" :columns="columns" :fetching="fetching"
            mod-zebra>
            <template v-slot:noresults>
                <div v-vl-align:center>
                    <vl-u-spacer mod-large />
                    <p v-if="!showUnavailable">Uw zoekopdracht heeft geen resultaten opgeleverd.</p>
                    <p v-if="showUnavailable">De facturen konden niet opgehaald worden bij Clearing House. Probeer later nogmaals opnieuw.</p>
                </div>
            </template>
            <template v-slot:actions>
                <vl-button @click="download"
                    :key="'download-' + downloadFileUri" class="vl-button--link" icon="download-harddisk"
                    mod-icon-before :mod-loading="downloadFileUriLoading" :mod-disabled="downloadFileUriLoading"
                    v-if="showDownloadLink">Download</vl-button>
            </template>
        </ippro-datatable>
    </vl-column>
</vl-form-grid>