<div>
    <kl-search-maprequests mod-simple
        :search-fields="data.searchFields"
        :search-options="data.searchOptions"
        :grouped-search-options="data.groupedSearchOptions"
        :default-search-option="defaultSearchOption"
        :default-filter="defaultFilter"
        ref="searchMaprequests"
        @search="search"
        v-if="data">
        <template v-slot:zones v-if="data.zones && data.zones.length > 1">
            <vl-form-message-label for="zones-to-reply" v-vl-visually-hidden>Zones</vl-form-message-label>
            <vl-select name="zones-to-reply" v-model="selectedZone" mod-block>
                <option value="all">Alle zones</option>
                <option :value="option.key" v-for="option in data.zones">
                    {{ option.label }}
                </option>
            </vl-select>
        </template>
    </kl-search-maprequests>
    <vl-u-spacer mod-small />
    <ippro-datatable v-if="mapRequests" :meta="mapRequestsMeta" :dataset="mapRequests" :columns="mapRequestsColumns" mod-pagination-on-top
        :fetching="mapRequestsFetching" @pager-clicked="pagerClicked" @column-clicked="columnClicked" @row-clicked="rowClicked" ref="mapRequestDatatable" mod-clickable-rows mod-zebra>
        <template v-slot:noresults>
            <vl-u-spacer mod-large />
            <div v-vl-align:center>
                <template v-if="searchUsed">
                    <p>Uw zoekopdracht heeft geen resultaten opgeleverd.</p>
                    <vl-button @click="clearSearch" class="vl-button--link">Zoekvelden wissen</vl-button>
                    <vl-u-spacer mod-xsmall />
                </template>
                <p v-else-if="!!!data.count">U heeft alle ontvangen planaanvragen beantwoord.<br />Ga naar uw beantwoorde planaanvragen.</p>
                <p v-else>U heeft alle ontvangen planaanvragen van uw organisatie beantwoord.<br />Bekijk ook de te beantwoorden planaanvragen van uw suborganisaties<br /> of ga naar uw beantwoorde planaanvragen.</p>
            </div>
        </template>
    </ippro-datatable>
</div>
