<vl-region>
    <vl-layout>
        <vl-form-grid mod-stacked>
            <vl-column>
                <vl-title tag-name="h1">{{title}}</vl-title>
            </vl-column>
            <vl-u-spacer mod-small />
            <vl-column>
                <vl-progress-bar mod-numeric v-if="activeStep && !viewModelLoading">
                    <vl-progress-bar-step v-for="(step, index) in steps" @click="goToStep(index + 1)" :label="step.label" :mod-active="activeStep == index + 1" :key="index" />
                </vl-progress-bar>
            </vl-column>
            <vl-column v-if="isInfoAlertVisible">
                <vl-alert title="Let op: de gevraagde plannen zijn enkel voor u persoonlijk" :closable="false" class="info-panel">
                    <vl-typography>
                        <ul>
                            <li>
                                <div><strong>Het is niet mogelijk om een factuur op naam van uw bedrijf te krijgen.</strong></div>
                                <div>Wilt u plannen aanvragen voor uw werkgever of bedrijf? Contacteer dan <vl-link :href="'mailto:' + helpdeskMail">{{ helpdeskMail }}</vl-link>.</div>
                            </li>
                            <li>
                                <div><strong>Laat u grondwerken door een aannemer uitvoeren?</strong></div>
                                <div>Dan is de aannemer zelf verplicht om plannen aan te vragen.</div>
                            </li>
                        </ul>
                    </vl-typography>
                </vl-alert>
            </vl-column>
        </vl-form-grid>
    </vl-layout>
</vl-region>
