<vl-form-grid mod-stacked>
	<vl-column v-if="error">
		<vl-alert icon="alert-circle" title="Onbekende fout" mod-error>
				<p>Er heeft zich een onbekende fout voorgedaan. Gelieve later opnieuw te proberen.</p>
		</vl-alert>
	</vl-column>
	<vl-column v-if="showConfirmCheckbox">
		<vl-checkbox v-model="confirmCheckbox" ref="confirmCheckbox">
            Ik bevestig dat ik gearchiveerde gegevens niet zal gebruiken voor het uitvoeren van grondwerken.
        </vl-checkbox>
	</vl-column>
	<vl-column>
		<a :href="fileUrl" ref="downloadButton" :class="downloadButtonClasses" :disabled="isDownloadButtonDisabled">{{ downloadButtonText }}</a>
	</vl-column>
</vl-form-grid>
