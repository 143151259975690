<vl-region class="impersonation">
    <div v-vl-align:center v-if="loading">
        <vl-loader message="De gegevens worden geladen. Even geduld, a.u.b." />
    </div>
    <vl-form-grid v-else mod-stacked>
        <vl-column v-if="!activeImpersonation">
            <div class="border">
<!--                <vl-form-validation-observer ref="validationObserver" v-slot="{ handleSubmit, invalid, pristine }" >-->
                    <form class="vl-form" @submit="onSubmit">
                        <vl-form-grid mod-stacked>
                            <vl-column v-vl-align:center v-if="!startNewImpersonatingSession" >
                                <vl-button @click="startingNewImpersonatingSession"  v-vl-align:centerclass="'btn-start-impersonating'" mod-secondary mod-large>Nieuwe impersonation sessie starten</vl-button>
                            </vl-column>
                            <template v-if="startNewImpersonatingSession">
                                <vl-column>
                                    <vl-title tag-name="h2">Nieuwe impersonation sessie starten</vl-title>
                                </vl-column>
                                <vl-column width-l="4">
                                    <kl-field-validation-wrapper :field="crmCaseNumberField" mod-required #default="{hasErrors}">
                                        <vl-input-field name="CRM casenummer" v-model="crmCaseNumber" :mod-error="hasErrors" mod-block />
                                    </kl-field-validation-wrapper>
                                </vl-column>
                                <vl-column>
                                    <vl-typography>Om KLIP te kunnen bekijken als deze gebruiker dient de gebruiker hiervoor eerst toestemming te geven.</vl-typography>
                                </vl-column>
                                <vl-column>
                                    <vl-action-group>
                                        <vl-button type="submit" :mod-disabled="disableSubmit" :mod-loading="isSendingRequestForConsent" mod-large mod-primary>Verzend toestemmingsaanvraag</vl-button>
                                        <vl-button class="vl-button--link" @click="cancelStartNewImpersonatingSession" icon="cross" mod-icon-before>Annuleer</vl-button>
                                    </vl-action-group>
                                </vl-column>
                            </template>
                        </vl-form-grid>
                    </form>
<!--                </vl-form-validation-observer>-->
            </div>
        </vl-column>
        <vl-column v-else-if="activeImpersonation.impersonationStatus === ImpersonationStatus.Pending">
            <div class="border">
                <vl-form-grid mod-stacked>
                    <vl-column width="8">
                        <strong>CRM case {{ activeImpersonation.crmCaseNumber }}</strong> - Toestemming aangevraagd op {{  formatDate(activeImpersonation.askedForConsentAt) }}
                    </vl-column>
                    <vl-column width="4" v-vl-align:right>
                        <vl-button mod-secondary @click="refresh" mod-small icon="text-redo" mod-icon-before>Refresh pagina</vl-button>
                    </vl-column>
                    <vl-column>
                        <vl-typography>Zorg ervoor dat de gebruiker zich aanmeldt en de toestemming goedkeurt.</vl-typography>
                    </vl-column>
                </vl-form-grid>
            </div>
        </vl-column>
        <vl-column v-else-if="activeImpersonation.impersonationStatus === ImpersonationStatus.Consent">
            <div class="border">
                <vl-form-grid mod-stacked>
                    <vl-column width="8">
                        <strong>CRM case {{  activeImpersonation.crmCaseNumber }}</strong> - Toestemming verleend op {{ formatDate(activeImpersonation.consentAt) }}.
                    </vl-column>
                    <vl-column>
                        <vl-typography>
                            De gebruiker heeft toestemming verleend om de impersonation op te starten. Als je de impersonation start kan je exact 1u lang aanmelden met deze gebruiker.
                        </vl-typography>
                    </vl-column>
                    <vl-column>
                        <vl-action-group>
                            <vl-button @click="startImpersonation" :mod-disabled="startingImpersonation" :mod-loading="startingImpersonation" mod-primary mod-large>Start impersonation</vl-button>
                            <vl-button class="vl-button--link" @click="cancelImpersonationSession"  icon="cross" mod-icon-before>Annuleer</vl-button>
                        </vl-action-group>
                    </vl-column>
                </vl-form-grid>
            </div>
        </vl-column>
        <vl-column v-else-if="activeImpersonation.impersonationStatus === ImpersonationStatus.Revoked">
            <div class="border">
                <strong>CRM case {{  activeImpersonation.crmCaseNumber }}</strong> - Aanvraag afgekeurd op {{formatDate(activeImpersonation.revokedAt)}}
            </div>
        </vl-column>
        <vl-column v-else-if="activeImpersonation.impersonationStatus === ImpersonationStatus.Started">
            <div class="border">
                <vl-form-grid mod-stacked>
                    <vl-column width="8">
                        <strong>CRM case {{ activeImpersonation.crmCaseNumber }}</strong> - Gestart op {{ formatDate(activeImpersonation.startedAt) }}.
                    </vl-column>
                    <vl-column v-if="activeImpersonation.login">
                        <vl-typography>
                            Om de impersonation te starten open je een <strong>nieuw incognitovenster</strong>  en navigeer je naar {{identityServerUrl}} <vl-link mod-icon-only icon="copy-paste" @click="copyImpersonationHyperlink" v-vl-tooltip="'kopieer impersonation link'" hidden-text='kopieer impersonation link' />. In de dropdown kan je dan de gebruiker met onderstaande gebruikersnaam selecteren.
                        </vl-typography>
                        <vl-typography><strong>LET OP</strong>: gebruikersnaam en wachtwoord worden slechts 1x getoond en niet bewaard.
                            Gebruik deze gegevens enkel voor jezelf en geef deze niet door aan anderen.
                        </vl-typography>
                    </vl-column>
                    <vl-column v-if="activeImpersonation.login">
                        <kl-description-list  class="audit-log">
                            <kl-description-list-row label="Gebruikersnaam:">
                                {{ activeImpersonation.login.userName }} &nbsp; <vl-link mod-icon-only icon="copy-paste" @click="copyUserName" v-vl-tooltip="'kopieer gebruikersnaam'" hidden-text='Kopieer gebruikersnaam' />
                            </kl-description-list-row>
                            <kl-description-list-row label="Paswoord:">
                                {{ activeImpersonation.login.password }} <vl-link mod-icon-only icon="copy-paste" @click="copyPassword" v-vl-tooltip="'kopieer paswoord'" hidden-text='Kopieer paswoord' />
                            </kl-description-list-row>
                        </kl-description-list>
                    </vl-column>
                    <vl-column v-if="!activeImpersonation.login">
                        <vl-typography>De impersonation sessie is gestart. Deze is nog geldig tot {{validUntil(activeImpersonation.startedAt)}}.</vl-typography>
                    </vl-column>
                    <vl-column v-if="activeImpersonation.audit && activeImpersonation.audit.length > 0">
                        <vl-accordion
                            title="Auditlog"
                            title-tag-name="h2"
                            title-tag-style="h3">
                            <kl-description-list v-for="audit in activeImpersonation.audit" :key="audit.eventDate" class="audit-log">
                                <kl-description-list-row :label="formatDate(audit.eventDate)">{{ audit.eventDescription }}</kl-description-list-row>
                            </kl-description-list>
                        </vl-accordion>
                    </vl-column>
                </vl-form-grid>
            </div>
        </vl-column>
        <vl-column width-s="0" width-m="4" width-l="6" v-vl-visibility:hide.s></vl-column>
        <vl-column v-for="history in impersonationHistory" :key="history.id">
            <div class="border">
                <vl-form-grid mod-stacked>
                    <vl-column width="8">
                        <strong>CRM case {{  history.crmCaseNumber }}</strong> - {{ impersonationHistoryStatusText(history) }}
                    </vl-column>
                    <vl-column v-if="history.audit && history.audit.length > 0">
                        <vl-accordion
                            title="Auditlog"
                            title-tag-name="h2"
                            title-tag-style="h3">
                            <kl-description-list v-for="audit in history.audit" :key="audit.eventDate" class="audit-log">
                                <kl-description-list-row :label="formatDate(audit.eventDate)">{{ audit.eventDescription }}</kl-description-list-row>
                            </kl-description-list>
                        </vl-accordion>
                    </vl-column>
                </vl-form-grid>
            </div>
            <vl-u-spacer mod-medium/>
        </vl-column>
    </vl-form-grid>
</vl-region>
