<div class="kl-contact-card">
    <header role="none" class="kl-contact-card__header">
        <template v-if="$slots.operations">
            <slot name="operations"></slot>
        </template>
        <template v-else>
            <vl-title :tag-name="titleTag" tag-style="h6" class="kl-contact-card__title" v-html="title" />
            <p v-if="subtitle" class="kl-contact-card__subtitle" v-html="subtitle" />
        </template>
    </header>
    <address class="kl-contact-card__address" v-if="cleanedAddress" v-html="cleanedAddress" />
    <ul class="kl-contact-card__options">
        <li class="kl-contact-card__options__item" v-if="phone">
            <vl-link :href="`tel:${phone}`" icon="phone" mod-icon-before v-if="modPhoneClickable">{{ phone }}</vl-link>
            <ippro-icon-text icon="phone" v-else>{{ phone }}</ippro-icon-text>
        </li>
        <li class="kl-contact-card__options__item" v-if="email">
            <vl-link :href="emailLink ? emailLink : `mailto:${email}`" icon="mail" mod-icon-before v-if="emailLink || modEmailClickable">{{ email }}</vl-link>
            <ippro-icon-text icon="mail" v-else>{{ email }}</ippro-icon-text>
        </li>
    </ul>
    <p class="kl-contact-card__message" v-if="message" v-html="message" />
    <p class="kl-contact-card__emergency" v-if="emergencyPhone" v-vl-text.bold.error>Bij noodgeval: <vl-icon icon="phone" class="kl-contact-card__emergency__icon" />{{ emergencyPhone }}</p>
</div>