<div class="kl-maprequest-download">
    <vl-form-grid mod-v-center mod-stacked>
        <vl-column width-xs="12" width="5">
            <vl-title tag-name="span" tag-style="h6">Planaanvraagzone</vl-title>
        </vl-column>
        <vl-column width-xs="12" width="7">
            <vl-input-group>
                <vl-select name="download-filetype" id="download-filetype" v-model="downloadFiletype" @input="downloadFiletypeChanged" mod-no-placeholder mod-block>
                    <option :value="label" v-for="(url, label) in downloadUrls" :key="label">
                        {{ label }}
                    </option>
                </vl-select>
                <a :href="downloadFileUri" v-if="downloadFileUri && downloadFiletype" class="vl-button vl-button--narrow" download>Download</a>
            </vl-input-group>
        </vl-column>
    </vl-form-grid>
</div>