<form @submit.prevent="search" class="kl-search-zones vl-form" v-kl-u-background-alt v-kl-u-box-padding>
    <vl-form-grid mod-stacked mod-v-center>
        <vl-column width-s="12" width-m="3" width-l="9">
            <vl-checkbox v-model="onlySearchActiveOrganisations" mod-block>Toon enkel de actieve organisaties</vl-checkbox>
        </vl-column>        
        <vl-column v-vl-visually-hidden>
            <vl-action-group>
                <vl-button type="submit" v-vl-visually-hidden>Zoeken</vl-button>
            </vl-action-group>
        </vl-column>
    </vl-form-grid>
</form>