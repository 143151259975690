<form @submit.prevent="search" class="kl-search-invoices vl-form" v-kl-u-background-alt v-kl-u-box-padding>
    <vl-form-grid mod-stacked mod-v-center>
        <vl-column width-s="12" width-m="9" width-l="6">
                <vl-form-grid mod-stacked>
                    <vl-column width="6">
                        <label for="date-from">van</label>
                        <kl-datepicker visual-format="d/m/Y" v-model="dateFrom" :default-date="selectedFromDate" :max-date="dateFromMaxDate"/>
                    </vl-column>
                    <vl-column width="6">
                        <label for="date-to">tot</label>
                        <kl-datepicker visual-format="d/m/Y" v-model="dateTo" :default-date="selectedToDate" :min-date="dateToMinDate"/>
                    </vl-column>
                </vl-form-grid>
        </vl-column>
        <vl-column v-vl-visually-hidden>
            <vl-action-group>
                <vl-button type="submit" v-vl-visually-hidden>Zoeken</vl-button>
            </vl-action-group>
        </vl-column>
    </vl-form-grid>
</form>
