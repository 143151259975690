<!--<vl-form-validation-observer v-slot="{ handleSubmit, invalid, pristine }">-->
    <form class="vl-form" @submit="onSubmit">
        <vl-modal id="una-zone-move-modal" mod-medium :closable="true">
            <vl-title tag-name="h3">Verplaats zone</vl-title>
            <vl-form-grid mod-stacked>
                <vl-column>
                    <kl-field-validation-wrapper :field="selectedDestinationOrganisationField" #default="{hasErrors}" mod-required>
                        <kl-search-organisation @selected="onOrganisationSelect" v-model="selectedDestinationOrganisation" :mod-error="hasErrors" :show-id="true" :only-include-una-organisations="true" />
                    </kl-field-validation-wrapper>
                </vl-column>
                <vl-column v-if="hasDestinationOrganisation">
                    <div v-kl-u-background-alt v-kl-u-box-padding>{{ destinationOrganisation.namePath }}</div>
                </vl-column>
                <vl-column>
                    <kl-field-validation-wrapper :field="reasonField" #default="{hasErrors}" mod-required>
                        <vl-textarea rows="6" name="reason" id="reason" v-model="reason" :mod-error="hasErrors" mod-block />
                    </kl-field-validation-wrapper>
                </vl-column>
                <vl-column>
                    <kl-field-validation-wrapper :field="mailToField" #default="{hasErrors}" mod-required>
                        <vl-textarea rows="6" name="mailTo" id="mailTo" v-model="mailTo" :mod-error="hasErrors" mod-block />
                    </kl-field-validation-wrapper>
                </vl-column>
            </vl-form-grid>

            <template v-slot:modal-footer>
                <vl-action-group>
                    <vl-button type="submit" mod-large :mod-loading="movingZone" :mod-disabled="disableSubmit">Verplaats zone</vl-button>
                    <vl-button class="vl-button--link" @click.prevent="onCancelMoveUnaZone" icon="cross" mod-icon-before>Annuleer</vl-button>
                </vl-action-group>
            </template>
        </vl-modal>
    </form>
<!--</vl-form-validation-observer>-->
