import userLocalStorage from '@/storage/user-local-storage';
import { UnaMapRequest } from '../../una-routes';
import { MapRequestSearchField, SearchOptions } from '@/app/shared/components/kl-search-maprequests/kl-search-maprequests';
import * as UnaModel from '../../shared/una-model';
import * as KlipApi from '@/api/klip-api.proxy';
import { escape } from 'html-escaper';
import {computed, defineComponent, onMounted, reactive, ref, watch} from 'vue';
import {useRouter} from '@/router/router-vue3';
import KlSearchMaprequests from '@/app/shared/components/kl-search-maprequests/kl-search-maprequests.vue';
import {useRoute} from '@/router/router-vue3';
import {useKlipApiProxy} from '@/plugins/proxy-client';
import {handleAbortError, KlAbortController} from '@/app/shared/helpers/kl-abort-controller';
import dayjs from "dayjs";
import {componentRef} from '@/app/shared/helpers/vue-typescript-util';

export default defineComponent({
    name: 'KlUnaReplied',
    emits: ['update-query'],
    props: {
        data: {
            type: Object as () => UnaModel.IMapRequestsReplied,
            required: true,
        }
    },
    setup(props, { emit }) {

        const router = useRouter();
        const route = useRoute();

        const _una_GetMapRequestsReplied_AbortController = new KlAbortController();
        const _una_GetMapRequestsRepliedCount_AbortController = new KlAbortController();

        const searchMaprequests = ref<InstanceType<typeof KlSearchMaprequests>>(null);

        const searchOptionLocalStorageKey = 'una_search_replied_pa_organisation_id';

        const defaultFilter = ref<MapRequestSearchField>(new MapRequestSearchField());

        const mapRequests = ref<KlipApi.IMapRequestRepliedListItem[]>(null);
        const mapRequestsColumns = ref<KlipApi.IColumn[]>([]);
        const mapRequestsFetching = ref<boolean>(false);
        const mapRequestsInput = ref<KlipApi.IGetMapRequestsRepliedInput>(null);
        const searchUsed = ref<boolean>(false);
        const showDownloadLink = ref<boolean>(false);
        const downloadFileUriLoading = ref<boolean>(false);
        const downloadFileUri = ref<string>(null);
        const selectedZone = ref<string>('all');
        const initialMappingFromQuery = ref<boolean>(true);

        const mapRequestsMeta = reactive<{ totalRows: number, resultsPerPage: number, currentPage: number | undefined }>({ totalRows: 0, resultsPerPage: 50, currentPage: 1 });


        const defaultSearchOption = computed(() => {
            return !!props.data.defaultSearchOption
                ? props.data.defaultSearchOption
                : props.data && props.data.getMapRequestsInput && props.data.getMapRequestsInput.searchOption
                    ? props.data.getMapRequestsInput.searchOption.toString()
                    : null;
        });

        const isArchived = computed(() => {
            return mapRequestsInput.value.isArchived;
        });

        const downloadButtonTagName = computed(() => {
            return downloadFileUri.value ? 'a' : 'button';
        });


        const switchToArchived = () => {
            componentRef<InstanceType<typeof KlSearchMaprequests>>(searchMaprequests).value.searchArchive = 'true';
        }

        const pagerClicked = (page: number) => {
            mapRequestsFetching.value = true;
            mapRequestsMeta.currentPage = page;
            mapRequestsInput.value.offset = mapRequestsMeta.resultsPerPage * (page - 1);
            _updateQuery();
        }

        const columnClicked = (column: KlipApi.IColumn) => {
            mapRequestsFetching.value = true;
            mapRequestsInput.value.offset = 0;
            mapRequestsInput.value.orderField = KlipApi.MapRequestsRepliedOrderField[column.key[0].toUpperCase() + column.key.substr(1) as keyof typeof KlipApi.MapRequestsRepliedOrderField];
            mapRequestsInput.value.orderDirection = column.direction;
            _updateQuery();
            _fetchMapRequests();
        }

        const rowClicked = (row: IRow) => {
            if (row.mapRequestId && row.unaZoneId) {
                const mapRequest = mapRequests.value.find((request) => request.mapRequestId === row.mapRequestId && request.unaZoneId === row.unaZoneId);
                router.push({ name: UnaMapRequest.name, params: { id: mapRequest.mapRequestId, archived: mapRequest.isArchived ? 'true' : 'false', zoneId: mapRequest.unaZoneId } });
            }
        }

        const _parseCell = (row: IRow, column: IColumn) => {
            const mapRequest = mapRequests.value.find((request) => request.mapRequestId === row.mapRequestId && request.unaZoneId === row.unaZoneId);
            const cellValue = escape(row[column.key])
            let cellTemplate = `<span>${cellValue}</span>`;

            if (mapRequest) {
                cellTemplate = `<kl-router-link :to='${JSON.stringify(UnaMapRequest)}' :params='{id:"${mapRequest.mapRequestId}", zoneId:"${mapRequest.unaZoneId}", archived:"${mapRequest.isArchived}"}'>${cellValue}</kl-router-link>`;
            }
            return {
                template: cellTemplate,
            };
        }

        const clearSearch = () => {
            componentRef<InstanceType<typeof KlSearchMaprequests>>(searchMaprequests).value.reset();
        }

        const _setSearchOptionToLocalStorage = (searchField: MapRequestSearchField) => {
            const isSubOrgSelected = isNaN(+searchField.searchOption);
            userLocalStorage.setItem(searchOptionLocalStorageKey, isSubOrgSelected ? searchField.organisationId : searchField.searchOption);
        }

        const search = (searchFields: MapRequestSearchField) => {
            if (!initialMappingFromQuery.value) {
                searchUsed.value = true;
                mapRequestsFetching.value = true;
                mapRequestsInput.value = {
                    offset: 0,
                    limit: mapRequestsInput.value.limit,
                    orderDirection: mapRequestsInput.value.orderDirection,
                    orderField: mapRequestsInput.value.orderField,
                    query: searchFields.keyword,
                    searchField: KlipApi.SearchField[searchFields.searchField as keyof typeof KlipApi.SearchField],
                    organisationId: searchFields.organisationId,
                    searchOption: isNaN(+searchFields.searchOption) ? KlipApi.SearchOption.SubOrganisation : +searchFields.searchOption as KlipApi.SearchOption,
                    isArchived: searchFields.archive,
                    utilityNetworkAuthorityZoneId: selectedZone.value === 'all' ? null : selectedZone.value,
                    startDate: searchFields.date.from && searchFields.date.from[0] ? dayjs(searchFields.date.from[0]).toISOString() : null,
                    endDate: searchFields.date.to && searchFields.date.to[0] ? dayjs(searchFields.date.to[0]).toISOString() : null,
                    zoneGeometry: searchFields.location ? JSON.stringify(searchFields.location) : null,
                };
                mapRequestsMeta.currentPage = 1;
                _setSearchOptionToLocalStorage(searchFields);
                _updateQuery();
            }
            initialMappingFromQuery.value = false;
        }

        const _updateQuery = () => {
            if (route.hash === '#replied') {
                emit('update-query', { input: mapRequestsInput.value, hash: '#replied' });
            }
        }

        const download = () => {
            downloadFileUriLoading.value = true;
            const input = KlipApi.GetMapRequestsRepliedInput.fromJS(mapRequestsInput.value);
            useKlipApiProxy().una_DownloadMapRequestsReplied(input)
                .then((response: KlipApi.FileResponse) => {
                    const fileURL = URL.createObjectURL(response.data);
                    downloadFileUri.value = fileURL;
                    const link = document.createElement('a');
                    link.href = fileURL;
                    link.download = response.fileName;
                    link.click();
                    URL.revokeObjectURL(link.href);
                })
                .finally(() => {
                    downloadFileUriLoading.value = false;
                });
        }

        const _resetDownloadLink = () => {
            downloadFileUri.value = null;
        }


        onMounted(() => {
            mapRequestsColumns.value = props.data.mapRequestColumns.map((column) => ({
                ...column,
                parser: _parseCell,
            }));
            defaultFilter.value = {
                searchField: props.data.getMapRequestsInput.searchField ? KlipApi.SearchField[props.data.getMapRequestsInput.searchField] : KlipApi.SearchField[KlipApi.SearchField.MyReference],
                searchOption: props.data.getMapRequestsInput.searchOption.toString(),
                organisationId: props.data.getMapRequestsInput.organisationId,
                keyword: props.data.getMapRequestsInput.query,
                date: {
                    from: props.data.getMapRequestsInput.startDate ? [props.data.getMapRequestsInput.startDate] : null,
                    to: props.data.getMapRequestsInput.endDate ? [props.data.getMapRequestsInput.endDate] : null,
                },
                archive: props.data.getMapRequestsInput.isArchived ? props.data.getMapRequestsInput.isArchived : false,
                location: mapRequestsInput.value.zoneGeometry ? JSON.parse(mapRequestsInput.value.zoneGeometry) : null,
            } as MapRequestSearchField;
        });

        const _fetchMapRequestsCount = () => {
            const input = KlipApi.GetMapRequestsRepliedInput.fromJS(mapRequestsInput.value);
            useKlipApiProxy().una_GetMapRequestsRepliedCount(input, _una_GetMapRequestsRepliedCount_AbortController.abortAndCreateNewSignal())
                .then((response) => {
                    mapRequestsMeta.totalRows = response.result;
                })
                .catch(handleAbortError);
        }

        const _fetchMapRequests = () => {
            if (mapRequestsInput.value && SearchOptions) {
                mapRequestsFetching.value = true;
                mapRequestsColumns.value = (mapRequestsColumns.value || []).map((column) => ({
                    ...column,
                    isVisible: !(mapRequestsInput.value.searchOption === KlipApi.SearchOption.Organisation && column.key === 'organisation'),
                }));


                const input = KlipApi.GetMapRequestsRepliedInput.fromJS(mapRequestsInput.value);

                useKlipApiProxy().una_GetMapRequestsReplied(input, _una_GetMapRequestsReplied_AbortController.abortAndCreateNewSignal())
                    .then((maprequestsResponse) => {
                        mapRequests.value = maprequestsResponse.result.mapRequests;
                    })
                    .catch(handleAbortError)
                    .finally(() => {
                        mapRequestsFetching.value = false;
                        showDownloadLink.value = !!mapRequests.value.length;
                    });
            }
        }


        watch(
            () => props.data,
            (newData: UnaModel.IMapRequestsReplied) => {
                if (newData) {
                    mapRequestsMeta.currentPage = Math.ceil(newData.getMapRequestsInput.offset / mapRequestsMeta.resultsPerPage) + 1;
                    mapRequestsInput.value = newData.getMapRequestsInput;
                }
            },
            { immediate: true, deep: true })

        watch(
            mapRequestsInput,
            (newMapRequestsInput: KlipApi.IGetMapRequestsRepliedInput) => {
                if (!newMapRequestsInput) {
                    return;
                }
                _resetDownloadLink();
                _fetchMapRequestsCount();
                _fetchMapRequests();
            },
            {immediate: true, deep: true})

        watch(
            selectedZone,
            (newZoneId: string) => {
                if (!newZoneId) {
                    return;
                }
                mapRequestsInput.value.utilityNetworkAuthorityZoneId = newZoneId === 'all' ? null : newZoneId;
                mapRequestsInput.value.offset = 0;
                mapRequestsInput.value.limit = mapRequestsMeta.resultsPerPage;
                mapRequestsMeta.currentPage = 1;
                _updateQuery();
            },
            {immediate: true, deep: true})


        return {
            searchMaprequests,

            defaultSearchOption,
            mapRequests,
            mapRequestsMeta,
            mapRequestsColumns,
            mapRequestsFetching,
            mapRequestsInput,
            searchUsed,
            showDownloadLink,
            downloadFileUriLoading,
            downloadFileUri,
            selectedZone,
            initialMappingFromQuery,
            defaultFilter,
            downloadButtonTagName,
            isArchived,
            switchToArchived,
            pagerClicked,
            columnClicked,
            rowClicked,
            clearSearch,
            search,
            download,
        };
    }
})
