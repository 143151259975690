import { EditSubOrganisation } from '@/app/settings/components/kl-organisation-settings/kl-organisation-routes';
import {useSettingsStore} from '@/app/settings/settings-store';
import * as KlipApi from '@/api/klip-api.proxy';
import {computed, defineComponent, onMounted, ref, watch} from 'vue';
import {useKlipApiProxy} from '@/plugins/proxy-client';
import {useRouter} from '@/router/router-vue3';
import {useField, useForm} from 'vee-validate';
import {klValidateRules} from '@/app/shared/components/kl-form-fields/kl-field-validation-wrapper.vue';

export default defineComponent({
    name: 'KlUnaCreateEditZone',
    props: {
        zoneId: { type: String, default: '' },
        organisationId: { type: String, default: '' },
    },
    setup(props) {

        // const validationObserver = ref<InstanceType<typeof ValidationObserver>>(null);

        const router = useRouter();

        const namespaceUrl: string = import.meta.env.VITE_LINKS_NAMESPACE;
        const overlayUrl: string = import.meta.env.VITE_LINKS_OVERLAY;
        const helpdeskMail: string = import.meta.env.VITE_EMAIL_HELPDESK;
        const zoneNameValidationRegex = /^[a-zA-Z0-9\-_ .+!()&ö/:,è|'@\s]*$/;
        const namespaceRegex = /^[0-9a-zA-Z-_]{5,50}$/;


        const form = useForm();
        const disableSubmit = computed((): boolean => {
            return !form.meta.value.valid || !form.meta.value.dirty;
        });

        const nameField = useField<string>('Naam zone', { 'required': true, 'max': 90, 'regex': zoneNameValidationRegex });
        const mailDestinationField = useField<string>('Stuur planaanvragen naar e-mail', 'required|validKlipEmail');
        const mailSettingsField = useField('mailSettings');
        const namespaceField = useField<string>(
            'Namespace',
            (newValue, params) => klValidateRules({ 'required': true, 'regex': namespaceRegex, 'uniqueNamespace': [props.zoneId] }, newValue, params))
        const contactNameField = useField<string>('Naam contact', 'required|max:100');
        const contactPhoneField = useField<string>('Telefoon', 'required|phone');
        const contactEmailField = useField<string>('Email', 'required|validKlipEmail');
        const contactMessageField = useField<string>('Boodschap voor de planaanvrager', 'max:400');
        const contactPhoneEmergencyField = useField<string>('Telefoon in geval van nood', 'required|max:20|phone_with_112');

        const name = nameField.value;
        const mailDestination = mailDestinationField.value;
        const namespace = namespaceField.value;
        const contact = ref({
            name: '',
            phone: '',
            email: '',
            message: '',
            phoneEmergency: '',
        });

        watch(() => contact.value.name, (newValue: string) => { contactNameField.value.value = newValue; });
        watch(() => contact.value.phone, (newValue: string) => { contactPhoneField.value.value = newValue; });
        watch(() => contact.value.email, (newValue: string) => { contactEmailField.value.value = newValue; });
        watch(() => contact.value.message, (newValue: string) => { contactMessageField.value.value = newValue; });
        watch(() => contact.value.phoneEmergency, (newValue: string) => { contactPhoneEmergencyField.value.value = newValue; });

        const mailSettings = ref({
            confirmation: false,
            confirmationExampleUri: '',
            reminder1DayToRespond: false,
            reminder1DayToRespondExampleUri: '',
            uploadImklSuccess: false,
            uploadImklSuccessExampleUri: '',
            uploadImklFailed: false,
            uploadImklFailedExampleUri: '',
            respondedNotInvolved: false,
            respondedNotInvolvedExampleUri: ''
        });

        // update form.meta.value.dirty flag
        watch(mailSettings, (newValue) => { mailSettingsField.setValue (newValue); }, { deep: true });


        const loading = ref<boolean>(false);
        const isSending = ref<boolean>(false);
        const deletingZone = ref<boolean>(false);
        const deleteButtonFocussed = ref<boolean>(false);
        const deletingGeometry = ref<boolean>(false);
        const addingNewGeometry = ref<boolean>(false);
        const unaDisplayName = ref<string>('');


        const routeEditOrganinsation = computed(() => {
            return EditSubOrganisation;
        });

        const editMode = computed((): boolean => {
            return !!props.zoneId;
        });

        const createMode = computed((): boolean => {
            return !!!props.zoneId;
        });

        const title = computed((): string => {
            if (!!props.zoneId) {
                return 'Bewerk KLIM zone';
            } else {
                return 'Nieuwe KLIM zone';
            }
        });

        const submitButtonLabel = computed((): string => {
            return editMode.value ? 'Bewaar veranderingen' : 'KLIM Zone toevoegen';
        });


        const _submit = async () => {
            isSending.value = true;
            const data = <KlipApi.IUpdateKlimZoneInput | KlipApi.ICreateKlimZoneInput> {
                organisationId: props.organisationId,
                alarmCentralTelephone: contact.value.phoneEmergency,
                contactName: contact.value.name,
                contactEmail: contact.value.email,
                contactTelephone: contact.value.phone,
                extraInformation: contact.value.message,
                mapRequestEmail: mailDestination.value,
                name: name.value,
                namespace: namespace.value,
                sendConfirmation: mailSettings.value.confirmation,
                sendreminder1DayToRespond: mailSettings.value.reminder1DayToRespond,
                sendrespondedNotInvolved: mailSettings.value.respondedNotInvolved,
                senduploadImklFailed: mailSettings.value.uploadImklFailed,
                senduploadImklSuccess: mailSettings.value.uploadImklSuccess,
            };

            if (editMode.value) {
                const fullData = _updateKlimZone(data);
                await useSettingsStore().updateKlimNamespaceZone(fullData);
            }
            else {
                const fullData = _createKlimZone(data);
                await useSettingsStore().createKlimNamespaceZone(fullData);
            }

            isSending.value = false;
            addingNewGeometry.value = false;

            await router.replace({
                name: routeEditOrganinsation.value.name,
                params: {
                    organisationId: props.organisationId,
                }
            });
        }

        const _createKlimZone = (createZoneInput: KlipApi.ICreateKlimZoneInput): KlipApi.CreateKlimZoneInput => {
            return createZoneInput as KlipApi.CreateKlimZoneInput;
        }

        const _updateKlimZone = (createZoneInput: KlipApi.IUpdateKlimZoneInput): KlipApi.UpdateKlimZoneInput => {
            const klimData = {
                ...createZoneInput,
                id: props.zoneId,
            } as KlipApi.UpdateKlimZoneInput;
            return klimData;
        }


        const _onIdChange = (newZoneId: string) => {
            if (!!newZoneId) {
                loading.value = true;
                useKlipApiProxy().unaSettings_GetUnaZoneEditView(newZoneId, props.organisationId)
                    .then((response) => {
                        if (!response.result.id) {
                            return;
                        }

                        unaDisplayName.value = response.result.unaName;
                        name.value = response.result.zoneName;
                        mailDestination.value = response.result.mapRequestEmail;
                        namespace.value = response.result.namespace;
                        contact.value = {
                            name: response.result.contactName,
                            phone: response.result.contactTelephone,
                            email: response.result.contactEmail,
                            message: response.result.extraInformation,
                            phoneEmergency: response.result.alarmCentralTelephone,
                        };
                        mailSettings.value = {
                            confirmation: response.result.confirmation,
                            confirmationExampleUri: response.result.confirmationExampleUri,
                            reminder1DayToRespond: response.result.reminder1DayToRespond,
                            reminder1DayToRespondExampleUri: response.result.reminder1DayToRespondExampleUri,
                            uploadImklFailed: response.result.uploadImklFailed,
                            uploadImklFailedExampleUri: response.result.uploadImklFailedExampleUri,
                            uploadImklSuccess: response.result.uploadImklFailed,
                            uploadImklSuccessExampleUri: response.result.uploadImklSuccessExampleUri,
                            respondedNotInvolved: response.result.respondedNotInvolved,
                            respondedNotInvolvedExampleUri: response.result.respondedNotInvolvedExampleUri
                        };
                        loading.value = false;
                    }).finally(async () => {
                        form.resetForm({ values: form.values });
                    });
            } else {
                loading.value = true;
                useKlipApiProxy().unaSettings_GetUnaZoneCreateView()
                    .then((response) => {
                        mailSettings.value = {
                            confirmation: true,
                            confirmationExampleUri: response.result.confirmationExampleUri,
                            reminder1DayToRespond: false,
                            reminder1DayToRespondExampleUri: response.result.reminder1DayToRespondExampleUri,
                            uploadImklFailed: false,
                            uploadImklFailedExampleUri: response.result.uploadImklFailedExampleUri,
                            uploadImklSuccess: false,
                            uploadImklSuccessExampleUri: response.result.uploadImklSuccessExampleUri,
                            respondedNotInvolved: false,
                            respondedNotInvolvedExampleUri: response.result.respondedNotInvolvedExampleUri
                        };
                        loading.value = false;
                    });
            }
        }

        watch(
            () => props.zoneId,
            _onIdChange,
            { immediate: true });


        onMounted(() => {
            addingNewGeometry.value = !!createMode.value;
        });

        const _onInvalidSubmit = (submittedValues) => {
            console.log('_onInvalidSubmit', submittedValues);
        }

        const onSubmit = form.handleSubmit(_submit, _onInvalidSubmit);

        return {
            nameField,
            mailDestinationField,
            namespaceField,
            contactNameField,
            contactPhoneField,
            contactEmailField,
            contactMessageField,
            contactPhoneEmergencyField,

            namespaceUrl,
            overlayUrl,
            helpdeskMail,
            zoneNameValidationRegex,
            namespaceRegex,

            name,
            mailDestination,
            namespace,
            contact,
            mailSettings,
            loading,
            isSending,
            deletingZone,
            deleteButtonFocussed,
            deletingGeometry,
            addingNewGeometry,
            unaDisplayName,

            routeEditOrganinsation,
            editMode,
            createMode,
            title,
            submitButtonLabel,

            onSubmit,
            disableSubmit,
        }
    }
})
