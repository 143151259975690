<div>
  <vl-region class="info" v-if="loading">
    <vl-layout>
      <div v-vl-align:center>
        <vl-loader message="De gegevens worden geladen. Even geduld, a.u.b." />
      </div>
    </vl-layout>
  </vl-region>
  <vl-region class="info" v-if="error">
    <vl-layout>
      <div v-vl-align:center>
        Er ging iets mis met het ophalen van de gegevens.
      </div>
    </vl-layout>
  </vl-region>
  <div>
    <div class="viewer-container">
      <!-- viewer -->
      <div id="viewer"></div>
    </div>
  </div>
</div>