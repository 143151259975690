import axios from 'axios';
import userLocalStorage from '@/storage/user-local-storage';
import { EnvelopeOfMriViewerViewModel, EnvelopeOfUnaViewerViewModel, EnvelopeOfImklTestViewerViewModel, MapRequestPmklMapAssetsStatus, MapRequestPrintView, PrintMapRequestInput } from '@/api/klip-api.proxy';
import { ViewerPrintModel } from './kl-viewer-print-model';
import {EEventType, useSignalrHub} from '@/plugins/signalr';
import { useErrorStore } from '@/stores/error-store';
import { Viewer, IViewerOptions, IPrintRequestData, IPrintMapRequestInput, IPrintDefaults } from '@klip/gisvl.klip.viewer';
import {defineComponent, onBeforeUnmount, onMounted, ref} from 'vue';
import {useKlipApiProxy} from '@/plugins/proxy-client';
import {useMetaStore} from '@/stores/meta-store';

export enum ViewerTypes {
    Mri = 0,
    Una = 1,
    ImklTest = 2,
}

export default defineComponent({
    name: 'KlViewer',
    props: {
        mapRequestId: {
            type: String,
            default: null,
        },
        zoneId: {
            type: String,
            default: null,
        },
        imklFileId: {
            type: String,
            default: null,
        },
    },
    setup(props) {

        const loading = ref<boolean>(true);
        const error = ref<boolean>(false);

        let _type: ViewerTypes;
        let _downloadImklAttachment: string;
        let _downloadPrintResult: ViewerPrintModel[];

        let viewer: Viewer = null;

        const downloadPreferenceLocalStorageKey: string = 'viewer_download_preference';
        const printRequestDefaultsKey: string = 'viewer_printrequest_preference';

        onMounted(() => {
            _determineViewerType();
            if (_type === ViewerTypes.Una) {
                useKlipApiProxy().una_GetViewer(props.mapRequestId, props.zoneId).then((response) => parseData(response));
            } else if (_type === ViewerTypes.Mri) {
                useKlipApiProxy().mri_GetViewer(props.mapRequestId).then((response) => parseData(response));
            } else if (_type === ViewerTypes.ImklTest) {
                useKlipApiProxy().imklTest_GetViewer(props.imklFileId).then((response) => parseData(response));
            }

            if (_type === ViewerTypes.Mri) {
                useSignalrHub().subscribeToMapRequestNotifications(props.mapRequestId);
                useSignalrHub().subscribeTo(EEventType.Printstatus, _loadPrintData);
            }

            const parseData = async (response: EnvelopeOfMriViewerViewModel | EnvelopeOfUnaViewerViewModel | EnvelopeOfImklTestViewerViewModel) => {
                if (response.result.mapRequestReference) {
                    useMetaStore().setPageTitle(response.result.mapRequestReference);
                }

                const options: IViewerOptions = {
                    selector: '#viewer',
                    contentRoot: '/viewer/',
                    onStart: () => {
                        _initViewer(response);
                    },
                    attachmentLinkGenerator: async (fileName: string, fileId: string): Promise<string> => {
                        const link = _downloadImklAttachment.replace(/{fileid}/gi, fileId);
                        return link;
                    },
                    isOfflineViewer: false,
                    showOpenFile: false,
                    showWarningsPanel: true,
                    enableGeolocationInfo: false,
                    enableMeasureTool: _type !== ViewerTypes.ImklTest,
                    isKlbMode: _type === ViewerTypes.Una,
                    showGrbBaseLayer: _type === ViewerTypes.ImklTest,
                    showLayerToggleButton: true,
                    showMaprequestPanel: _type === ViewerTypes.Mri,
                    downloadZoneData: _getDownloadZoneData(response),
                    downloadKlipPackageData: _getDownloadKlipPackageData(response),
                    downloadPrintData: _getDownloadPrintData(response),
                    printRequestData: _getPrintRequestData(),
                    jsTree: '/jstree/',
                    klipHelpUrl: import.meta.env.VITE_LINKS_KLIPAPPURL,
                    statusHelpUrl: import.meta.env.VITE_LINKS_MAPREQUESTSTATUSHELP,
                };

                viewer = new Viewer(options);
            };
        });

        onBeforeUnmount(() => {
            useSignalrHub().unSubscribeFrom(EEventType.Printstatus);
            useSignalrHub().unSubscribeFromMapRequestNotifications(props.mapRequestId);
        });

        const _initViewer = (response: EnvelopeOfMriViewerViewModel | EnvelopeOfUnaViewerViewModel | EnvelopeOfImklTestViewerViewModel) => {
            _getMapData(response).then((data) => {
                if (data && data.pmkl) {
                    viewer.load(data.pmkl, true, null);
                }
                if (data && data.wfs) {
                    viewer.loadWfs(data.wfs.featureCollection, false);
                }
                if (data && data.kmHm) {
                    viewer.loadKmHm(data.kmHm.featureCollection);
                }
                loading.value = false;
            });

            _loadPrintData();
        }

        const _getMapData = async (response: EnvelopeOfMriViewerViewModel | EnvelopeOfUnaViewerViewModel | EnvelopeOfImklTestViewerViewModel) => {
            if (!response && !response.result) {
                return null;
            }

            const _baseUrl: string = import.meta.env.VITE_BASE_URI;

            if (_type === ViewerTypes.Una || _type === ViewerTypes.Mri) {
                const result = _type === ViewerTypes.Una
                    ? (response as EnvelopeOfUnaViewerViewModel).result
                    : (response as EnvelopeOfMriViewerViewModel).result;
                _downloadImklAttachment = result.downloadImklAttachmentUrl;

                const pmklCall = axios.get(_baseUrl + result.getPmklUrl);
                const wfsCall = axios.get(_baseUrl + result.getWfsUrl);

                try {
                    const [pmklResponse, wfsResponse] = await Promise.all([pmklCall, wfsCall]);

                    let pmkl = null;
                    let wfs = null;
                    let kmHm = null;

                    if (pmklResponse.data.result) {
                        pmkl = JSON.parse(pmklResponse.data.result);
                    }
                    if (wfsResponse.data.result) {
                        wfs = JSON.parse(wfsResponse.data.result[0]);
                        kmHm = JSON.parse(wfsResponse.data.result[1]);
                    }
                    return {
                        pmkl,
                        wfs,
                        kmHm,
                    };
                } catch {
                    error.value = true;
                }
            } else {
                const result = (response as EnvelopeOfImklTestViewerViewModel).result;
                const pmklCall = axios.get(_baseUrl + result.getPmklUrl);
                _downloadImklAttachment = result.downloadImklTestAttachmentUrl;

                try {
                    const [pmklResponse] = await Promise.all([pmklCall]);

                    let pmkl = null;

                    if (pmklResponse.data.result) {
                        pmkl = JSON.parse(pmklResponse.data.result);
                    }
                    return {
                        pmkl,
                    };
                } catch {
                    error.value = true;
                }
            }
        }

        const _determineViewerType = () => {
            if (props.mapRequestId) {
                if (props.zoneId) {
                    _type = ViewerTypes.Una;

                } else {
                    _type = ViewerTypes.Mri;
                }
            } else if (props.imklFileId) {
                _type = ViewerTypes.ImklTest;
            }
        }

        const _getDownloadZoneData = (response: EnvelopeOfMriViewerViewModel | EnvelopeOfUnaViewerViewModel) => {
            const key = downloadPreferenceLocalStorageKey;
            if (_type !== ViewerTypes.ImklTest) {
                return {
                    show: true,
                    setDefault(filetype: string) {
                        userLocalStorage.setItem(key, filetype);
                    },
                    links: _getLinks(response),
                };
            } else {
                return { show: false };
            }
        }

        const _getLinks = (response: EnvelopeOfMriViewerViewModel | EnvelopeOfUnaViewerViewModel): any[] => {
            const mapRequestZoneDownloadUrls: { [key: string]: string; } = response.result.mapRequestZoneDownloadUrls;
            const downloadFiletype: string = userLocalStorage.getItem(downloadPreferenceLocalStorageKey) || 'Shape';

            const array: any[] = [];

            for (const key in mapRequestZoneDownloadUrls) {
                if (mapRequestZoneDownloadUrls.hasOwnProperty(key)) {
                    array.push({
                        filetype: key,
                        downloadUrl: mapRequestZoneDownloadUrls[key],
                        isDefault: downloadFiletype === key,
                    });
                }
            }

            return array;
        }

        const _getDownloadKlipPackageData = (response: EnvelopeOfMriViewerViewModel) => {
            if (_type === ViewerTypes.Mri) {
                return {
                    show: true,
                    appInfoUrl: import.meta.env.VITE_LINKS_KLIPAPPURL,
                    downloadUrl: (response as EnvelopeOfMriViewerViewModel).result.downloadPmklFileUrl,
                    // TODO: trigger this event with signalr
                    // updateEvent: 'download-klippackage:update',
                    getIsReady() {
                        return (response as EnvelopeOfMriViewerViewModel).result.assetsStatusData.pmklPackageStatus === MapRequestPmklMapAssetsStatus.Done;
                    },
                    getDownloadSizeInBytes() {
                        return (response as EnvelopeOfMriViewerViewModel).result.assetsStatusData.pmklPackageSizeInBytes;
                    },
                };
            } else {
                return { show: false };
            }
        }

        const _getDownloadPrintData = (response: EnvelopeOfMriViewerViewModel) => {
            if (_type === ViewerTypes.Mri) {
                return {
                    show: true,
                    updateEvent: 'download-prints:update',
                    getData: () => {
                        return _downloadPrintResult;
                    },
                };
            } else {
                return { show: false };
            }
        }

        const _getPrintDefaults = (): IPrintDefaults => {
            const printRequestDefaults: string = userLocalStorage.getItem(printRequestDefaultsKey);
            if (!printRequestDefaults) {
                return null;
            }
            return JSON.parse(printRequestDefaults);
        }

        const _getPrintRequestData = (): IPrintRequestData => {
            if (_type !== ViewerTypes.Mri) {
                return;
            }

            return {
                sendPrintRequest: (printOptions: IPrintMapRequestInput, onSuccess: () => void, onFail: () => void) => {
                    // causes problems because casing is different between IPrintMapRequestInput & PrintMapRequestInput
                    // (bff is case insensitive)
                    // const options: PrintMapRequestInput = PrintMapRequestInput.fromJS(printOptions);
                    const options: PrintMapRequestInput = printOptions as PrintMapRequestInput;
                    useErrorStore().setBypassError(['405']);
                    useKlipApiProxy().mri_PrintMapRequest(options).then(() => {
                        _loadPrintData();
                        if (onSuccess) {
                            onSuccess();
                        }
                    }).catch(() => {
                        if (onFail) {
                            onFail();
                            useErrorStore().resetBypassError();
                        }
                    });
                },
                defaults: _getPrintDefaults(),
                saveDefaults: (defaults: IPrintDefaults) => {
                    userLocalStorage.setItem(printRequestDefaultsKey, JSON.stringify(defaults));
                },
            };
        }

        const _loadPrintData = (message?: IMessage) => {
            if (_type === ViewerTypes.Mri && (message == null || (message != null && message.info === 'print done'))) {
                useKlipApiProxy().mri_GetMapRequestPrints(props.mapRequestId).then((response) => {
                    _downloadPrintResult = response.result.map((result: MapRequestPrintView) => {
                        return new ViewerPrintModel(result);
                    });

                    const event = document.createEvent('HTMLEvents');
                    event.initEvent('download-prints:update', true, true);
                    document.dispatchEvent(event);
                });
            }
        }

        return {
            error,
            loading,
        }
    }
})
