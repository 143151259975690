import {
    mriIndex,
    mriMapRequestCreate,
    mriMapRequest,
    mriViewer,
    mriOverlayCreate,
    mriOverlayDetail,
    mriInvoices,
    mriInvoicesForContact,
    mriShoppingCart,
} from './mri-routes';
import { settings } from '../settings/settings-routes';
import {RouteRecordRaw} from 'vue-router';

const oldRoutes: RouteRecordRaw[] = [
    { path: '/mrioverview', redirect: mriIndex },
    { path: '/newmaprequest', redirect: mriMapRequestCreate },
    { path: '/maprequest/:id', redirect: mriMapRequest },
    { path: '/archivemaprequest/:id', redirect: mriMapRequest },
    { path: '/viewer/:id/mri', redirect: mriViewer },
    { path: '/newmaprequestforoverlay', redirect: mriOverlayCreate },
    { path: '/maprequestforoverlay/:id', redirect: mriOverlayDetail },
    { path: '/mriprofile', redirect: settings },
    { path: '/invoices', redirect: mriInvoices },
    { path: '/invoices/:userId', redirect: mriInvoicesForContact },
    { path: '/shoppingcart', redirect: mriShoppingCart },
];

export default oldRoutes;
