import userLocalStorage from '@/storage/user-local-storage';
import {useUserStore} from '@/app/shared/state/UserDataModule';
import { UnaMapRequest } from '../../una-routes';
import { EAlertType } from '@/app/shared/state/AlertModule';
import * as KlipApi from '@/api/klip-api.proxy';
import * as UnaModel from '../../shared/una-model';
import {useUnaStore} from '../../una-store';
import KlSearchMaprequests, { MapRequestSearchField } from '@/app/shared/components/kl-search-maprequests/kl-search-maprequests';
import { escape } from 'html-escaper';
import QueryFilter from '@/app/shared/helpers/queryFilter';
import {computed, defineComponent, onMounted, reactive, ref, watch} from 'vue';
import {useRoute, useRouter} from '@/router/router-vue3';
import {useAlertHandler} from '@/plugins/alert';
import dayjs from "dayjs";
import {EUserRole} from '@/app/shared/state/user-role';
import {useKlModalEventsUtil} from '@/app/shared/helpers/kl-modal-events-util';

export default defineComponent({
    name: 'KlUnaToConfirm',
    emits: ['update-query'],
    props: {
        data: {
            type: Object as () => UnaModel.IMapRequestsToConfirm,
            required: true
        },
    },
    setup(props, { emit }) {

        const route = useRoute();
        const router = useRouter();

        const confirmAllModalEvents = useKlModalEventsUtil('confirm-all-modal');

        const searchMaprequests = ref<InstanceType<typeof KlSearchMaprequests>>(null);

        const queryFilter: QueryFilter = new QueryFilter();
        const searchOptionLocalStorageKey = 'una_search_to_confirm_pa_organisation_id';

        const mapRequests = computed((): UnaModel.IMapRequestListItem[] => useUnaStore().mapRequestsToConfirm);
        const mapRequestToConfirmTotalCount = computed((): number => useUnaStore().mapRequestsToConfirmTotalCount);

        const _fetchMapRequestToConfirm = (input: KlipApi.GetMapRequestsToConfirmInput) => useUnaStore().fetchMapRequestToConfirm(input);
        const confirmCheckedMapRequests = (input: KlipApi.ConfirmMapRequestsInput[]) => useUnaStore().confirmMapRequests(input);


        const initialMappingFromQuery = ref<boolean>(true);
        const searchUsed = ref<boolean>(false);
        const checkedMapRequests = ref<UnaModel.IMapRequestListItem[]>([]);
        const mapRequestsColumns = ref<KlipApi.IColumn[]>([]);
        const mapRequestsFetching = ref<boolean>(false);
        const mapRequestsInput = ref<KlipApi.IGetMapRequestsToConfirmInput>(null);
        const confirmingMaprequests = ref<boolean>(false);
        const previousSortedColumn = ref<KlipApi.IColumn>(null);
        const selectedZone = ref<string>('all');
        const confirmingAll = ref<boolean>(false);
        const defaultFilter = ref<MapRequestSearchField>(new MapRequestSearchField());

        const mapRequestsMeta = reactive<{ totalRows: number, resultsPerPage: number, currentPage: number | undefined }>({
            totalRows: 0,
            resultsPerPage: 50,
            currentPage: 1
        });

        const buttonEnabled = computed(() => {
            return !!(mapRequests.value
                && mapRequests.value.length
                && checkedMapRequests.value.length
                && !confirmingMaprequests.value);
        });

        const defaultSearchOption = computed(() => {
            return !!props.data.defaultSearchOption ? props.data.defaultSearchOption : props.data && props.data.getMapRequestsInput && props.data.getMapRequestsInput.searchOption
                ? props.data.getMapRequestsInput.searchOption.toString() : null;
        });

        const _setSearchOptionToLocalStorage = (searchField: MapRequestSearchField) => {
            const isSubOrgSelected = isNaN(+searchField.searchOption);
            userLocalStorage.setItem(searchOptionLocalStorageKey, isSubOrgSelected ? searchField.organisationId : searchField.searchOption);
        }

        const isUnaWriter = computed((): boolean => {
            return useUserStore().hasRole([EUserRole.una_operator]);
        });

        const _updateQuery = () => {
            if (route.hash === '#toconfirm') {
                emit('update-query', { input: mapRequestsInput.value, hash: '#toconfirm' });
            }
        }

        const pagerClicked = (page: number) => {
            mapRequestsMeta.currentPage = page;
            mapRequestsFetching.value = true;
            mapRequestsInput.value.offset = mapRequestsMeta.resultsPerPage * (page - 1);
            _updateQuery();
        }

        const columnClicked = (column: KlipApi.IColumn) => {
            mapRequestsFetching.value = true;
            mapRequestsInput.value.offset = 0;
            mapRequestsMeta.currentPage = 1;
            mapRequestsInput.value.orderField = KlipApi.MapRequestsToConfirmOrderField[column.key[0].toUpperCase() + column.key.substr(1) as keyof typeof KlipApi.MapRequestsToConfirmOrderField];

            if (previousSortedColumn.value && previousSortedColumn.value.key !== column.key) {
                column.direction = 1;
            }

            mapRequestsInput.value.orderDirection = column.direction;
            previousSortedColumn.value = column;
            _updateQuery();
        }

        const rowClicked = (row: IRow) => {
            router.push({ name: UnaMapRequest.name, params: { id: row.mapRequestId, archived: 'false', zoneId: row.unaZoneId } });
        }

        const checkboxChanged = (checkedRows: UnaModel.IMapRequestListItem[]) => {
            checkedMapRequests.value = checkedRows;
        }

        const search = (searchFields: MapRequestSearchField) => {
            if (!initialMappingFromQuery.value) {
                searchUsed.value = true;
                mapRequestsFetching.value = true;
                mapRequestsInput.value = {
                    offset: 0,
                    limit: mapRequestsInput.value.limit,
                    orderDirection: mapRequestsInput.value.orderDirection,
                    orderField: mapRequestsInput.value.orderField,
                    query: searchFields.keyword,
                    organisationId: searchFields.organisationId,
                    utilityNetworkAuthorityZoneId: selectedZone.value === 'all' ? null : selectedZone.value,
                    searchOption: isNaN(+searchFields.searchOption) ? KlipApi.SearchOption.SubOrganisation : +searchFields.searchOption as KlipApi.SearchOption,
                    searchField: KlipApi.SearchField[searchFields.searchField as keyof typeof KlipApi.SearchField],
                    startDate: searchFields.date.from && searchFields.date.from[0] ? dayjs(searchFields.date.from[0]).toISOString() : null,
                    endDate: searchFields.date.to && searchFields.date.to[0] ? dayjs(searchFields.date.to[0]).toISOString() : null,
                };
                mapRequestsMeta.currentPage = 1;
                _setSearchOptionToLocalStorage(searchFields);
                _updateQuery();
            }
            initialMappingFromQuery.value = false;
        }

        const clearSearch = () => {
            searchMaprequests.value.reset();
        }

        const _parseCell = (row: IRow, column: IColumn) => {
            const cellValue = escape(row[column.key])
            return {
                template: row.mapRequestId && row.unaZoneId ? `<kl-router-link :to='${JSON.stringify(UnaMapRequest)}'
             :params='{id:"${row.mapRequestId}", archived:"false", zoneId:"${row.unaZoneId}"}'>${cellValue}</kl-router-link>` :
                    `<span>${cellValue}</span>`,
            };
        }

        const _toggleModal = () => {
            confirmAllModalEvents.triggerModalToggle();
        }

        const confirmCheckedMaprequests = () => {
            if (mapRequestsInput.value.searchOption === KlipApi.SearchOption.All && mapRequests.value && mapRequests.value.length == checkedMapRequests.value.length) {
                _toggleModal();
                return;
            }
            _confirmMapRequest();
        }

        const confirmAll = async () => {
            _confirmMapRequest();
        }

        const _confirmMapRequest = () => {
            confirmingMaprequests.value = true;
            const maprequestsToConfirm: KlipApi.ConfirmMapRequestsInput[] =
                checkedMapRequests.value.map((obj: UnaModel.IMapRequestListItem) => {
                    return KlipApi.ConfirmMapRequestsInput.fromJS({
                        mapRequestId: obj.mapRequestId,
                        zoneId: obj.unaZoneId,
                    });
                });

            confirmCheckedMapRequests(maprequestsToConfirm).then(() => {
                const numberOfMaprequestToConfirm = checkedMapRequests.value.length;
                checkedMapRequests.value = mapRequests.value ? mapRequests.value.filter((obj) => obj.checked) : [];
                mapRequestsMeta.totalRows = mapRequestToConfirmTotalCount.value;
                confirmingMaprequests.value = false;

                if (numberOfMaprequestToConfirm === mapRequestsMeta.resultsPerPage) {
                    pagerClicked(mapRequestsMeta.currentPage + 1);
                }

                const toasterId = `confirm-maprequest-toaster-${Math.random().toString(36).substring(7)}`;
                useAlertHandler().addAlert({
                    type: EAlertType.Success,
                    id: toasterId,
                    title: 'Ontvangst bevestigd',
                    message: `U heeft ${maprequestsToConfirm.length} ${maprequestsToConfirm.length > 1 ?
                        'planaanvragen' : 'planaanvraag'} bevestigd.`,
                    icon: 'info-circle',
                    autoClose: 5000,
                    closable: true,
                });
            });
        }

        onMounted(() => {
            mapRequestsColumns.value = props.data.mapRequestColumns.map((column) => ({
                ...column,
                parser: _parseCell,
            }));
            queryFilter.mapColumnsFromQuery(route, mapRequestsColumns.value, KlipApi.MapRequestsToConfirmOrderField)
            defaultFilter.value =  <MapRequestSearchField> {
                searchField: props.data.getMapRequestsInput.searchField ? KlipApi.SearchField[props.data.getMapRequestsInput.searchField] : KlipApi.SearchField[KlipApi.SearchField.MyReference],
                keyword: props.data.getMapRequestsInput.query,
                searchOption: props.data.getMapRequestsInput.searchOption.toString(),
                organisationId: props.data.getMapRequestsInput.organisationId,
                date: {
                    from: props.data.getMapRequestsInput.startDate ? [props.data.getMapRequestsInput.startDate] : null,
                    to: props.data.getMapRequestsInput.endDate ? [props.data.getMapRequestsInput.endDate] : null,
                },
            };
        });

        const _fetchMapRequests = () => {
            mapRequestsFetching.value = true;
            const input = KlipApi.GetMapRequestsToConfirmInput.fromJS(mapRequestsInput.value);
            _fetchMapRequestToConfirm(input).then(() => {
                checkedMapRequests.value = mapRequests.value.filter((m) => m.checked);
                mapRequestsMeta.totalRows = mapRequestToConfirmTotalCount.value;
                mapRequestsFetching.value = false;
            });
        }


        watch(
            () => props.data,
            (newData: UnaModel.IMapRequestsToConfirm) => {
                if (newData) {
                    mapRequestsInput.value = newData.getMapRequestsInput;
                    mapRequestsMeta.currentPage = Math.ceil(mapRequestsInput.value.offset / mapRequestsMeta.resultsPerPage) + 1;
                }
            },
            { immediate: true, deep: true });

        watch(
            mapRequestsInput,
                (newMapRequestsInput: KlipApi.IGetMapRequestsToConfirmInput) => {
                if (newMapRequestsInput) {
                    _fetchMapRequests();
                }
            },
            { immediate: true, deep: true });

        watch(
            selectedZone,
            (newZoneId: string) => {
                if(!newZoneId){
                    return;
                }
                mapRequestsInput.value.utilityNetworkAuthorityZoneId = newZoneId === 'all' ? null : newZoneId;
                mapRequestsInput.value.offset = 0;
                mapRequestsInput.value.limit = mapRequestsMeta.resultsPerPage;
                mapRequestsMeta.currentPage = 1;
                _updateQuery();
            },
            { immediate: true, deep: true });

        watch(
            mapRequestToConfirmTotalCount,
            () => {
                mapRequestsMeta.totalRows = mapRequestToConfirmTotalCount.value;
            },
            { immediate: true, deep: true });

        return {
            mapRequests,
            mapRequestToConfirmTotalCount,
            searchOptionLocalStorageKey,
            initialMappingFromQuery,
            searchUsed,
            checkedMapRequests,
            mapRequestsMeta,
            mapRequestsColumns,
            mapRequestsFetching,
            mapRequestsInput,
            confirmingMaprequests,
            previousSortedColumn,
            selectedZone,
            confirmingAll,
            defaultFilter,
            defaultSearchOption,
            buttonEnabled,

            isUnaWriter,
            pagerClicked,
            columnClicked,
            rowClicked,
            checkboxChanged,
            search,
            confirmCheckedMaprequests,
            confirmAll,

            clearSearch,
            searchMaprequests,
        };
    }
})
