<div :class="classes">
    <header role="none" :class="headerClasses">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 27" class="kl-profile-card__header__image" aria-hidden="true">
            <path fill="#000" fill-rule="nonzero" d="M18.86 23.75a11.55 11.55 0 0 0 4.09-3.58 9.66 9.66 0 0 0-1.32-3.88 3.75 3.75 0 0 0-2.78-1.78 7.16 7.16 0 0 1-5.35 2.37 7.11 7.11 0 0 1-5.35-2.37c-1.2.16-2.12.76-2.78 1.78a9.66 9.66 0 0 0-1.32 3.88 11.55 11.55 0 0 0 14.81 3.58zM17.6 13.73a5.57 5.57 0 0 0 1.7-4.09c0-1.6-.57-2.96-1.7-4.09a5.57 5.57 0 0 0-4.09-1.7c-1.6 0-2.96.57-4.1 1.7a5.57 5.57 0 0 0-1.69 4.1c0 1.59.57 2.95 1.7 4.08a5.57 5.57 0 0 0 4.09 1.7c1.6 0 2.96-.57 4.1-1.7zm8.34-5.47A13.2 13.2 0 0 1 27 13.5a13.74 13.74 0 0 1-3.94 9.54 13.4 13.4 0 0 1-4.3 2.89A13.23 13.23 0 0 1 13.5 27a13.56 13.56 0 0 1-9.55-3.95 13.56 13.56 0 0 1 0-19.1 13.56 13.56 0 0 1 19.1 0c1.2 1.2 2.17 2.64 2.88 4.3z"/>
        </svg>
        <div class="kl-profile-card__header__content">
            <vl-title :tag-name="titleTag" tag-style="h3" class="kl-profile-card__header__title" v-html="title"></vl-title>
            <span v-if="subtitle" class="kl-profile-card__header__subtitle" v-html="subtitle"></span>
        </div>
    </header>
    <div class="kl-profile-card__content" v-if="hasDefaultSlot">
        <slot />
    </div>
</div>
